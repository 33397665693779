import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import Logo from "../assets/amzs.gif";
import {useContext} from "react";
import {ApiContext} from "../index";
import {CvvRoutes} from "../strings";
import {useNavigate} from "react-router-dom";
import {StyledProps, Theme} from "../theme";
import {AdminStoreContext} from "./protected_route";
import {AdminStore} from "./stores/admin_store";
import {TailSpin} from "react-loader-spinner"


const Container = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  max-width: 1200px;
  margin: 0 auto 0 auto;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 70px;
`;

const HeaderMenu = styled.div`
  display: flex;
  height: 35px;
  background-color: #336699;
  padding: 4px;
  align-items: center;
  box-sizing: border-box;
`;

const HeaderBox = styled.table`
  border-collapse: collapse;
  font-size: 13px;
`;

const HeaderBoxHR = styled.tr`
  color: ${({theme}: StyledProps) => theme.content};
  background-color: ${({theme}: StyledProps) => theme.primary};
`;

const HeaderBoxHD = styled.td`
  text-align: center;
  vertical-align: center;
  padding: 4px 8px 4px 8px;
  border: solid 1px ${({theme}: StyledProps) => theme.primary};
`;

const HeaderBoxTDH = styled.td`
  width: 90px;
  color: ${({theme}: StyledProps) => theme.content};
  background-color: #EEEEEE;
  border: solid 1px #CCCCCC;
  padding: 4px 8px 4px 8px;
`;

const HeaderBoxTD = styled.td`
  width: 100px;
  border: solid 1px #CCCCCC;
  color: red;
  padding: 4px 8px 4px 8px;
`;


export const AdminButton = styled.div<{ disabled?: boolean, selected?: boolean, background?: string, backgroundHover?: string }>`
  cursor: pointer;
  border: solid 1px white;
  background-color: ${({selected, background}) => background || (selected ? '#007194' : '#0099CB')};
  font-weight: ${({selected}) => selected ? 'bold' : '600'};
  color: white;
  line-height: normal;
  vertical-align: center;
  font-size: 12px;
  padding: 3px 8px 3px 8px;
  transition: all 300ms;
  margin: 0 2px 0 2px;
  opacity: ${({disabled}) => disabled ? '0.5' : '1'};
  user-select: none;
  pointer-events: ${({disabled}) => disabled ? 'none': 'auto'};

  &:hover {
    background-color: ${({background, backgroundHover}) => backgroundHover || background || '#007194'};
  }
`;

export const AdminScaffold = observer(({children, route}: { children: any, route?: string }) => {
    const store = useContext(AdminStoreContext) as AdminStore;
    const api = useContext(ApiContext);
    const theme = useTheme() as Theme;
    const navigate = useNavigate();

    return <Container>
        <Header>
            <img src={Logo} style={{height: 'calc(100% - 8px)', paddingLeft: '4px', marginRight: 'auto'}} alt={'logo'}/>
            <HeaderBox>
                <thead>
                <HeaderBoxHR>
                    <HeaderBoxHD colSpan={2}>ADMINISTRATOR</HeaderBoxHD>
                </HeaderBoxHR>
                </thead>
                <tbody>
                <tr>
                    <HeaderBoxTDH>Uporabnik</HeaderBoxTDH>
                    <HeaderBoxTD>{store.user?.name}</HeaderBoxTD>
                </tr>
                </tbody>
            </HeaderBox>
            <div style={{
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {!!store.loading && <TailSpin color={theme.content} height={50} width={50}/>}
            </div>
        </Header>
        {route && <HeaderMenu>
            <AdminButton
                selected={route === CvvRoutes.admin.razpisaniTecaji}
                onClick={() => navigate(CvvRoutes.admin.razpisaniTecaji)}>
                RAZPISANI TEČAJI
            </AdminButton>
            <AdminButton
                selected={route === CvvRoutes.admin.prijavljeneOsebe}
                onClick={() => navigate(CvvRoutes.admin.prijavljeneOsebe)}>
                PRIJAVLJENE OSEBE
            </AdminButton>
            <AdminButton style={{marginLeft: 'auto', border: 'none'}}
                         onClick={() => api.setAuth(null)}
                         background={'black'}>ODJAVA
            </AdminButton>
        </HeaderMenu>}
        {children}
    </Container>
});