import {observer} from "mobx-react-lite";
import {CvvCalendarGrid, CvvCalendarHeaderDays} from "../../forms/components/calendar";
import styled, {useTheme} from "styled-components";
import {useCallback, useContext, useState} from "react";
import {AdminStoreContext} from "../protected_route";
import {AdminStore} from "../stores/admin_store";
import {StyledProps, Theme} from "../../theme";
import {admin_tecaji} from "../../proto/compiled";
import {DateFormatters, DateUtils} from "../../utils";

const CalendarsContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 5px;
  border: solid 1px ${({theme}: StyledProps) => theme.content10};
`;

const CalendarContainer = styled.div<{ $left?: boolean, $right?: boolean }>`
  text-align: center;
  padding: 4px 16px 8px 16px;
  border-radius: ${({$left, $right}) => $left ? '5px 0 0 5px' : $right ? '0 5px 5px 0' : '0'};
  border-left: ${({theme, $left, $right}: StyledProps) => !$left && !$right ? `solid 1px ${theme.content10}` : 'none'};
  border-right: ${({theme, $left, $right}: StyledProps) => !$left && !$right ? `solid 1px ${theme.content10}` : 'none'};
`;

export const AdminCalendars = observer((props) => {
    const store = useContext(AdminStoreContext) as AdminStore;
    const theme = useTheme() as Theme;

    const buildDay = useCallback((date?: Date, payload?: any) => {
        const tecaji = store.calendars.getTecaji(date);
        const hasDay = tecaji.length > 0;
        return <CalendarDay
            key={date?.getTime() || Math.random()}
            selected={store.calendars.selectedDate?.toDateString() === date?.toDateString()}
            $past={!!date && DateUtils.dayIsBefore(date, new Date())}
            $hasDay={hasDay}
            onClick={!date || !hasDay ? undefined : () => {
                store.calendars.selectDate(date);
            }}>
            <CalendarDayOverlay tecaji={tecaji}>
                {date?.getDate()}
            </CalendarDayOverlay>
        </CalendarDay>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <CalendarsContainer>
        <CalendarContainer $left={true}>
            <theme.textTheme.button.span>{store.calendars.controllers[0].monthYearTitle()}</theme.textTheme.button.span>
            <div style={{margin: '8px 0 8px'}}><CvvCalendarHeaderDays/></div>
            <CvvCalendarGrid controller={store.calendars.controllers[0]} dayBuilder={buildDay}/>
        </CalendarContainer>
        <CalendarContainer style={{backgroundColor: theme.content5}}>
            <theme.textTheme.button.span>{store.calendars.controllers[1].monthYearTitle()}</theme.textTheme.button.span>
            <div style={{margin: '8px 0 8px'}}><CvvCalendarHeaderDays/></div>
            <CvvCalendarGrid controller={store.calendars.controllers[1]} dayBuilder={buildDay}/>
        </CalendarContainer>
        <CalendarContainer $right={true}>
            <theme.textTheme.button.span>{store.calendars.controllers[2].monthYearTitle()}</theme.textTheme.button.span>
            <div style={{margin: '8px 0 8px'}}><CvvCalendarHeaderDays/></div>
            <CvvCalendarGrid controller={store.calendars.controllers[2]} dayBuilder={buildDay}/>
        </CalendarContainer>
    </CalendarsContainer>;
});

const CalendarDayOverlay = ({tecaji, children}: { tecaji: admin_tecaji.IAdminTecajDaySummary[], children: any }) => {
    const [hovering, setHovering] = useState(false);
    return <div onMouseEnter={() => setHovering(tecaji.length > 0)} onMouseLeave={() => setHovering(false)} style={{
        width: '100%',
        height: '100%',
        display: 'flex',

        justifyContent: 'center',
        alignItems: 'center',
    }}>
        {children}
        {hovering && tecaji.length > 0 && <HoveringOverlay>
            {tecaji.map((e) => <CalendarDaySummaryRow key={e.id} summary={e}/>)}
        </HoveringOverlay>}
    </div>
}

const CalendarDaySummaryRow = ({summary}: { summary: admin_tecaji.IAdminTecajDaySummary }) => {
    const theme = useTheme() as Theme;
    return <CalendarDaySummaryRowElement>
        <span style={{flex: '1 0', overflow: 'hidden', textAlign: 'left', fontWeight: 'bold'}}>{summary.tecajName?.toUpperCase()}</span>
        <span style={{width: '50px', backgroundColor: theme.content10}}>{summary.dayN! + 1}. dan</span>
        <span style={{width: '90px'}}>{DateFormatters.dateTime(new Date(summary.start!.ts as number))}</span>
        <span style={{width: '60px', backgroundColor: '#CBFFCB'}}>prosto: {summary.placesFree}</span>
    </CalendarDaySummaryRowElement>
};

const CalendarDaySummaryRowElement = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  ${({theme}: StyledProps) => theme.textTheme.body.css};

  & > span {
    padding: 2px 4px 2px 4px;
    max-lines: 1;
    white-space: nowrap;
  }
`;

const HoveringOverlay = styled.div`
  position: absolute;
  pointer-events: none;
  user-select: none;
  top: 40px;
  left: 8px;
  background-color: white;
  border: solid 1px ${({theme}: StyledProps) => theme.content};
  border-left-width: 5px;
  z-index: 999;
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
`;

const CalendarDay = styled.div<{ selected: boolean, $hasDay: boolean, $past: boolean }>`
  position: relative;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  background-color: ${({
                         theme,
                         $hasDay,
                         selected
                       }: StyledProps) => $hasDay && selected ? theme.content : 'transparent'};
  transition: background-color 300ms, color 300ms;
  cursor: ${({$hasDay}) => $hasDay ? 'pointer' : 'auto'};
  user-select: none;
  color: ${({theme, selected, $past}: StyledProps) => selected ? 'white' : $past ? 'gray' : theme.content};
  outline-offset: -1px;
  outline: solid 1px ${({theme, $hasDay}: StyledProps) => $hasDay ? theme.content : 'transparent'};

  &:hover {
    background-color: ${({
                           theme,
                           $hasDay,
                           selected
                         }: StyledProps) => $hasDay ? selected ? theme.content : theme.content10 : 'transparent'};
  }
`;