import {billing} from "../proto/compiled";
import {NumberFormatters} from "../utils";

export class Pricelist {
    pricelist: billing.IPricelist;
    items: { [k: string]: billing.IPricelistItem } = {};

    constructor(pricelist: billing.IPricelist) {
        this.pricelist = pricelist;
        for (const item of pricelist.items!) {
            this.items[item.code!] = item;
        }
    }

    static total(items: billing.IPricelistItem[], modifiers?: Set<string>): number {
        let sum = 0;
        for (const item of items) {
            sum += Pricelist.getValue(item, modifiers);
        }
        return sum;
    }

    static getValue(item?: billing.IPricelistItem, modifiers?: Set<string>): number {
        if (!item) return 0;
        let value = item.amount ?? 0;
        for (const modifier of item.modifiers!) {
            if (modifiers?.has(modifier.name!)) {
                value = Pricelist.applyModifier(modifier, value);
            }
        }
        return value;
    }

    static applyModifier(modifier: billing.IPriceModifier, value: number): number {
        value += modifier.add!;
        value *= modifier.multiply!;
        return value;
    }

    getPriceEur(item: string, modifiers?: string[]): string {
        return NumberFormatters.eur(this.getPrice(item, modifiers) / 100);
    }

    getModifierDifferenceEur(item: string, modifiers?: string[]): string {
        const pure = this.getPrice(item);
        const modified = this.getPrice(item, modifiers);
        return NumberFormatters.eur((pure - modified) / 100);
    }

    getPrice(item: string, modifiers?: string[]): number {
        return Pricelist.getValue(this.items[item], modifiers && new Set(modifiers));
    }
}

export const PricelistItems = {
    tecaj: 'tecaj',
    kosilo: 'kosilo',
    clanstvo: 'clanstvo',
    vozilo: 'vozilo',
};

export const PricelistModifiers = {
    amzs_clan: 'amzs_clan',
    vozilo_s_prijateljem: 'vozilo_s_prijateljem',
    vozilo_z_drugim: 'vozilo_z_drugim',
    student_dijak: 'student_dijak',
    upokojenec: 'upokojenec',
    amzs_clan_1: 'amzs_clan_1',
    amzs_clan_2: 'amzs_clan_2',
};
