import {SocketApi, SocketApiAckStatus} from "proto_socket_typescript";
import {MladiVoznikStore} from "./mladi_voznik_store";
import {billing, cvv_types, mladi_voznik} from "../proto/compiled";
import {proto} from "../proto/messages";
import {toast} from "react-toastify";
import {makeAutoObservable} from "mobx";
import IAddress = cvv_types.IAddress;

export interface CheckboxData {
    content: any;
    checked?: boolean;
    expanded?: boolean;
    children?: CheckboxData[];
    owner?: CheckboxData;
}

export class MladiVoznikStoreS6 {
    api: SocketApi;
    base: MladiVoznikStore;
    stDarilnegaBona: string = 'CVV';
    billingAddress?: IAddress;

    constructor(base: MladiVoznikStore, api: SocketApi) {
        this.api = api;
        this.base = base;
        makeAutoObservable(this);
    }

    init() {
        this.base.formStore!.onSave();
        this.api.sendMessage(proto.TxSaveMladiVoznikForm.create({
            form: this.base.form as mladi_voznik.IMladiVoznikForm,
            validationCode: this.base.step1.result?.validationCode,
            paymentType: this.base.formStore?.paymentType
        }), {ack: true}).then((status) => {
            if (status.status !== SocketApiAckStatus.success) {
                toast.error(status.errorMessage);
            }
        });
        if (this.base.formStore!.api.authenticated && this.base.formStore?.paymentType === billing.PaymentType.kreditna) {
            this.base.formStore!.paymentType = billing.PaymentType.darilni_bon;
        }
        this.billingAddress = cvv_types.Address.create();
    }

    dispose() {
    }

    get done(): boolean {
        return false;
    };

    cardPaymentSubmitForm() {
        this.api.sendMessage(proto.TxSaveMladiVoznikForm.create({
            form: this.base.form as mladi_voznik.IMladiVoznikForm,
            validationCode: this.base.step1.result?.validationCode,
            paymentType: this.base.formStore?.paymentType
        }), {ack: true}).then((status) => {
            if (status.status !== SocketApiAckStatus.success) {
                toast.error(status.errorMessage);
            }
        });
    }

    async submit() {
        return await this.api.sendMessage(proto.TxSubmitForm.create({
            validationCode: this.base.step1.result?.validationCode,
            paymentType: this.base.formStore?.paymentType,
            stDarilnegaBona: this.stDarilnegaBona
        }), {ack: true});
    }
}
