import {billing, cvv_types, mladi_voznik} from "./proto/compiled";

export abstract class CvvStrings {
    static vozilo: { [k: number]: string } = {
        [cvv_types.Vozilo.svoje]: 'SVOJE',
        [cvv_types.Vozilo.najeto]: 'NAJETO',
        [cvv_types.Vozilo.najeto_prijatelj]: 'NAJETO S PRIJATELJEM',
        [cvv_types.Vozilo.najeto_druga_oseba]: 'NAJETO Z DRUGO OSEBO',
    }

    static napotilo: { [k: number]: string } = {
        [mladi_voznik.KazenskeTockeNapotilo.sodisce]: 'SODIŠČE',
        [mladi_voznik.KazenskeTockeNapotilo.zdravstveni_pregled]: 'ZDRAVSTVENI PREGLED',
        [mladi_voznik.KazenskeTockeNapotilo.prostovoljno]: 'PROSTOVOLJNO',
    }
}

export const CvvRoutes = {
    admin: {
        razpisaniTecaji: '/admin/',
        razpisaniTecaj: (id?: string) => '/admin/razpisani-tecaj/' + (id ? (id + '/') : ''),
        prijavljeneOsebe: '/admin/prijavljene-osebe/',
    },
    formSummary: (sessionId?: string) => `/summary/${sessionId}/`,
    editForm: (sessionId?: string) => `/resume/${sessionId}/`,
    novaPrijava: (tip: string, tecaj: string) => `/${tip}/${tecaj}/`,
};

export const CvvPaymentType = {
    [billing.PaymentType.gotovina]: 'GOTOVINA',
    [billing.PaymentType.kreditna]: 'KREDITNA',
    [billing.PaymentType.darilni_bon]: 'DARILNI BON',
    [billing.PaymentType.predracun]: 'PLAČILO PO PREDRAČUNU',
}