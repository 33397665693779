import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../theme";
import {OverlayContainer} from "./overlays";
import {CSSProperties, useEffect, useState} from "react";
import {appStore} from "../index";
import {IconButton} from "../forms/components/decorations";
import {MdClose} from "react-icons/md";
import Iframe from 'react-iframe'

export const DialogContainer = styled.div<{ width?: string, height?: string }>`
    position: relative;
    background-color: ${({theme}: StyledProps) => theme.neutral};
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
    max-width: ${({width}) => width};
    max-height: ${({height}) => height};
    width: ${({width}) => width ? 'calc(100% - 32px)' : ''};
    height: ${({width}) => width ? 'calc(100% - 32px)' : ''};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

interface D3SecureDialogProps {
    redirectUrl: string;
    callback: (status: string) => any;
    onClose: () => any;
    children?: any;
    origin?: string;
}

export const D3SecureDialog = (props: D3SecureDialogProps) => {
    const [first, setFirst] = useState(true);
    useEffect(() => setFirst(false), []);
    const theme = useTheme() as Theme;
    useEffect(() => {
        const messageHandler = (event: MessageEvent) => {
            if (event.origin === props.origin) {
                const { paymentStatus } = event.data;
                if (paymentStatus) {
                    props.callback(paymentStatus);
                }
            } else {
                console.warn("Message from untrusted origin:", event.origin);
            }
        };

        window.addEventListener("message", messageHandler);

        return () => {
            window.removeEventListener("message", messageHandler);
        };
}, []);
    const width = window.screen.width < 500 ? '90%' : '500px';
    return <OverlayContainer hidden={first}>
        <DialogContainer style={{padding: '0', width: width}}>
            <theme.textTheme.headline4.span style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                minHeight: '100px',
                height: '80vh',
                width: '100%',
                padding: '16px 50px 16px 50px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: 'border-box'
            }}>
                {props.children}
                <Iframe url={props.redirectUrl}
                        width="100%"
                        height="100%"
                        id={"3dsecureIframe"}
                        display="initial"
                        position="relative"
                        styles={{border: 'none'}}
                        sandbox={["allow-scripts","allow-same-origin", "allow-forms"]}
                />
            </theme.textTheme.headline4.span>
        </DialogContainer>
    </OverlayContainer>;
};

export const ShowD3SecureDialog = (props: D3SecureDialogProps) => {
    appStore.showDialog(
        <D3SecureDialog
        redirectUrl={props.redirectUrl}
        callback={(status) => {
            props.callback(status);
            appStore.hideDialog();
        }}
        onClose={props.onClose}
        origin={window.location.origin}
        >
            {props.children}
            <IconButton
                topRight={true}
                onClick={() => {
                    props.onClose();
                    appStore.hideDialog();
                }}>
                <MdClose/>
            </IconButton>
        </D3SecureDialog>
    );
}