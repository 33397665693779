import {observer} from "mobx-react-lite";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {AdminStore} from "./stores/admin_store";
import {MdChevronLeft, MdChevronRight, MdClose, MdFirstPage, MdLastPage} from "react-icons/md"
import {AdminStoreContext} from "./protected_route";
import styled from "styled-components";
import {AdminButton, AdminScaffold} from "./admin_scaffold";
import {CvvPaymentType, CvvRoutes} from "../strings";
import {AdminHeader, AdminInput} from "./components/admin_decorations";
import {useLocation, useNavigate} from "react-router-dom";
import {DateFormatters} from "../utils";
import {StyledProps} from "../theme";
import {admin_participants, billing, cvv_types} from "../proto/compiled";
import {debounce} from "lodash";
import {AdminParticipant} from "./admin_participant";
import {MdDriveEta} from "react-icons/md";
import {IconButton} from "../forms/components/decorations";

const ParticipantsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 180px 200px 120px 130px 210px;
  gap: 1px;
  margin-bottom: 10px;
`;

const ParticipantsGridHeaderCell = styled.div`
  text-align: center;
  line-height: 27px;
  height: 27px;
  outline: solid 1px #CCCCCC;
  background-color: ${({theme}) => theme.content10};
  overflow: hidden;
  ${({theme}: StyledProps) => theme.textTheme.body.with({color: 'black', weight: 'bold'}).css};
`;

const ParticipantsGridCell = styled.div`
  display: flex;
  outline: solid 1px #CCCCCC;
  line-height: 30px;
  padding: 0 5px 0 5px;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  ${({theme}: StyledProps) => theme.textTheme.body.with({color: 'black'}).css};
`;

const Select = styled.select`
  margin-right: 8px;
`;

const Container = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const RowContainer = styled.div<{ selected: boolean, archived: boolean }>`
  & > * {
    background-color: ${({theme, archived}: StyledProps) => archived ? theme.content5 : undefined};
    color: ${({theme, archived}: StyledProps) => archived ? theme.content50 : undefined};
  }

  & > :nth-child(1) {
    transition: border 300ms;
    ${({
         theme,
         selected
       }: StyledProps) => selected ? `border-left: solid 10px ${theme.primary}` : `border-left: solid 0 ${theme.primary}`};
  }
`;

export const AdminParticipants = observer(() => {
    const store = useContext(AdminStoreContext) as AdminStore;
    const navigate = useNavigate();
    useEffect(() => {
        store.api.connection.whenConnected.then(() => store.participants.load());
    }, [store, store.participants]);

    const getRow = useCallback((participant: admin_participants.IParticipantSummary) => {
        const start = new Date(participant.tecajStart!.ts as number);
        let color = 'transparent';
        if (participant.archived) {
            color = '#dcdcdc';
        } else if (participant.racunIzdan) {
            if (participant.paymentType === billing.PaymentType.gotovina) {
                color = '#d4efff'
            } else {
                color = '#acb1d7';
            }
        } else {
            switch (participant.paymentStatus) {
                case billing.PaymentStatus.no_proforma_invoice:
                    color = '#fff4b0';
                    break;
                case billing.PaymentStatus.amount_mismatch:
                    color = '#fff4b0';
                    break;
                case billing.PaymentStatus.paid:
                    color = '#c9ffc0';
                    break;
            }
        }

        return <RowContainer
            key={participant.sessionId!}
            archived={!!participant.archived}
            selected={participant.sessionId === store.participants.participant?.summary?.sessionId}
            style={{
                display: 'contents',
                cursor: 'pointer',
            }}
            onClick={() => store.participants.setParticipant(participant.sessionId!)}>
            <ParticipantsGridCell>
                {participant.tecajTipName?.toUpperCase()} - {participant.tecajName?.toUpperCase()}
            </ParticipantsGridCell>
            <ParticipantsGridCell>
                {participant.locationPrefix} - {DateFormatters.date(start)} ({DateFormatters.time(start)})
            </ParticipantsGridCell>
            <ParticipantsGridCell>
                {participant.nameSurname}
                {participant.vozilo !== cvv_types.Vozilo.svoje &&
                    <MdDriveEta style={{marginLeft: 'auto', fontSize: '20px', marginRight: '8px'}}/>}
            </ParticipantsGridCell>
            <ParticipantsGridCell style={{justifyContent: 'center'}}>{participant.sklic}</ParticipantsGridCell>
            <ParticipantsGridCell style={{justifyContent: 'center', backgroundColor: color}}>
                {(participant.paymentStatus === billing.PaymentStatus.paid && !participant.racunIzdan) ? 'RAČUN ŠE NI IZDAN' : CvvPaymentType[participant.paymentType!]}
            </ParticipantsGridCell>
            <ParticipantsGridCell style={{justifyContent: 'center'}}>
                {participant.paymentStatus === billing.PaymentStatus.proforma_invoice && 'PREDRAČUN POSLAN'}
                {participant.paymentStatus === billing.PaymentStatus.amount_mismatch && 'ZNESEK SE NE UJEMA'}
                {participant.paymentStatus === billing.PaymentStatus.paid && <>
                    <AttendanceConfirmation participant={participant}/>
                </>}


            </ParticipantsGridCell>
        </RowContainer>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadDebounced = useMemo(() => debounce(() => store.participants.load(), 300), [store.participants]);
    return <AdminScaffold route={CvvRoutes.admin.prijavljeneOsebe}>
        <Container>
            <AdminHeader>
                {store.razpisaniTecaj && store.razpisaniTecaj.tecajName}
                {store.razpisaniTecaj && ' (' + DateFormatters.dateTime(new Date(store.razpisaniTecaj.start!.ts! as number)) + ')'}
                {store.razpisaniTecaj && <span
                    style={{
                        marginLeft: 'auto',
                        marginRight: '8px',
                        cursor: 'pointer',
                        height: '100%',
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    onClick={() => {
                        store.razpisaniTecaj = undefined;
                        store.participants.load();
                    }}>
                <MdClose style={{fontSize: '20px', fontWeight: 'bold'}}/>
                </span>}
                {!store.razpisaniTecaj && <>
                    <Select value={store.tecajTip ?? ''}
                            onChange={(e) => {
                                store.setTecajTip(e.target.value);
                                store.participants.load();
                            }}>
                        <option value={''}>---- izberi tip ----</option>
                        {store.meta?.tecajTipi?.map((t) => <option key={t.text!} value={t.text!}>{t.name}</option>)}
                    </Select>
                    <Select value={store.tecaj ?? ''} onChange={(e) => {
                        store.setTecaj(e.target.value);
                        store.participants.load();
                    }}>
                        <option value={''}>---- izberi tecaj ----</option>
                        {store.meta &&
                            store.meta?.tecajTipTecaji![store.tecajTip!]?.choices?.map(
                                (t: any) => <option key={t.text!} value={t.text!}>{t.name}</option>
                            )}
                    </Select>
                    <input type={'date'}
                           value={store.participants.date && DateFormatters.isoDate(store.participants.date)}
                           onChange={(e) => {
                               store.participants.date = e.target.valueAsDate || undefined;
                               loadDebounced();
                           }}
                    />
                    <input type={'text'}
                           style={{marginLeft: '8px'}}
                           placeholder={'Iskanje'}
                           value={store.participants.search ?? ''}
                           onChange={(e) => {
                               store.participants.search = e.target.value;
                               loadDebounced();
                           }}/>

                    {store.tecajTip && store.tecaj && <AdminButton
                        onClick={() => {
                            navigate(CvvRoutes.novaPrijava(store.tecajTip!, store.tecaj!));
                        }}
                        style={{marginLeft: 'auto'}}>
                        + DODAJ NOVO PRIJAVO
                    </AdminButton>}
                </>}
            </AdminHeader>
            <ParticipantsGrid>
                <ParticipantsGridHeaderCell>vrsta tečaja</ParticipantsGridHeaderCell>
                <ParticipantsGridHeaderCell>datum tečaja</ParticipantsGridHeaderCell>
                <ParticipantsGridHeaderCell>oseba</ParticipantsGridHeaderCell>
                <ParticipantsGridHeaderCell>sklic</ParticipantsGridHeaderCell>
                <ParticipantsGridHeaderCell>plačilo</ParticipantsGridHeaderCell>
                <ParticipantsGridHeaderCell>ostalo</ParticipantsGridHeaderCell>
                {store.participants.participants?.participants?.map((p) => getRow(p))}
            </ParticipantsGrid>
            <ParticipantsPagination/>
            {store.participants.participant && <AdminParticipant/>}
        </Container>
    </AdminScaffold>
});

const ParticipantsPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  margin: -4px 0 4px auto;
`;
const ParticipantsPagination = observer(() => {
    const store = useContext(AdminStoreContext) as AdminStore;
    const nPages = store.participants.participants?.nPages ?? 0;
    const cPage = store.participants.page;

    return <ParticipantsPaginationContainer>
        <IconButton onClick={() => store.participants.setPage(0)}><MdFirstPage/></IconButton>
        <IconButton onClick={() => store.participants.setPage(cPage - 1)}><MdChevronLeft/></IconButton>
        <input type={"number"}
               style={{width: '50px', margin: '0 8px 0 8px', borderRadius: '3px', textAlign: 'center'}}
               min={1}
               max={nPages + 1}
               value={cPage + 1}
               onFocus={(e) => e.target.select()}
               onChange={(e) => {
                   if (!store.participants.setPage(e.target.valueAsNumber - 1)) {
                       e.preventDefault();
                       setTimeout(() => e.target.select(), 0);
                   }
               }}/>
        od {(store.participants.participants?.nPages ?? 0) + 1}
        <IconButton onClick={() => store.participants.setPage(cPage + 1)} style={{marginLeft: '8px'}}><MdChevronRight/></IconButton>
        <IconButton onClick={() => store.participants.setPage(nPages)}><MdLastPage/></IconButton>
    </ParticipantsPaginationContainer>;
});


const AttendanceConfirmation = observer(({participant}: { participant: admin_participants.IParticipantSummary }) => {
    const store = useContext(AdminStoreContext) as AdminStore;
    const [state, setState] = useState(false);
    return <div style={{
        display: 'flex',
        alignItems: 'center',
    }}>{
        Array(participant.nDays!).fill(0).map((v, i) => <div
            key={'presence-' + i}
            onClick={(e) => {
                e.stopPropagation();
                store.participants.togglePresence(participant, i).then(() => setState(!state));
            }}
            style={{
                width: '20px',
                height: '25px',
                margin: '2px',
                backgroundColor: participant.daysPresent!.indexOf(i) >= 0 ? 'green' : 'red',
                cursor: 'pointer',
            }}/>)
    }</div>
});