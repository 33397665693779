import {FormMladiVoznik} from "./forms/mladi_voznik/form_mladi_voznik";
import {useContext, useEffect, useMemo, useState} from "react";
import {ApiContext, AppStoreContext, FormStoreContext} from "./index";
import {OverlayContainer} from "./components/overlays";
import {DialogContainer} from "./components/dialogs";
import {useTheme} from "styled-components";
import {Theme} from "./theme";
import {observer} from "mobx-react-lite";
import {cvv_types} from "./proto/compiled";
import {proto} from "./proto/messages";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {SocketApiAckStatus} from "proto_socket_typescript";
import {ProtectedRoute} from "./admin/protected_route";
import {AdminButton, AdminScaffold} from "./admin/admin_scaffold";
import {CvvRoutes} from "./strings";
import {IconButton} from "./forms/components/decorations";
import {MdArrowBack} from "react-icons/md";
import {AdminHeader} from "./admin/components/admin_decorations";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {FormSummary} from "./forms/form_summary";
import {FormStore} from "./stores/form_store";

interface AppProps {
    summary?: boolean,
}

function App(props: AppProps) {
    const api = useContext(ApiContext);
    const {tip, tecaj, sessionId} = useParams();
    const [error, setError] = useState<string | null>(null);
    const location = useLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const formStore = useMemo(() => new FormStore(api), [sessionId, location.key]);

    useMemo(() => formStore.sessionId = sessionId, [formStore, sessionId]);

    // load payment library on mount
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://gateway.bankart.si/js/integrated/payment.1.3.min.js";
        script.async = true;
        // Set the data-main attribute
        script.setAttribute('data-main', 'payment-js');
        script.onload = () => {
            if (window.PaymentJs) {
                console.log("PaymentJS library is ready");
            } else {
                console.error("Failed to load the PaymentJS library.");
            }
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    // load form meta
    useEffect(() => {
        api.connection.whenConnected.then(() => {
            if (sessionId) {
                if (props.summary) {
                    api.sendMessage(proto.TxLoadFormSummary.create({
                        sessionId: sessionId
                    }));
                } else {
                    api.sendMessage(proto.TxResumeForm.create({
                        sessionId: sessionId
                    }), {ack: true});
                }
            } else {
                api.sendMessage(proto.TxLoadForm.create({
                    tipTecaja: tip,
                    tecaj: tecaj,
                }), {ack: true}).then((status) => {
                    if (status.status === SocketApiAckStatus.success) {
                        setError(null);
                    } else {
                        setError(status.errorMessage);
                    }
                });
            }
        });
    }, [api, tip, tecaj, sessionId, props.summary]);


    // render
    const theme = useTheme() as Theme;
    const app = useContext(AppStoreContext);
    const navigate = useNavigate();


    let formContent;
    if (props.summary) {
        formContent = <FormSummary/>;
    } else if (formStore.formConfig) {
        let displayedForm;
        switch (formStore.meta?.tecaj?.formType) {
            case cvv_types.FormType.mladi_voznik:
                displayedForm = <FormMladiVoznik/>;
                break;
        }

        formContent = <>
            {displayedForm}
            {error && <div style={{    // todo: style this error message
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <theme.textTheme.error.span>{error}</theme.textTheme.error.span>
            </div>}
            {app.dialog}
            <ConnectedOverlay/>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>;
    }

    if (api.authenticated) {
        formContent = <ProtectedRoute>
            <AdminScaffold>
                <AdminHeader>
                    <IconButton onClick={() => {
                        navigate(CvvRoutes.admin.prijavljeneOsebe, {replace: true});
                        navigate(-1);
                    }}
                                style={{color: 'white', height: '100%', aspectRatio: '1'}}>
                        <MdArrowBack/>
                    </IconButton>
                    {window.location.href.includes('resume') ? 'UREDI PRIJAVO' : 'DODAJ NOVO PRIJAVO'}
                    <AdminButton style={{marginLeft: 'auto', border: 'none'}}
                                 onClick={() => api.setAuth(null)}
                                 background={'black'}>ODJAVA
                    </AdminButton>
                </AdminHeader>
                {formContent}
            </AdminScaffold>
        </ProtectedRoute>
    }
    return <FormStoreContext.Provider value={formStore}>
        {formContent}
    </FormStoreContext.Provider>
}

export default observer(App);

export const ConnectedOverlay = () => {
    const api = useContext(ApiContext);
    const theme = useTheme() as Theme;

    // handle connected overlay
    const [connected, setConnected] = useState(true);
    useEffect(() => {
        const sub = api.connection.connectedChanges.subscribe((connected) => setConnected(connected));
        const timeout = new Promise((res) => setTimeout(() => res(null), 1000));
        Promise.race([api.connection.whenConnected, timeout]).then(
            (_) => setConnected(api.connection.connected)
        );
        return () => sub.unsubscribe();
    }, [api.connection]);

    return <OverlayContainer hidden={connected}>
        <DialogContainer style={{padding: '50px'}}>
            <theme.textTheme.headline3.span>
                Povezava s strežnikom ni uspela!
            </theme.textTheme.headline3.span>
            <theme.textTheme.body.span>
                <ol>
                    <li>Preverite ali imate delujočo internetno povezavo.</li>
                    <li>Poskusite malo kasneje. Morda poteka nadgradnja ali vzdrževanje sistema.</li>
                </ol>
            </theme.textTheme.body.span>
        </DialogContainer>
    </OverlayContainer>;
};