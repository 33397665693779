import {SocketApi} from "proto_socket_typescript";
import {MladiVoznikStore} from "./mladi_voznik_store";
import {makeAutoObservable} from "mobx";
import {cvv_types, mladi_voznik} from "../proto/compiled";
import IChildData = mladi_voznik.IChildData;

export class MladiVoznikStoreStepChildren {
    api: SocketApi;
    base: MladiVoznikStore;
    children: number | null | undefined;
    childrenData: IChildData[] | null | undefined;
    birthDates: (Date | undefined)[] = [];
    heights: (string | undefined)[] = [];
    weights: (string | undefined)[] = [];

    constructor(base: MladiVoznikStore, api: SocketApi) {
        this.api = api;
        this.base = base;
        this.birthDates = [];
        this.children = this.base.form.step1?.children;
        this.childrenData = this.base.form.stepChildren?.children;
        makeAutoObservable(this);
    }

    init() {
    }

    dispose() {
    }

    get done(): boolean {
        return true;
    };

    initData(children?: number) {
        if (!children) return;
        this.children = children;
        if (!this.childrenData) {
            this.childrenData = [];
            this.birthDates = [];
            for (let i = 0; i < this.children!; i++) {
                this.childrenData.push({
                        name: '',
                        surname: '',
                        birthDate: null,
                        height: null,
                        weight: null,
                    } as IChildData
                )
                this.birthDates.push(undefined);
                this.heights.push(undefined);
                this.weights.push(undefined);
            }
        } else if (this.childrenData.length > this.children!) {
            this.childrenData = this.childrenData?.slice(0, this.children!);
            this.birthDates = this.birthDates?.slice(0, this.children!);
            this.heights = this.heights?.slice(0, this.children!);
            this.weights = this.weights?.slice(0, this.children!);
        } else if (this.childrenData.length < this.children) {
            for (let i = this.childrenData.length; i < this.children!; i++) {
                this.childrenData.push({
                        name: '',
                        surname: '',
                        birthDate: null,
                        height: null,
                        weight: null,
                    } as IChildData
                )
                this.birthDates.push(undefined);
                this.heights.push(undefined);
                this.weights.push(undefined);
            }
        }
        this.base.form.stepChildren!.children = this.childrenData;
    }
    setNameSurname(i: number, value: string) {
        this.childrenData![i].nameSurname = value;
        this.base.form.stepChildren!.children![i].nameSurname = value;
    }
    setBirthDate(i: number, value?: Date) {
        this.birthDates[i] = value;
        if (value) {
            this.base.form.stepChildren!.children![i].birthDate = new cvv_types.Date({ts: value.getTime()});
        } else {
            this.base.form.stepChildren!.children![i].birthDate = null;
        }
    }
    setHeight(i: number, value: string) {
        if(value.length > 6) {
            value = value.slice(0, 6);
        }
        if(parseFloat(value) > 300) {
            value = '300';
        }
        this.heights[i] = value;
        this.childrenData![i].height = isNaN(parseFloat(value)) ? undefined : parseFloat(value);
        this.base.form.stepChildren!.children![i].height = isNaN(parseFloat(value)) ? null : parseFloat(value);
    }
    setWeight(i: number, value: string) {
        if(value.length > 6) {
            value = value.slice(0, 6);
        }
        if(parseFloat(value) && parseFloat(value) > 1000) {
            value = '1000';
        }
        this.weights[i] = value;
        this.childrenData![i].weight = isNaN(parseFloat(value)) ? undefined : parseFloat(value);
        this.base.form.stepChildren!.children![i].weight = isNaN(parseFloat(value)) ? null : parseFloat(value);
    }

}
