import {observer} from "mobx-react-lite";
import {CvvCategoryHeader} from "../components/headers";
import {CvvInputField} from "../components/input";
import {CvvTextInput} from "../components/text_input";
import {ContentUnitFrame} from "../../components/dialogs";
import {FormStepContainer} from "../components/decorations";
import {useContext} from "react";
import {useTheme} from "styled-components";
import {Theme} from "../../theme";
import {MladiVoznikStoreContext} from "./form_mladi_voznik";
import {MladiVoznikStore} from "../../stores/mladi_voznik_store";
import {ReactComponent as NextSvg} from "../../assets/next.svg";
import {CvvButton} from "../../components/buttons";
import {CvvRadio} from "../../components/radio";
import {cvv_types, mladi_voznik} from "../../proto/compiled";
import {CvvCollapsible} from "../../components/collapsible";
import {FormStoreContext} from "../../index";
import {FormStore} from "../../stores/form_store";
import {PricelistItems, PricelistModifiers} from "../pricelist";
import {MladiVoznikSummary} from "./form_mladi_voznik_components";

export const FormMladiVoznikS4 = observer((props: any) => {
    const store = useContext(MladiVoznikStoreContext) as MladiVoznikStore;
    const formStore = useContext(FormStoreContext) as FormStore;

    const theme = useTheme() as Theme;

    let boClan = !!store.step1.result?.jeClan || !!store.form.step4?.clanstvo;
    return <FormStepContainer>
        {store.config.s4?.najemVozila && <><CvvCategoryHeader style={{marginTop: '52px'}}>
            Vozilo
        </CvvCategoryHeader>
            {!!store.form.prijatelj?.length && <ContentUnitFrame style={{padding: '16px'}}>
                <theme.textTheme.body.span>
                    Vozilo je že plačal {store.form.prijatelj}.
                </theme.textTheme.body.span>
            </ContentUnitFrame>}
            {!store.form.prijatelj?.length && <>
                <CvvRadio name={'vozilo'} id={'svoje'} checked={store.form.step4?.vozilo === cvv_types.Vozilo.svoje}
                          onChange={() => {
                              store.form.step4!.vozilo = cvv_types.Vozilo.svoje;
                          }} label={'SVOJE (0,00 €)'}/>
                <CvvRadio name={'vozilo'} id={'najeto'} checked={store.form.step4?.vozilo === cvv_types.Vozilo.najeto}
                          onChange={() => {
                              store.form.step4!.vozilo = cvv_types.Vozilo.najeto;
                          }} label={'VOZILO ŽELIM NAJETI ('
                    + formStore.pricelist?.getPriceEur(PricelistItems.vozilo)
                    + ')'}/>
                {store.config.s4?.najemVozilaSPrijateljem && <CvvRadio name={'vozilo'} id={'najeto-prijatelj'}
                                                                       checked={store.form.step4?.vozilo === cvv_types.Vozilo.najeto_prijatelj}
                                                                       onChange={() => {
                                                                           store.form.step4!.vozilo = cvv_types.Vozilo.najeto_prijatelj;
                                                                       }}
                                                                       label={'VOZILO ŽELIM NAJETI S PRIJATELJEM ('
                                                                           + formStore.pricelist?.getPriceEur(PricelistItems.vozilo, [PricelistModifiers.vozilo_s_prijateljem])
                                                                           + ')'}/>}
                <div style={{
                    marginTop: store.form.step4?.vozilo === cvv_types.Vozilo.najeto_prijatelj ? '-8px' : '0',
                    marginBottom: store.form.step4?.vozilo === cvv_types.Vozilo.najeto_prijatelj ? '16px' : '0',
                    width: "100%",
                    transition: 'all 300ms',
                    overflow: 'hidden'
                }}>
                    <CvvCollapsible expanded={store.form.step4?.vozilo === cvv_types.Vozilo.najeto_prijatelj}>
                        <ContentUnitFrame style={{padding: '16px'}}>
                            <theme.textTheme.body.span>
                                Obema bomo poslali link, preko katerega se lahko prijavi tvoj prijatelj (najem vozila se
                                obračuna le
                                pri tvoji prijavi).
                            </theme.textTheme.body.span>
                            <CvvInputField error={store.validators.step4.voziloPrijateljEmail()}
                                           label={'E-NASLOV PRIJATELJA'}
                                           input={
                                               <CvvTextInput
                                                   invalid={!!store.validators.step4.voziloPrijateljEmail()}
                                                   value={store.form.step4!.voziloPrijateljEmail!}
                                                   onChange={(e) => store.form.step4!.voziloPrijateljEmail = e.target.value}
                                                   {...store.form.step4?.vozilo !== cvv_types.Vozilo.najeto_prijatelj && {tabIndex: -1}}
                                               />
                                           }/>
                        </ContentUnitFrame>
                    </CvvCollapsible>
                </div>
                {store.config.s4?.najemVozilaZDrugoOsebo && <CvvRadio name={'vozilo'} id={'najeto-drugi'}
                                                                      checked={store.form.step4?.vozilo === cvv_types.Vozilo.najeto_druga_oseba}
                                                                      onChange={() => {
                                                                          store.form.step4!.vozilo = cvv_types.Vozilo.najeto_druga_oseba;
                                                                      }}
                                                                      label={'VOZILO ŽELIM NAJETI Z DRUGO OSEBO ('
                                                                          + formStore.pricelist?.getPriceEur(PricelistItems.vozilo, [PricelistModifiers.vozilo_z_drugim])
                                                                          + ')'}/>}
            </>}
        </>}
        {store.config.s4?.vSkupiniSPrijateljem && !store.form.prijatelj?.length &&
            <CvvCollapsible expanded={store.form.step4?.vozilo !== cvv_types.Vozilo.najeto_prijatelj}>
                <CvvCategoryHeader style={{marginTop: '52px'}}>
                    V skupini želim biti s prijateljem
                </CvvCategoryHeader>
                <CvvRadio
                    tabIndex={store.form.step4!.vozilo === cvv_types.Vozilo.najeto_prijatelj ? -1 : undefined}
                    name={'prijatelj'} id={'da'} checked={store.form.step4!.vSkupiniSPrijateljem!} onChange={() => {
                    store.form.step4!.vSkupiniSPrijateljem = true;
                }} label={'DA'}/>
                <CvvRadio
                    tabIndex={store.form.step4!.vozilo === cvv_types.Vozilo.najeto_prijatelj ? -1 : undefined}
                    name={'prijatelj'} id={'ne'} checked={!store.form.step4!.vSkupiniSPrijateljem} onChange={() => {
                    store.form.step4!.vSkupiniSPrijateljem = false;
                }} label={'NE'}/>
                <CvvCollapsible expanded={!!store.form.step4!.vSkupiniSPrijateljem}>
                    <CvvInputField error={store.validators.step4.skupinaPrijateljIme()}
                                   label={'IME IN PRIIMEK PRIJATELJA'}
                                   input={
                                       <CvvTextInput
                                           invalid={!!store.validators.step4.skupinaPrijateljIme()}
                                           value={store.form.step4!.skupinaPrijateljIme!}
                                           onChange={(e) => store.form.step4!.skupinaPrijateljIme = e.target.value}
                                           {...!store.form.step4?.vSkupiniSPrijateljem && {tabIndex: -1}}
                                       />
                                   }/>
                </CvvCollapsible>
            </CvvCollapsible>}

        {!store.step1.result?.jeClan && <>
            <CvvCategoryHeader style={{marginTop: '52px'}}>
                Skleni AMZS članstvo in prejmi brezplačno kosilo
            </CvvCategoryHeader>
            <CvvRadio name={'clanstvo'} id={'da'} checked={store.form.step4!.clanstvo!}
                      onChange={() => {
                          store.form.step4!.clanstvo = true;
                      }} label={'DA ('
                + formStore.pricelist?.getPriceEur(PricelistItems.clanstvo, Array.from(store.pricelistModifiers))
                + ')'}/>
            <CvvRadio name={'clanstvo'} id={'ne'} checked={!store.form.step4!.clanstvo} onChange={() => {
                store.form.step4!.clanstvo = false;
            }} label={'NE'}/>
        </>}

        {store.config.s4?.eIzpitKoda &&
            <CvvInputField label={'E-IZPIT KODA'} input={
                <CvvTextInput value={store.form.step4!.eIzpitKoda!}
                              onChange={(e) => store.form.step4!.eIzpitKoda = e.target.value}/>
            }/>}


        {store.config.s4?.kosilo && <><CvvCategoryHeader style={{marginTop: '52px'}}>
            Kosilo
        </CvvCategoryHeader>
            {boClan &&
                <ContentUnitFrame style={{padding: '16px', marginTop: '-10px', marginBottom: '8px'}}>
                    <theme.textTheme.button.span>Za člane AMZS je kosilo brezplačno.</theme.textTheme.button.span>
                </ContentUnitFrame>}
            {!boClan && <><CvvRadio name={'kosilo'} id={'da'} checked={store.form.step4!.kosilo!}
                                    onChange={() => {
                                        store.form.step4!.kosilo = true;
                                    }} label={'DA ('
                + formStore.pricelist?.getPriceEur(PricelistItems.kosilo, Array.from(store.pricelistModifiers))
                + ')'}/>
                <CvvRadio name={'kosilo'} id={'ne'} checked={!store.form.step4!.kosilo} onChange={() => {
                    store.form.step4!.kosilo = false;
                }} label={'NE'}/></>}</>}

        <div style={{marginTop: '32px'}}/>

        <theme.textTheme.body.span>
            Povzetek naročila
        </theme.textTheme.body.span>
        <MladiVoznikSummary/>
        {store.config.s4?.opomba && <>
            <div style={{marginTop: '32px'}}/>
            <CvvInputField label={'DRUGE OPOMBE'} input={
                <CvvTextInput
                    value={store.form.step4!.opomba ?? ''}
                    onChange={(e) => store.form.step4!.opomba = e.target.value}/>
            }/>
        </>}
        <CvvButton
            onClick={() => store.setStep(store.step + 1)}
            style={{marginTop: '64px'}}
            disabled={!store.stepValid(store.step, false)}>
            <theme.textTheme.headline5.span>NADALJUJ</theme.textTheme.headline5.span>
            <NextSvg height={'22px'} style={{marginLeft: 'auto'}}/>
        </CvvButton>
    </FormStepContainer>
})