import {observer} from "mobx-react-lite";
import {FormStepContainer} from "../components/decorations";
import React, {useContext} from "react";
import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../../theme";
import {MladiVoznikStoreContext} from "./form_mladi_voznik";
import {MladiVoznikStore} from "../../stores/mladi_voznik_store";
import {CvvCheckbox} from "../../components/checkbox";
import {FaAngleDown} from 'react-icons/fa'
import {CheckboxData, MladiVoznikStoreS5} from "../../stores/mladi_voznik_store_s5";
import {CvvCollapsible} from "../../components/collapsible";
import {CvvButton} from "../../components/buttons";
import {ReactComponent as NextSvg} from "../../assets/next.svg";
import {SanitizedHtml} from "../../components/sanitize_html";

const TermsList = styled.ol`
  padding: 0;
  margin: 0;
  text-align: left;
  ${({theme}: StyledProps) => theme.textTheme.body.css};

  && > li {
    margin-top: 20px;
  }
`;

const CollapsibleContainer = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
`

const CollapsibleHeaderContainer = styled.div`
  display: flex;
  min-height: 30px;
  width: 100%;
  align-items: center;
  cursor: pointer;
`;

const ExpandIconContainer = styled.div<{ collapse: boolean }>`
  margin-left: auto;
  font-size: 20px;
  cursor: pointer;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: rotate(${({collapse}) => collapse ? '180deg' : '0'});
  transition: all 300ms;
`;

function getCheckbox(checkbox: CheckboxData, store: MladiVoznikStoreS5, path: string, owner?: CheckboxData) {
    return <CollapsibleContainer key={'agreement-' + path}>
        <CvvCheckbox
            label={<CollapsibleHeaderContainer>
                {checkbox.content}
                <ExpandIconContainer
                    style={{
                        opacity: checkbox.children ? '1' : '0',
                        pointerEvents: checkbox.children ? 'auto' : 'none',
                    }}
                    collapse={!!checkbox.expanded}
                    onClick={(e) => {
                        e.stopPropagation();
                        checkbox.expanded = !checkbox.expanded;
                    }}>
                    <FaAngleDown/>
                </ExpandIconContainer>
            </CollapsibleHeaderContainer>}
            checked={!!checkbox.checked}
            onChange={() => {
                store.toggle(checkbox);
            }}/>
        {checkbox.children &&
            <CvvCollapsible expanded={!!checkbox.expanded}>
                {checkbox.children.map((c, i) => getCheckbox(c, store, path + '/' + i, checkbox))}
            </CvvCollapsible>}
    </CollapsibleContainer>;
}

export const FormMladiVoznikS5 = observer((props: any) => {
    const store = useContext(MladiVoznikStoreContext) as MladiVoznikStore;
    const theme = useTheme() as Theme;

    return <FormStepContainer style={{
        paddingTop: '32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }}>
        <theme.textTheme.headline2.span style={{textAlign: 'center', fontSize: '35px'}}>
            Izjava o odgovornosti
        </theme.textTheme.headline2.span>
        <br/>
        <br/>
        <br/>
        {store.formStore?.meta?.gdpr && <SanitizedHtml style={{width: '100%'}} html={store.formStore?.meta?.gdpr}/>}
        {!store.formStore?.meta?.gdpr && <>
            <theme.textTheme.body.span style={{fontWeight: 'bold', marginBottom: '8px', marginRight: 'auto'}}>
                S podpisom te izjave kot udeleženec dogodka ali programa izjavljam:
            </theme.textTheme.body.span>
            <theme.textTheme.body.span>
                Vaše osebne podatke bo AMZS d.d. uporabil za namen »izvajanja komercialnega tečaja«. Če nam svojih
                osebnih podatkov ne želite posredovati, se na tečaj ne morete prijaviti. Vaše osebne podatke bomo v
                skladu z vašo privolitvijo obdelovali tudi za namen pošiljanja obvestil, ponudb in anket. Če se odločite
                za prilagojene novice, bomo vaše podatke, ki jih pridobimo med našim sodelovanjem, uporabili za namen
                neposrednega trženja na podlagi profiliranja. Od prejemanja sporočil se lahko kadarkoli odjavite tako,
                da na elektronski naslov info@amzs.si pošljete ključno besedo »odjava«. Vaše osebne podatke bomo za
                namen izvajanja tečaja hranili še pet let od zaključka tečaja oziroma poravnave vseh obveznosti, za
                namen obvestil, ponudb in anket pa do vašega preklica. Obveščamo vas še, da imate pravico dostopa do
                svojih osebnih podatkov, njihov popravek oziroma dopolnitev, izbris (v primeru neposrednega trženja),
                pravico do omejitve obdelave in pravico do prenosljivosti podatkov. Če boste menili, da vaših pravic ne
                uresničujemo ustrezno, se lahko pritožite Informacijskemu pooblaščencu RS (ip-rs.si). Več o politiki
                zasebnosti
            </theme.textTheme.body.span>
        </>}

        <div style={{width: '100%', marginLeft: '-32px', marginTop: '32px'}}>
            {store.step5.checkboxes.map((c, i) => getCheckbox(c, store.step5, '/' + i))}
        </div>

        <CvvButton
            onClick={() => store.setStep(store.step + 1)}
            style={{marginTop: '64px'}}
            disabled={!store.stepValid(store.step, false)}>
            <theme.textTheme.headline5.span>PODPIŠI</theme.textTheme.headline5.span>
            <NextSvg height={'22px'} style={{marginLeft: 'auto'}}/>
        </CvvButton>
    </FormStepContainer>
})
