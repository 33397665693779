import {AdminStore} from "./admin_store";
import {makeAutoObservable} from "mobx";
import {admin_tecaj, cvv_types} from "../../proto/compiled";
import {proto} from "../../proto/messages";
import {SocketApiAckStatus} from "proto_socket_typescript";
import {CvvRoutes} from "../../strings";

export class AdminRazpisaniTecajStore {
    private disposeCallbacks: (() => any)[] = [];
    private readonly id?: string;

    base: AdminStore;
    razpisaniTecaj?: admin_tecaj.IAdminRazpisaniTecaj;
    tecajInstructors?: admin_tecaj.IAdminTecajInstructors;
    tecajTipKey = '';
    tecajKey = '';
    error = '';
    saved = false;

    constructor(base: AdminStore, id?: string) {
        this.base = base;
        this.tecajTipKey = base.tecajTip ?? '';
        this.tecajKey = base.tecaj ?? '';
        this.id = id;

        makeAutoObservable(this);

        this.onSub(this.base.api.getMessageHandler(new proto.RxAdminRazpisaniTecaj()).subscribe((m) => this.onRazpisaniTecaj(m)));
        this.onSub(this.base.api.getMessageHandler(new proto.RxAdminTecajInstructors()).subscribe((m) => this.onTecajInstructors(m)));

        this.base.api.connection.whenConnected.then(() => this.load());
    }

    load(): void {
        if (this.id) {
            this.base.loading++;
            this.base.api.sendMessage(proto.TxAdminLoadRazpisaniTecaj.create({
                id: this.id
            })).then(() => this.base.loading--);
        } else if (this.tecajKey.length) {
            this.base.loading++;
            this.base.api.sendMessage(proto.TxAdminLoadRazpisaniTecaj.create({
                tecajTipKey: this.tecajTipKey,
                tecajKey: this.tecajKey,
            })).then(() => this.base.loading--);
        }
    }

    private onRazpisaniTecaj(message: proto.RxAdminRazpisaniTecaj) {
        this.razpisaniTecaj = message.proto;
        this.razpisaniTecaj.instructor = this.razpisaniTecaj.instructor ?? new cvv_types.Choice();
        makeAutoObservable(this.razpisaniTecaj.instructor!);
        if (this.razpisaniTecaj.id) {
            const location = CvvRoutes.admin.razpisaniTecaj(this.razpisaniTecaj.id);
            window.history.replaceState({}, '', location);
        }

        try {
            makeAutoObservable(this.razpisaniTecaj);
            this.razpisaniTecaj.days?.forEach((d) => {
                d.start = d.start ?? new cvv_types.Date();
                d.end = d.end ?? new cvv_types.Date();
                d.location = d.location ?? new cvv_types.Choice();
                makeAutoObservable(d.start!);
                makeAutoObservable(d.end!);
                makeAutoObservable(d.location!);
            });
        } catch (e) {
        }
        this.tecajTipKey = this.razpisaniTecaj.tecajTip?.text ?? this.tecajTipKey;
        this.tecajKey = this.razpisaniTecaj.tecaj?.text ?? this.tecajKey;
    }

    dispose() {
        for (const dc of this.disposeCallbacks) {
            dc();
        }
    }

    private onSub(subscription: any) {
        this.disposeCallbacks.push(() => subscription.unsubscribe());
    }

    setTecaj(key: string) {
        this.tecajKey = key;
        this.load();
    }

    setTecajTip(key: string) {
        this.tecajTipKey = key;
        this.tecajKey = '';
    }

    async save() {
        this.saved = false;
        const response = await this.base.api.sendMessage(
            proto.TxAdminSaveRazpisaniTecaj.create({
                tecaj: this.razpisaniTecaj,
            }), {ack: true}
        );

        if (response.status === SocketApiAckStatus.success) {
            this.saved = true;
            this.error = '';
            this.base.calendars.load();
        } else {
            this.error = response.errorMessage ?? '';
        }
    }

    private onTecajInstructors(m: proto.RxAdminTecajInstructors) {
        this.tecajInstructors = m.proto;
    }
}