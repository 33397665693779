import {SocketApi} from "proto_socket_typescript";
import {MladiVoznikStore} from "./mladi_voznik_store";
import {cvv_types} from "../proto/compiled";
import {DateFormatters} from "../utils";
import {makeAutoObservable} from "mobx";

export class MladiVoznikStoreS3 {
    api: SocketApi;
    base: MladiVoznikStore;
    datumIzdajeVozniske?: Date;
    datumIzdajeVozniskeEdited: boolean = false;
    datumOpravljenegaIntenzivnegaTecaja?: Date;
    datumNapotila?: Date;
    datumOpravljenegaIntenzivnegaTecajaEdited: boolean = false;
    datumNapotilaEdited: boolean = false;
    kazenskeSoglasje: boolean = false;

    constructor(base: MladiVoznikStore, api: SocketApi) {
        this.api = api;
        this.base = base;
        if (this.base.form.step2?.date?.dateTime?.ts) {
            this.datumIzdajeVozniske = new Date(this.base.form.step2!.date!.dateTime!.ts as number);
        }
        if (this.base.form.step3?.datumOpravljenegaIntenzivnegaTecaja?.ts) {
            this.datumOpravljenegaIntenzivnegaTecaja = new Date(this.base.form.step3.datumOpravljenegaIntenzivnegaTecaja.ts as number);
        }
        if (this.base.form.step3?.napotiloDatum?.ts) {
            this.datumNapotila = new Date(this.base.form.step3.napotiloDatum.ts as number);
        }
        makeAutoObservable(this);
    }

    init() {
    }

    dispose() {
    }

    get done(): boolean {
        return true;
    };

    datumIzdajeValidator(ignore: boolean) {
        if (ignore && !this.datumIzdajeVozniskeEdited) return undefined;
        if (!this.base.form.step3!.datumIzdajeVozniske) return 'Vnesite veljaven datum.';
        const age = Date.now() - (this.base.form.step3!.datumIzdajeVozniske.ts as number);

        const nowMinus6M = new Date();
        nowMinus6M.setMonth(nowMinus6M.getMonth() - 6);
        const nowMinus6MAge = Date.now() - nowMinus6M.getTime();
        if (age < nowMinus6MAge) return 'Od datuma izdaje vozniškega dovoljenja, do datuma opravljanja tečaja mora miniti vsaj 6 mesecev!';
        return undefined;
    }

    datumOpravljenegaIntenzivnegaTecajaValidator(ignore: boolean) {
        if (ignore && !this.datumOpravljenegaIntenzivnegaTecajaEdited) return undefined;
        if (!this.base.form.step3!.datumOpravljenegaIntenzivnegaTecaja) return 'Vnesite veljaven datum.';
        if (this.base.form.step3!.datumOpravljenegaIntenzivnegaTecaja.ts as number >= Date.now()) {
            return 'Datum opravljenega intenzivnega tečaja mora biti v preteklosti!';
        }
        return undefined;
    }

    datumNapotilaValidator(ignore: boolean) {
        if (ignore && !this.datumNapotilaEdited) return undefined;
        if (!this.base.form.step3!.napotiloDatum) return 'Vnesite veljaven datum.';
        if (this.base.form.step3!.napotiloDatum.ts as number >= Date.now()) {
            return 'Datum napotila mora biti v preteklosti!';
        }
        return undefined;
    }

    setDatumIzdajeVozniske(value?: Date) {
        this.datumIzdajeVozniske = value;
        this.datumIzdajeVozniskeEdited = true;
        if (value) {
            this.base.form.step3!.datumIzdajeVozniske = new cvv_types.Date({ts: value.getTime()});
        } else {
            this.base.form.step3!.datumIzdajeVozniske = null;
        }
    }

    setDatumOpravljenegaIntenzivnegaTecaja(value?: Date) {
        this.datumOpravljenegaIntenzivnegaTecaja = value;
        this.datumOpravljenegaIntenzivnegaTecajaEdited = true;
        if (value) {
            this.base.form.step3!.datumOpravljenegaIntenzivnegaTecaja = new cvv_types.Date({ts: value.getTime()});
        } else {
            this.base.form.step3!.datumOpravljenegaIntenzivnegaTecaja = null;
        }
    }

    setDatumNapotila(value?: Date) {
        this.datumNapotila = value;
        this.datumNapotilaEdited = true;
        if (value) {
            this.base.form.step3!.napotiloDatum = new cvv_types.Date({ts: value.getTime()});
        } else {
            this.base.form.step3!.napotiloDatum = null;
        }
    }
}
