import {observer} from "mobx-react-lite";
import {FormStepContainer} from "../components/decorations";
import {useCallback, useContext} from "react";
import {MladiVoznikStoreContext} from "./form_mladi_voznik";
import {useTheme} from "styled-components";
import {Theme} from "../../theme";
import {CalendarDay, CvvCalendarGrid, CvvCalendarHeader} from "../components/calendar";
import {CvvLessonSlot} from "../components/slots";
import {MladiVoznikStore} from "../../stores/mladi_voznik_store";
import {TailSpin} from "react-loader-spinner"
import {zfill} from "../../utils";

export const FormMladiVoznikS2 = observer((props: any) => {
    const store = useContext(MladiVoznikStoreContext) as MladiVoznikStore;
    const theme = useTheme() as Theme;
    const buildDay = useCallback((date?: Date, payload?: any) => {
        const slotDate = store.form.step2?.date && new Date(store.form.step2!.date!.dateTime!.ts as number);
        const inPast = !!date && date.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;
        const hasTecaj = !!date && !!store.step2.calendarData?.daysWithLectures?.includes(date!.getDate());
        return <CalendarDay
            key={date?.getTime() || Math.random()}
            selected={!!store.step2.selectedDay && store.step2.selectedDay.toDateString() === date?.toDateString()}
            slotSelected={!!slotDate && date?.toDateString() === slotDate.toDateString()}
            hint={hasTecaj}
            clickable={!!date && !inPast && hasTecaj}
            onClick={() => store.step2.setSelectedDay(date!)}>
            {date?.getDate()}
        </CalendarDay>
    }, [store.form.step2, store.step2]);

    const slots: any[] = [];
    if (store.step2.selectedDayData) {
        for (const slot of store.step2.selectedDayData!.lectures) {
            const date = new Date(slot.dateTime!.ts as number)
            slots.push(<div
                onClick={() => store.step2.setDate(slot)}
                key={date.getTime()} style={{marginTop: '8px', width: '80%'}}>
                <CvvLessonSlot
                    title={zfill(date.getHours(), 2) + ':' + zfill(date.getMinutes(), 2)}
                    selected={store.form.step2?.date?.id === slot.id}
                    freePlaces={slot.freePlaces}
                />
            </div>)
        }
    }

    return <FormStepContainer style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '32px'
    }}>
        <theme.textTheme.headline2.span>
            Izberite svoj termin!
        </theme.textTheme.headline2.span>
        <div style={{
            margin: '20px auto 0 auto',
            textAlign: 'center'
        }}>
            <CvvCalendarHeader controller={store.step2.calendarController}/>
            <CvvCalendarGrid controller={store.step2.calendarController} dayBuilder={buildDay}/>
            {!store.step2.selectedDayData && store.step2.selectedDayData &&
            <TailSpin color={theme.primary} height={80} width={80}/>}
            <theme.textTheme.error.span style={{
                opacity: (!store.ignoreEmptyValidations && !store.step2.done) ? 1 : 0,
                display: 'block',
                transition: 'opacity 300ms ease',
            }}>
                Izberite datum tečaja!
            </theme.textTheme.error.span>
        </div>
        {slots}
    </FormStepContainer>
})