import {AdminStore} from "./admin_store";
import {makeAutoObservable} from "mobx";

export class AdminTecajiStore {
    private disposeCallbacks: (() => any)[] = [];

    base: AdminStore;
    selectedDate?: Date;

    constructor(base: AdminStore) {
        this.base = base;

        makeAutoObservable(this);

        this.base.api.connection.whenConnected.then(() => this.load());
    }

    load(): void {
    }

    dispose() {
        for (const dc of this.disposeCallbacks) {
            dc();
        }
    }

    private onSub(subscription: any) {
        this.disposeCallbacks.push(() => subscription.unsubscribe());
    }
}