import styled from "styled-components";
import {StyledProps} from "../theme";
import React, {CSSProperties} from "react";

export const CvvCheckboxLabel = styled.label`
  margin-left: 16px;
  width: 100%;
  ${({theme}: StyledProps) => theme.textTheme.body.with({size: '10px'}).css};
`;

export const CvvCheckboxContainer = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  cursor: pointer;
  user-select: none;
  min-height: 30px;
  align-items: center;
`

const CvvCheckboxControl = styled.span`
  position: relative;
  height: 20px;
  width: 20px;
  margin-top: 4px;
  border: solid 1px ${({theme}: StyledProps) => theme.content};

  &:after {
    position: absolute;
    content: "";
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid ${({theme}: StyledProps) => theme.content};
    border-width: 0 3px 3px 0;
    opacity: 0;
    rotation: 20deg;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(20deg);
    transition: all 150ms;
  }
`;

export const CvvCheckboxHidden = styled.input.attrs(props => ({
    type: 'checkbox',
    name: props.name
}))`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &&:checked ~ ${CvvCheckboxControl}:after {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

interface CvvCheckboxProps {
    name?: string;
    id?: string;
    label: any;
    checked: boolean;
    style?: CSSProperties;
    tabIndex?: number;
    onChange: () => any;
}

export const CvvCheckbox = (props: CvvCheckboxProps) => {
    return <CvvCheckboxContainer
        style={{marginBottom: '12px'}}
        onClick={(e) => {
            props.onChange();
        }}>
        <CvvCheckboxHidden
            id={props.name + '-' + props.id}
            name={props.name}
            checked={props.checked}
            value={props.id}
            onChange={(e) => props.onChange()}
            {...props.tabIndex !== undefined && {tabIndex: props.tabIndex}}
        />
        <CvvCheckboxControl/>
        <CvvCheckboxLabel style={{
            ...props.style,
            pointerEvents: 'none',
        }} htmlFor={props.id}>{props.label}</CvvCheckboxLabel>
    </CvvCheckboxContainer>;
}