import {observer} from "mobx-react-lite";
import {useContext, useState} from "react";
import {AdminStore} from "./stores/admin_store";
import {AdminStoreContext} from "./protected_route";
import {AdminHeader} from "./components/admin_decorations";
import styled, {useTheme} from "styled-components";
import {CvvColors, CvvTheme, StyledProps, Theme} from "../theme";
import {DateFormatters} from "../utils";
import {MdAdd, MdClose, MdDelete, MdEdit, MdPlusOne, MdSave, MdWarning} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {CvvPaymentType, CvvStrings} from "../strings";
import {AdminButton} from "./admin_scaffold";
import {admin_participants, billing, cvv_types, mladi_voznik} from "../proto/compiled";
import {LoadingOverlay} from "../components/overlays";
import {UnovciBonDialog, YesNoDialog} from "../components/dialogs";
import {appStore, AppStoreContext} from "../index";
import {usePlacesWidget} from "react-google-autocomplete";
import {GMAPS_API_KEY} from "../globals";
import {MladiVoznikStore} from "../stores/mladi_voznik_store";
import {toast} from "react-toastify";
import DatePicker from "react-date-picker";
import {AdminDatepickerStyles} from "../components/datepicker_styles";
import {makeAutoObservable} from "mobx";
import {proto} from "../proto/messages";
import {SocketApiAckStatus} from "proto_socket_typescript";

const ParticipantGrid = styled.div<{ columns: string }>`
    width: 100%;
    display: grid;
    grid-template-columns: ${({columns}) => columns};
    gap: 1px;
    margin-bottom: 10px;
`;

const ParticipantGridHeaderCell = styled.div<{ span?: number }>`
    display: flex;
    align-items: center;
    padding: 0 4px 0 4px;
    line-height: 27px;
    height: 27px;
    outline: solid 1px #CCCCCC;
    background-color: ${({theme}) => theme.content10};
    grid-column-end: span ${({span}) => span ?? 1};
    overflow: hidden;
    ${({theme}: StyledProps) => theme.textTheme.body.with({color: 'black', weight: 'bold'}).css};
`;

const ParticipantGridCell = styled.div<{ span?: number, appearance?: cvv_types.GridColumnStyle, dense?: boolean }>`
    display: flex;
    outline: solid 1px #CCCCCC;
    line-height: ${({dense}: StyledProps) => dense ? '20px' : '30px'};
    padding: 0 5px 0 5px;
    grid-column-end: span ${({span}) => span ?? 1};
    overflow: hidden;
    white-space: nowrap;
    ${({theme, appearance, dense}: StyledProps) => {
        let color;
        if (dense) {
            color = 'white';
        } else {
            switch (appearance) {
                case cvv_types.GridColumnStyle.error:
                    color = 'white';
                    break;
                default:
                    color = 'black';
                    break;
            }
        }
        return theme.textTheme.body.with({color: color}).css;
    }};
    background-color: ${({theme, appearance, dense}: StyledProps) => {
        if (dense) return theme.content50;
        switch (appearance) {
            case cvv_types.GridColumnStyle.error:
                return '#CC0200';
            default:
                return 'transparent';
        }
    }};
`;

export const AdminParticipant = observer(() => {
    const [editing, setEditing] = useState(false);
    const store = useContext(AdminStoreContext) as AdminStore;
    const participant = store.participants.participant!;
    const summary = participant.summary!;
    const form = participant.form!;
    const formConfig = participant.formConfig!;
    const start = new Date(summary.tecajStart!.ts as number);
    const navigate = useNavigate();
    const theme = useTheme() as Theme;
    const saldo = ((participant.amountTotal ?? 0) - (participant.amountPaid ?? 0)) / 100;
    const children = !!form.step1?.children;
    let childrenObservable = false;

    return <>
        <AdminHeader>
            PODROBNOSTI O PRIJAVI
            <span style={{marginLeft: 'auto'}}/>
            {summary.daysPresent?.length === summary.nDays &&
                <LoadingOverlay style={{marginLeft: '8px'}} scale={0} builder={(action) => <AdminButton
                    style={{color: theme.content, borderColor: theme.primaryDark}}
                    background={theme.primary}
                    backgroundHover={'#ffe871'}
                    onClick={() => {
                        if (!summary.stevilkaPotrdila) {
                            appStore.showDialog(
                                <YesNoDialog result={async (result) => {
                                    appStore.hideDialog();
                                    if (result) {
                                        await action(store.participants.downloadConfirmation());
                                        store.participants.setParticipant(summary.sessionId!);
                                    }
                                }}>
                                    <CvvTheme.textTheme.headline5.span>
                                        Ali ste prepričani, da želite ustvariti potrdilo?
                                    </CvvTheme.textTheme.headline5.span>
                                </YesNoDialog>
                            );
                        } else {
                            action(store.participants.downloadConfirmation());
                        }
                    }}>
                    {summary.stevilkaPotrdila && `Prenesi potrdilo (${summary.stevilkaPotrdila})`}
                    {!summary.stevilkaPotrdila && 'Ustvari potrdilo'}
                </AdminButton>
                }/>}
            {!summary.racunIzdan && <LoadingOverlay scale={0} builder={(action) => <AdminButton
                style={{color: theme.content, borderColor: theme.primaryDark}}
                background={theme.primary}
                backgroundHover={'#ffe871'}
                onClick={async () => {
                    const response = await action(store.api.sendMessage(proto.TxAdminSendPredracun.create({
                        sessionId: summary.sessionId
                    }), {ack: true}));
                    if (response.status === SocketApiAckStatus.success) {
                        toast.success('Poslano');
                        store.participants.setParticipant(summary.sessionId!);
                        store.participants.load();
                    } else {
                        toast.error(response.errorMessage ?? 'Neznana napaka');
                    }
                }}>
                Pošlji predračun na e-pošto
            </AdminButton>
            }/>}
            {!participant.summary?.archived && <span
                style={{
                    marginLeft: '8px',
                    cursor: 'pointer',
                    height: '100%',
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center"
                }}
                onClick={() => {
                    appStore.showDialog(
                        <YesNoDialog result={(result) => {
                            appStore.hideDialog();
                            if (result) {
                                store.participants.archive(participant);
                            }
                        }}>
                            <CvvTheme.textTheme.headline5.span>
                                <center>Ali ste prepričani, da želite arhivirati prijavo?</center>
                            </CvvTheme.textTheme.headline5.span>
                        </YesNoDialog>
                    )
                }}>
                <MdDelete style={{fontSize: '20px', fontWeight: 'bold'}}/>
            </span>}
            <span
                style={{
                    marginLeft: '8px',
                    cursor: 'pointer',
                    height: '100%',
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: editing ? theme.primary : 'transparent',
                    color: editing ? theme.content : 'white',
                    padding: editing ? '0 8px 0 8px' : undefined,
                    transition: 'all 300ms'
                }}
                onClick={() => {
                    if (editing) {
                        store.api.sendMessage(proto.TxSaveMladiVoznikFormAdmin.create({
                            sessionId: summary.sessionId,
                            form: form
                        }), {ack: true}).then((r) => {
                            if (r.status !== SocketApiAckStatus.success) {
                                toast.error(r.errorMessage ?? 'Neznana napaka.');
                            }
                            store.participants.setParticipant(summary.sessionId!);
                            store.participants.load();
                        });
                    }
                    if(!editing && children && !childrenObservable && form.stepChildren!.children) {
                        for(const child of form.stepChildren!.children) {
                            makeAutoObservable(child);
                            if(child.birthDate) {
                                makeAutoObservable(child.birthDate);
                            }
                        }
                        childrenObservable = true;
                    }
                    setEditing(!editing);
                }}>
                {!editing && <MdEdit style={{fontSize: '20px', fontWeight: 'bold'}}/>}
                {editing && <MdSave style={{fontSize: '20px', fontWeight: 'bold'}}/>}
            </span>
            <span
                style={{
                    marginLeft: '8px',
                    cursor: 'pointer',
                    height: '100%',
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center"
                }}
                onClick={() => {
                    if (editing) {
                        store.participants.setParticipant(summary.sessionId!);
                        store.participants.load();
                        setEditing(false);
                    } else {
                        store.participants.setParticipant();
                    }
                }}>
            <MdClose style={{fontSize: '20px', fontWeight: 'bold'}}/>
            </span>
        </AdminHeader>
        <ParticipantGrid columns={'auto auto'}>
            <ParticipantGridHeaderCell span={2}>
                PODATKI O TEČAJU
            </ParticipantGridHeaderCell>
            <ParticipantGridCell>
                {summary.tecajTipName?.toUpperCase()} - {summary.tecajName?.toUpperCase()}
            </ParticipantGridCell>
            <ParticipantGridCell>
                DATUM: {!editing && <>{summary.location} - {DateFormatters.date(start)} ({DateFormatters.time(start)})</>}
                {editing && <select style={{margin: 'auto 0 auto 8px'}} value={form.step2!.date!.id!}
                                    onChange={(e) => store.setTecajTip(e.target.value)}>
                    {participant.alternativeDates?.map((t) => <option key={'alternative-date-' + t.id!}
                                                                      value={t.id!}>
                        {t?.location} - {DateFormatters.date(new Date(t!.dateTime!.ts as number))} ({DateFormatters.time(new Date(t!.dateTime!.ts as number))})
                    </option>)}
                </select>}
            </ParticipantGridCell>
        </ParticipantGrid>
        <ParticipantGrid columns={'auto auto auto'}>
            <ParticipantGridHeaderCell span={3}>
                PODATKI VEZANI NA TEČAJ
            </ParticipantGridHeaderCell>
            <ParticipantGridCell>
                {participant.form?.prijatelj && `VOZILO JE NAJEL PRIJATELJ (${participant.form.prijatelj})`}
                {!participant.form?.prijatelj && <>
                    VOZILO: {!editing && CvvStrings.vozilo[form!.step4!.vozilo as number]}
                    {editing && <select style={{margin: 'auto 0 auto 8px'}} value={form!.step4!.vozilo!.toString()}
                                        onChange={(e) => form!.step4!.vozilo = parseInt(e.target.value)}>
                        <option value={cvv_types.Vozilo.svoje}>
                            {CvvStrings.vozilo[cvv_types.Vozilo.svoje]}
                        </option>
                        {formConfig.s4?.najemVozila && <option value={cvv_types.Vozilo.najeto}>
                            {CvvStrings.vozilo[cvv_types.Vozilo.najeto]}
                        </option>}
                        {formConfig.s4?.najemVozilaSPrijateljem && <option value={cvv_types.Vozilo.najeto_prijatelj}>
                            {CvvStrings.vozilo[cvv_types.Vozilo.najeto_prijatelj]}
                        </option>}
                        {formConfig.s4?.najemVozilaZDrugoOsebo && <option value={cvv_types.Vozilo.najeto_druga_oseba}>
                            {CvvStrings.vozilo[cvv_types.Vozilo.najeto_druga_oseba]}
                        </option>}
                    </select>}
                </>}
            </ParticipantGridCell>
            {participant.form?.step4?.vozilo === cvv_types.Vozilo.najeto_prijatelj && <ParticipantGridCell>
                VOZILO PRIJATELJ
                E-MAIL: {!editing && (participant.form?.step4?.vozilo === cvv_types.Vozilo.najeto_prijatelj ? participant.form?.step4?.voziloPrijateljEmail : '/')}
                {editing && <input type={"text"}
                                   style={{margin: 'auto 0 auto 8px'}}
                                   value={participant.form?.step4?.voziloPrijateljEmail ?? ''}
                                   onChange={(e) => {
                                       participant.form!.step4!.voziloPrijateljEmail = e.target.value;
                                   }}/>
                }
            </ParticipantGridCell>}
            {formConfig.s4?.vSkupiniSPrijateljem && <ParticipantGridCell>
                V SKUPINI S PRIJATELJEM:
                {!editing && (participant.form?.step4?.vSkupiniSPrijateljem ? participant.form?.step4?.skupinaPrijateljIme : 'NE')}
                {editing && <input type={"text"}
                                   style={{margin: 'auto 0 auto 8px'}}
                                   value={participant.form?.step4?.skupinaPrijateljIme ?? ''}
                                   onChange={(e) => {
                                       participant.form!.step4!.vSkupiniSPrijateljem = !!e.target.value.length;
                                       participant.form!.step4!.skupinaPrijateljIme = e.target.value;
                                   }}/>
                }
            </ParticipantGridCell>}
            {formConfig.s4?.kosilo && <ParticipantGridCell>
                {editing && !participant.clanskaStevilka && <input type={"checkbox"}
                                                                   style={{marginRight: '8px'}}
                                                                   checked={participant.form?.step4?.kosilo!}
                                                                   onChange={() => participant.form!.step4!.kosilo = !participant.form?.step4?.kosilo}/>}
                KOSILO{participant.clanskaStevilka ? ': ZASTONJ' : !editing ? ': ' + (participant.form!.step4!.kosilo ? 'DA' : 'NE') : undefined}
            </ParticipantGridCell>}
            {formConfig.s4?.opomba && <ParticipantGridCell>
                OPOMBA: {!editing && form.step4?.opomba}
                {editing && <input type={"text"}
                                   style={{margin: 'auto 0 auto 8px'}}
                                   value={participant.form?.step4?.opomba ?? ''}
                                   onChange={(e) => {
                                       participant.form!.step4!.opomba = e.target.value;
                                   }}/>}
            </ParticipantGridCell>}
            {!participant.clanskaStevilka && <ParticipantGridCell>
                {editing && <input type={"checkbox"}
                                   style={{marginRight: '8px'}}
                                   checked={participant.form?.step4?.clanstvo!}
                                   onChange={() => participant.form!.step4!.clanstvo = !participant.form?.step4?.clanstvo}/>}
                SKLENI ČLANSTVO{!editing ? ': ' + (participant.form!.step4!.clanstvo ? 'DA' : 'NE') : undefined}
            </ParticipantGridCell>}
            {participant.clanskaStevilka && <ParticipantGridCell>
                ČLANSKA ŠTEVILKA: {participant.clanskaStevilka}
            </ParticipantGridCell>}
            {formConfig.s4?.eIzpitKoda && <ParticipantGridCell>
                E-IZPIT KODA: {!editing && (participant.form?.step4?.eIzpitKoda ?? '/')}
                {editing && <input type={"text"}
                                   style={{margin: 'auto 0 auto 8px'}}
                                   value={participant.form?.step4?.eIzpitKoda ?? ''}
                                   onChange={(e) => {
                                       participant.form!.step4!.eIzpitKoda = e.target.value;
                                   }}/>
                }
            </ParticipantGridCell>}
        </ParticipantGrid>
        <ParticipantGrid columns={'auto auto auto'}>
            <ParticipantGridHeaderCell span={3}>
                PODATKI O OSEBI
            </ParticipantGridHeaderCell>
            {formConfig.s1?.nameSurname && <ParticipantGridCell>
                IME IN PRIIMEK: {!editing && form.step1?.nameSurname}
                {editing && <input type={"text"}
                                   style={{margin: 'auto 0 auto 8px'}}
                                   value={participant.form?.step1?.nameSurname ?? ''}
                                   onChange={(e) => {
                                       participant.form!.step1!.nameSurname = e.target.value;
                                   }}/>}
            </ParticipantGridCell>}
            {formConfig.s1?.address && <ParticipantGridCell>
                NASLOV: {!editing && form.step1?.address?.address}
                {editing && <AdminAddressPicker address={form.step1?.address!}/>}
            </ParticipantGridCell>}
            {formConfig.s1?.mailingAddress && <ParticipantGridCell>
                NASLOV ZA POŠILJANJE: {!editing && form.step1?.mailingAddress?.address}
                {editing && <AdminAddressPicker address={form.step1?.mailingAddress!}/>}
            </ParticipantGridCell>}
            {formConfig.s1?.email && <ParticipantGridCell>
                E-MAIL: {!editing && form.step1?.email}
                {editing && <input type={"text"}
                                   style={{margin: 'auto 0 auto 8px'}}
                                   value={participant.form?.step1?.email ?? ''}
                                   onChange={(e) => {
                                       participant.form!.step1!.email = e.target.value;
                                   }}/>}
            </ParticipantGridCell>}
            {formConfig.s1?.children && <ParticipantGridCell>
                ŠTEVILO OTROK: {form.step1?.children}
                {editing && <span
                    style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        height: '100%',
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    onClick={() => {
                        form.step1!.children = (form.step1!.children ?? 0) + 1;
                        if (!form.stepChildren?.children) {
                            form.stepChildren!.children = [];
                        }
                        if (form.stepChildren!.children.length < form.step1!.children!) {
                            form.stepChildren!.children.push({
                                nameSurname: '',
                                birthDate: undefined,
                                height: undefined,
                                weight: undefined
                            });
                            makeAutoObservable(form.stepChildren!.children[form.stepChildren!.children.length - 1]);
                        }
                    }}
                >
                    <MdAdd style={{fontSize: '15px', fontWeight: 'bolder'}}></MdAdd>
                </span>}
            </ParticipantGridCell>}
            {formConfig.s1?.phone && <ParticipantGridCell>
                TELEFON: {!editing && form.step1?.phone}
                {editing && <input type={"text"}
                                   style={{margin: 'auto 0 auto 8px'}}
                                   value={participant.form?.step1?.phone ?? ''}
                                   onChange={(e) => {
                                       participant.form!.step1!.phone = e.target.value;
                                   }}/>}
            </ParticipantGridCell>}
            {formConfig.s1?.isStudent && <ParticipantGridCell>
                {editing && <input type={"checkbox"}
                                   style={{marginRight: '8px'}}
                                   checked={participant.form?.step1?.isStudent!}
                                   onChange={() => participant.form!.step1!.isStudent = !participant.form?.step1?.isStudent}/>}
                ŠTUDENT / DIJAK{!editing ? ': ' + (participant.form!.step1!.isStudent ? 'DA' : 'NE') : undefined}
            </ParticipantGridCell>}
            {formConfig.s3?.emso && <ParticipantGridCell>
                EMŠO: {!editing && form.step3?.emso}
                {editing && <input type={"text"}
                                   style={{margin: 'auto 0 auto 8px'}}
                                   value={participant.form?.step3?.emso ?? ''}
                                   onChange={(e) => {
                                       participant.form!.step3!.emso = e.target.value;
                                   }}/>}
            </ParticipantGridCell>}
            {formConfig.s3?.stVozniske && <ParticipantGridCell>
                ŠTEVILKA VOZNIŠKEGA DOVOLJENJA: {!editing && form.step3?.stVozniske}
                {editing && <input type={"text"}
                                   style={{margin: 'auto 0 auto 8px'}}
                                   value={participant.form?.step3?.stVozniske ?? ''}
                                   onChange={(e) => {
                                       participant.form!.step3!.stVozniske = e.target.value;
                                   }}/>}
            </ParticipantGridCell>}
            {formConfig.s3?.krajRojstva && <ParticipantGridCell>
                KRAJ ROJSTVA: {!editing && form.step3?.krajRojstva}
                {editing && <input type={"text"}
                                   style={{margin: 'auto 0 auto 8px'}}
                                   value={participant.form?.step3?.krajRojstva ?? ''}
                                   onChange={(e) => {
                                       participant.form!.step3!.krajRojstva = e.target.value;
                                   }}/>}
            </ParticipantGridCell>}
            {formConfig.s3?.veljavnoVoznisko && <ParticipantGridCell>
                {editing && <input type={"checkbox"}
                                   style={{marginRight: '8px'}}
                                   checked={participant.form?.step3?.veljavnoVoznisko!}
                                   onChange={() => participant.form!.step3!.veljavnoVoznisko = !participant.form?.step3?.veljavnoVoznisko}/>}
                VELJAVNO
                VOZNIŠKO{!editing ? ': ' + (participant.form!.step3!.veljavnoVoznisko ? 'DA' : 'NE') : undefined}
            </ParticipantGridCell>}
            {formConfig.s3?.veljavnoVozniskoKazenskeTocke && <>
                <ParticipantGridCell>
                    {editing && <input type={"checkbox"}
                                       style={{marginRight: '8px'}}
                                       checked={participant.form?.step3?.veljavnoVoznisko!}
                                       onChange={() => {
                                           participant.form!.step3!.veljavnoVoznisko = !participant.form?.step3?.veljavnoVoznisko;
                                           if (!participant.form!.step3!.veljavnoVoznisko && form.step3?.napotilo === mladi_voznik.KazenskeTockeNapotilo.prostovoljno) {
                                               form.step3!.napotilo = mladi_voznik.KazenskeTockeNapotilo.sodisce;
                                           }
                                       }}/>}
                    VELJAVNO
                    VOZNIŠKO{!editing ? ': ' + (participant.form!.step3!.veljavnoVoznisko ? 'DA' : 'NE') : undefined}
                </ParticipantGridCell>
                <ParticipantGridCell>
                    NAPOTILO: {!editing && CvvStrings.napotilo[form!.step3!.napotilo as number]}
                    {editing && <select style={{margin: 'auto 0 auto 8px'}} value={form!.step3!.napotilo!.toString()}
                                        onChange={(e) => form!.step3!.napotilo = parseInt(e.target.value)}>
                        <option value={mladi_voznik.KazenskeTockeNapotilo.sodisce}>
                            {CvvStrings.napotilo[mladi_voznik.KazenskeTockeNapotilo.sodisce]}
                        </option>
                        <option value={mladi_voznik.KazenskeTockeNapotilo.zdravstveni_pregled}>
                            {CvvStrings.napotilo[mladi_voznik.KazenskeTockeNapotilo.zdravstveni_pregled]}
                        </option>
                        {form.step3?.veljavnoVoznisko &&
                            <option value={mladi_voznik.KazenskeTockeNapotilo.prostovoljno}>
                                {CvvStrings.napotilo[mladi_voznik.KazenskeTockeNapotilo.prostovoljno]}
                            </option>
                        }
                    </select>}
                </ParticipantGridCell>

                {form.step3?.napotilo === mladi_voznik.KazenskeTockeNapotilo.sodisce && <>
                    <ParticipantGridCell>
                        ŠTEVILKA NAPOTILA: {!editing && form.step3?.napotiloStevilka}
                        {editing && <input type={"text"}
                                           style={{margin: 'auto 0 auto 8px'}}
                                           value={participant.form?.step3?.napotiloStevilka ?? ''}
                                           onChange={(e) => {
                                               participant.form!.step3!.napotiloStevilka = e.target.value;
                                           }}/>}
                    </ParticipantGridCell>
                    <ParticipantGridCell>
                        PRISTOJNO SODIŠČE: {!editing && form.step3?.napotiloPristojnoSodisce}
                        {editing && <input type={"text"}
                                           style={{margin: 'auto 0 auto 8px'}}
                                           value={participant.form?.step3?.napotiloPristojnoSodisce ?? ''}
                                           onChange={(e) => {
                                               participant.form!.step3!.napotiloPristojnoSodisce = e.target.value;
                                           }}/>}
                    </ParticipantGridCell>

                </>}
                {(form.step3?.napotilo === mladi_voznik.KazenskeTockeNapotilo.sodisce || form.step3?.napotilo === mladi_voznik.KazenskeTockeNapotilo.zdravstveni_pregled) &&
                    <ParticipantGridCell style={{overflow: 'visible'}}>
                        DATUM
                        NAPOTILA: {!editing && (form?.step3?.napotiloDatum?.ts ? DateFormatters.date(new Date(form?.step3?.napotiloDatum?.ts as number)) : '/')}
                        {editing && <AdminDatepickerStyles style={{margin: 'auto 0 auto 8px', lineHeight: '20px'}}>
                            <DatePicker
                                value={form?.step3?.napotiloDatum?.ts ? new Date(form?.step3?.napotiloDatum?.ts as number) : undefined}
                                format={'dd. MM. y'}
                                onChange={(date?: Date) => {
                                    if (!form!.step3!.napotiloDatum) {
                                        form!.step3!.napotiloDatum = cvv_types.Date.create();
                                        makeAutoObservable(form!.step3!.napotiloDatum);
                                    }
                                    if (!date) {
                                        form!.step3!.napotiloDatum!.ts = undefined;
                                        return;
                                    }
                                    form!.step3!.napotiloDatum!.ts = date.getTime();
                                }}/>
                        </AdminDatepickerStyles>}
                    </ParticipantGridCell>}
            </>}
            {formConfig.s3?.datumIzdajeVozniske && <ParticipantGridCell style={{overflow: 'visible'}}>
                DATUM IZDAJE
                VOZNIŠKE: {!editing && (participant.form?.step3?.datumIzdajeVozniske?.ts ? DateFormatters.date(new Date(participant.form?.step3?.datumIzdajeVozniske?.ts as number)) : '/')}
                {editing && <AdminDatepickerStyles style={{margin: 'auto 0 auto 8px', lineHeight: '20px'}}>
                    <DatePicker
                        value={participant.form?.step3?.datumIzdajeVozniske?.ts ? new Date(participant.form?.step3?.datumIzdajeVozniske?.ts as number) : undefined}
                        format={'dd. MM. y'}
                        onChange={(date?: Date) => {
                            if (!participant.form!.step3!.datumIzdajeVozniske) {
                                participant.form!.step3!.datumIzdajeVozniske = cvv_types.Date.create();
                                makeAutoObservable(participant.form!.step3!.datumIzdajeVozniske);
                            }
                            if (!date) {
                                participant.form!.step3!.datumIzdajeVozniske!.ts = undefined;
                                return;
                            }
                            participant.form!.step3!.datumIzdajeVozniske!.ts = date.getTime();
                        }}/>
                </AdminDatepickerStyles>}
            </ParticipantGridCell>}
            {formConfig.s3?.datumOpravljenegaIntenzivnegaTecaja && <ParticipantGridCell style={{overflow: 'visible'}}>
                DATUM OP. INT.
                TEČAJA: {!editing && (participant.form?.step3?.datumOpravljenegaIntenzivnegaTecaja?.ts ? DateFormatters.date(new Date(participant.form?.step3?.datumOpravljenegaIntenzivnegaTecaja?.ts as number)) : '/')}
                {editing && <AdminDatepickerStyles style={{margin: 'auto 0 auto 8px', lineHeight: '20px'}}>
                    <DatePicker
                        value={participant.form?.step3?.datumOpravljenegaIntenzivnegaTecaja?.ts ? new Date(participant.form?.step3?.datumOpravljenegaIntenzivnegaTecaja?.ts as number) : undefined}
                        format={'dd. MM. y'}
                        onChange={(date?: Date) => {
                            if (!participant.form!.step3!.datumOpravljenegaIntenzivnegaTecaja) {
                                participant.form!.step3!.datumOpravljenegaIntenzivnegaTecaja = cvv_types.Date.create();
                                makeAutoObservable(participant.form!.step3!.datumOpravljenegaIntenzivnegaTecaja);
                            }
                            if (!date) {
                                participant.form!.step3!.datumOpravljenegaIntenzivnegaTecaja!.ts = undefined;
                                return;
                            }
                            participant.form!.step3!.datumOpravljenegaIntenzivnegaTecaja!.ts = date.getTime();
                        }}/>
                </AdminDatepickerStyles>}
            </ParticipantGridCell>}
        </ParticipantGrid>
        {formConfig.s1?.children &&
            participant.form?.stepChildren?.children && participant.form?.stepChildren?.children.map((c, i) => {
                return <ParticipantGrid columns={'auto auto auto'}>
                    <ParticipantGridHeaderCell span={3} key={i}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                        }}>
                            <div>PODATKI O OTROCIH - OTROK {i + 1}</div>
                            {editing && <span
                                style={{
                                    cursor: 'pointer',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                onClick={() => {
                                    appStore.showDialog(
                                        <YesNoDialog result={(result) => {
                                            appStore.hideDialog();
                                            if (result) {
                                                participant.form!.stepChildren!.children!.splice(i, 1);
                                                participant.form!.step1!.children! -= 1;
                                            }
                                        }}>
                                            <CvvTheme.textTheme.headline5.span>
                                                <center>Ali ste prepričani, da želite izbrisati podatke o
                                                    otroku {i + 1}?
                                                </center>
                                            </CvvTheme.textTheme.headline5.span>
                                        </YesNoDialog>
                                    )
                                }}>
                                <MdDelete style={{fontWeight: 'bold', fontSize: '15px'}}/>
                            </span>}
                        </div>
                    </ParticipantGridHeaderCell>
                    {
                        formConfig.stepChildren?.nameSurname && <ParticipantGridCell key={`child-${i}-namesurname`}>
                            IME IN PRIIMEK: {!editing && (c.nameSurname ?? '')}
                            {editing && <input type={'text'}
                                               style={{margin: 'auto 0 auto 8px'}}
                                               value={c.nameSurname ?? ''}
                                               onChange={(e) => {
                                                    c.nameSurname = e.target.value;
                                               }}/>}
                        </ParticipantGridCell>
                    }
                    {
                        formConfig.stepChildren?.birthDate && <ParticipantGridCell style={{overflow: 'visible'}} key={`child-${i}-birthdate`}>
                            DATUM
                            ROJSTVA: {!editing && (c.birthDate && c.birthDate.ts ? DateFormatters.date(new Date(c.birthDate.ts as number)) : '/')}
                            {editing && <AdminDatepickerStyles style={{margin: 'auto 0 auto 8px', lineHeight: '20px'}}>
                                <DatePicker
                                    value={c.birthDate && c.birthDate.ts ? new Date(c.birthDate.ts as number) : undefined}
                                    format={'dd. MM. y'}
                                    onChange={(date?: Date) => {
                                        if (!c.birthDate) {
                                            c.birthDate = cvv_types.Date.create();
                                            makeAutoObservable(c.birthDate);
                                        }
                                        if (!date) {
                                            c.birthDate.ts = undefined;
                                            return;
                                        }
                                        c.birthDate.ts = date.getTime();
                                    }}/>
                            </AdminDatepickerStyles>}
                        </ParticipantGridCell>
                    }
                    {
                        formConfig.stepChildren?.height && <ParticipantGridCell key={`child-${i}-height`}>
                            VIŠINA: {!editing && c.height}
                            {editing && <input type={"number"}
                                               style={{margin: 'auto 8px'}}
                                               value={c.height ?? ''}
                                               onChange={(e) => {
                                                   if(!e.target.value) {
                                                       c.height = undefined;
                                                       return;
                                                   }
                                                   c.height = Number(e.target.value);
                                               }}/>} cm
                        </ParticipantGridCell>
                    }
                    {
                        formConfig.stepChildren?.weight && <ParticipantGridCell key={`child-${i}-weight`}>
                            TEŽA: {!editing && c.weight}
                            {editing && <input type={"number"}
                                               style={{margin: 'auto 8px'}}
                                               value={c.weight ?? ''}
                                               onChange={(e) => {
                                                   if(!e.target.value) {
                                                       c.weight = undefined;
                                                       return;
                                                   }
                                                   c.weight = Number(e.target.value);
                                               }}/>} kg
                        </ParticipantGridCell>
                    }
                </ParticipantGrid>
            })
        }

        <ParticipantGrid columns={'auto auto auto auto'}>
            <ParticipantGridHeaderCell span={4}>
                {saldo !== 0 && <MdWarning style={{marginRight: '4px', color: theme.error}}/>}
                PODATKI O PLAČILU
                <div style={{marginLeft: 'auto'}}/>
                {saldo < 0 && <LoadingOverlay style={{marginLeft: '8px'}} scale={0} builder={(action) => <AdminButton
                    style={{color: theme.content, borderColor: theme.primaryDark}}
                    background={theme.primary}
                    backgroundHover={'#ffe871'}
                    onClick={async () => {
                        const result = await action(store.api.sendMessage(proto.TxAdminPaymentAtCvv.create({sessionId: summary.sessionId}), {ack: true}));
                        if (result.status !== SocketApiAckStatus.success) {
                            toast.error(result.errorMessage);
                        } else {
                            store.participants.setParticipant(summary.sessionId!);
                            store.participants.load();
                        }
                    }}>
                    Vračilo preplačanega zneska
                </AdminButton>}/>}
                {saldo > 0 && <AdminButton
                    style={{color: theme.content, borderColor: theme.primaryDark}}
                    background={theme.primary}
                    backgroundHover={'#ffe871'}
                    onClick={() => {
                        appStore.dialog = <UnovciBonDialog sessionId={summary.sessionId!}/>;
                    }}>
                    Unovči darilni bon
                </AdminButton>}
                {saldo > 0 && <LoadingOverlay style={{marginLeft: '8px'}} scale={0} builder={(action) => <AdminButton
                    style={{color: theme.content, borderColor: theme.primaryDark}}
                    background={theme.primary}
                    backgroundHover={'#ffe871'}
                    onClick={async () => {
                        const result = await action(store.api.sendMessage(proto.TxAdminPaymentAtCvv.create({sessionId: summary.sessionId}), {ack: true}));
                        if (result.status !== SocketApiAckStatus.success) {
                            toast.error(result.errorMessage);
                        } else {
                            store.participants.setParticipant(summary.sessionId!);
                            store.participants.load();
                        }
                    }}>
                    Plačilo na CVV
                </AdminButton>}/>}
            </ParticipantGridHeaderCell>
            <ParticipantGridCell>SKLIC: {summary.sklic}</ParticipantGridCell>
            <ParticipantGridCell>IZBRANI TIP PLAČILA: {CvvPaymentType[summary.paymentType!]}</ParticipantGridCell>
            <ParticipantGridCell span={2}>PLAČANO {(participant.amountPaid ?? 0) / 100}€
                OD {(participant.amountTotal ?? 0) / 100}€,
                SALDO:&nbsp;
                <b>{saldo}€</b></ParticipantGridCell>
            {participant.payments?.map((p: admin_participants.IPaymentEntry, i: number) => {
                let span = 3;
                const children = [
                    <ParticipantGridCell
                        dense={true} style={{fontWeight: 'bold'}}
                        key={'payment-a-' + i}>{DateFormatters.dateTime(new Date(p.date?.ts as number))}</ParticipantGridCell>
                ];
                span--;
                let content;
                if (p.type === billing.PaymentType.darilni_bon) {
                    content = `DARILNI BON: ${p.darilniBon}`
                } else if (p.type === billing.PaymentType.gotovina) {
                    content = p.amount! > 0 ? 'PLAČILO NA CVV' : 'VRAČILO';
                } else if (p.type === billing.PaymentType.kreditna) {
                    content = `KREDITNA KARTICA (${p.bankartPaymentId})`
                }
                children.push(<ParticipantGridCell span={2} dense={true} style={{fontWeight: 'bold'}}
                                                   key={'payment-b-' + i}>
                    {content}
                    {p.comment && ` (${p.comment})`}
                </ParticipantGridCell>);
                span--;
                children.push(<ParticipantGridCell style={{fontWeight: 'bold'}} dense={true} span={span}
                                                   key={'payment-c-' + i}>{p.amount! / 100}€</ParticipantGridCell>);
                return children;
            })}
            <ParticipantGridCell span={1} style={{backgroundColor: CvvColors.shade, fontWeight: 'bold'}}>
                DOBAVNICA: {!!summary.navInvoiceProforma ? summary.navInvoiceProforma : '/'}
            </ParticipantGridCell>
            <ParticipantGridCell span={1} style={{backgroundColor: CvvColors.shade, fontWeight: 'bold'}}>
                AVANSNI RAČUN: {!!summary.navInvoiceAdvance ? summary.navInvoiceAdvance : '/'}
            </ParticipantGridCell>
            <ParticipantGridCell span={2} style={{backgroundColor: CvvColors.shade, fontWeight: 'bold'}}>
                RAČUN: {!!summary.navInvoice ? summary.navInvoice : (summary.daysPresent?.length === summary.nDays && !!summary.navInvoiceAdvance) ?
                <LoadingOverlay style={{margin: '4px 0 4px 4px'}} scale={0} builder={(action) => <AdminButton
                    style={{color: theme.content, borderColor: theme.primaryDark}}
                    background={theme.primary}
                    backgroundHover={'#ffe871'}
                    onClick={() => {
                        appStore.showDialog(
                            <YesNoDialog result={async (result) => {
                                appStore.hideDialog();
                                if (result) {
                                    await action(store.participants.createInvoice());
                                }
                            }}>
                                <CvvTheme.textTheme.headline5.span>
                                    Ali ste prepričani, da želite ustvariti račun?
                                </CvvTheme.textTheme.headline5.span>
                            </YesNoDialog>
                        );
                    }}>
                    USTVARI
                </AdminButton>
                }/> : '/'}
            </ParticipantGridCell>
        </ParticipantGrid>
        <LoadingOverlay style={{width: '100%'}}
                        builder={(action) => <ParticipantGrid columns={'50px 170px 170px 170px auto'}>
                            <ParticipantGridHeaderCell span={2}>
                                OPOMBE
                            </ParticipantGridHeaderCell>
                            <ParticipantGridHeaderCell span={3}>
                                <select value={store.participants.newComment.type?.id ?? ''}
                                        onChange={(e) => store.participants.newComment.type!.id = e.target.value}>
                                    {store.participants?.commentTypes?.filter((t) => t.userCanAdd).map((t) => <option
                                        key={t.id!}
                                        value={t.id!}>{t.label}</option>)}
                                </select>
                                <input type={'text'}
                                       style={{width: '100%', margin: '4px 4px 4px 4px'}}
                                       value={store.participants.newComment.text ?? ''}
                                       onChange={(e) => {
                                           store.participants.newComment.text = e.target.value;
                                       }}/>
                                <AdminButton
                                    disabled={!store.participants.newComment.text}
                                    onClick={() => action(store.participants.saveNewComment())}>SHRANI</AdminButton>
                            </ParticipantGridHeaderCell>
                            {store.participants.comments?.map((c, i) => <Comment key={i} i={i} comment={c}/>)}
                        </ParticipantGrid>}/>
    </>
});

const CommentContainer = styled.div<{ editable?: boolean }>`
    display: contents;

    & > * {
        background-color: ${({theme, editable}: StyledProps) => editable ? undefined : theme.content5};
        color: ${({theme, editable}: StyledProps) => editable ? undefined : theme.content50};
    }
`;

const Comment = ({i, comment}: { i: number, comment: cvv_types.IComment }) => {
    const app = useContext(AppStoreContext);
    const theme = useTheme() as Theme;
    const store = useContext(AdminStoreContext) as AdminStore;
    return <CommentContainer editable={!!comment.type?.userCanAdd}>
        <ParticipantGridCell>
            {i + 1}.
        </ParticipantGridCell>
        <ParticipantGridCell>
            {DateFormatters.date(new Date(comment.date!.ts as number))} - {DateFormatters.time(new Date(comment.date!.ts as number))}
        </ParticipantGridCell>
        <ParticipantGridCell>
            {comment.type?.label}
        </ParticipantGridCell>
        <ParticipantGridCell>
            {comment.user}
        </ParticipantGridCell>
        <ParticipantGridCell>
            {comment.text}
            {comment.type?.userCanDelete && <span
                style={{
                    margin: '0 8px 0 auto',
                    cursor: 'pointer',
                    height: '100%',
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center"
                }}
                onClick={() => app.showDialog(
                    <YesNoDialog result={(result) => {
                        app.hideDialog();
                        if (result) {
                            store.participants.deleteComment(comment);
                        }
                    }}>
                        <theme.textTheme.headline5.span style={{textAlign: 'center'}}>Ali ste prepričani, da želite
                            izbrisati opombo?
                        </theme.textTheme.headline5.span>
                    </YesNoDialog>
                )}>
            <MdDelete style={{fontSize: '15px'}}/>
            </span>}
        </ParticipantGridCell>
    </CommentContainer>
}

const AdminAddressPicker = observer(({address}: { address: cvv_types.IAddress }) => {
    const [addressString, setAddressString] = useState(address.address ?? '');
    const {ref} = usePlacesWidget<any>({
        apiKey: GMAPS_API_KEY,
        onPlaceSelected: (place) => {
            if (!place.address_components || place.address_components[0].types[0] !== 'street_number') {
                toast.error('izberite naslov s hišno številko');
                setTimeout(() => setAddressString(address.address ?? ''));
            } else {
                if (place.formatted_address) {
                    setAddressString(place.formatted_address);
                }
                MladiVoznikStore.placeResultToAddress(address, place);
            }
        },
        options: {
            componentRestrictions: {
                country: ['si'],
            },
            types: ['address'],
        }
    });

    return <div
        style={{display: 'flex', flexDirection: 'column', flexGrow: '1', justifyContent: 'center', marginLeft: '8px'}}>
        <input
            ref={ref}
            type={'text'}
            autoComplete={'address-line1'}
            value={addressString}
            onChange={(e) => {
                if (!e.target.value) {
                    address.address = '';
                    address.lat = undefined;
                    address.lng = undefined;
                    address.city = undefined;
                    address.country = undefined;
                    address.line1 = undefined;
                    address.postalCode = undefined;
                    address.state = undefined;
                }
                setAddressString(e.target.value);
            }}/>
    </div>;
});