import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../../theme";
import {ReactComponent as NextSvg} from "../../assets/next.svg";
import {FaCheck} from 'react-icons/fa'

const Circle = styled.div`
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: -20px;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 100%;
  background-color: ${({theme}: StyledProps) => theme.primary};
  justify-content: center;
  align-items: center;
`;

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  position: relative;
  border: solid 1px ${({theme}: StyledProps) => theme.content};
  border-left: solid ${({selected}) => selected ? '10px' : '1px'} ${({theme}: StyledProps) => theme.content};
  height: 56px;
  padding: 0 15px 0;
  margin-right: calc(35px / 2);
  transition: background-color 200ms ease, border-left-width 200ms ease;
  cursor: pointer;
  align-items: center;
  user-select: none;

  &:hover {
    background-color: ${({theme}: StyledProps) => theme.content10};
  }
`;

const PickContainer = styled.div`
  border-left: solid 1px ${({theme}: StyledProps) => theme.content};
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 80%;
  padding: 0 16px 0 16px;
  ${({theme}: StyledProps) => theme.textTheme.body.css};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface CvvLessonSlotProps {
    title: any
    subtitle?: any
    selected: boolean
    freePlaces?: number | null
}

export const CvvLessonSlot = (props: CvvLessonSlotProps) => {
    const theme = useTheme() as Theme;
    return <Container selected={props.selected}>
        <ContentContainer>
            <theme.textTheme.headline5.span>
                {props.title}
                {props.freePlaces && <theme.textTheme.body.span style={{display: 'block'}}>
                    {props.freePlaces} prostih mest
                </theme.textTheme.body.span>}
            </theme.textTheme.headline5.span>
            <theme.textTheme.body.span>
                {props.subtitle}
            </theme.textTheme.body.span>
        </ContentContainer>
        <PickContainer>
            {props.selected ? 'IZBRANO' : 'IZBERI'}
        </PickContainer>
        <Circle>
            {props.selected ? <FaCheck/> : <NextSvg style={{color: theme.content, width: '15px'}}/>}
        </Circle>
    </Container>;
}