import {observer} from "mobx-react-lite";
import {CvvCategoryHeader, CvvCategorySubHeader} from "../components/headers";
import {FormStepContainer} from "../components/decorations";
import {useTheme} from "styled-components";
import {Theme} from "../../theme";
import {MladiVoznikStoreContext} from "./form_mladi_voznik";
import {MladiVoznikStore} from "../../stores/mladi_voznik_store";
import {ReactComponent as NextSvg} from "../../assets/next.svg";
import {CvvButton} from "../../components/buttons";
import {RefObject, useContext, useRef} from "react";
import {CvvInputField} from "../components/input";
import {CvvTextInput} from "../components/text_input";
import {DatepickerStyles} from "../../components/datepicker_styles";
import DatePicker from "react-date-picker";


export const FormMladiVoznikStepChildren = observer((props: any) => {
    const store = useContext(MladiVoznikStoreContext) as MladiVoznikStore;
    const theme = useTheme() as Theme;
    const dateRef = useRef<HTMLInputElement>();


    return <FormStepContainer>
        <CvvCategoryHeader style={{marginTop: '52px'}}>
            PODATKI O OTROCIH
        </CvvCategoryHeader>
        {store.stepChildren.childrenData && store.stepChildren.childrenData.map((child, i) => (
            <div>
                <CvvCategorySubHeader style={{marginTop: '32px'}}>
                    OTROK {i + 1}</CvvCategorySubHeader>
                <CvvInputField key={'name'} error={store.validators.stepChildren.nameSurname(store.ignoreEmptyValidations, i)} label={'IME IN PRIIMEK'} input={
                    <CvvTextInput
                        autoComplete={'name'}
                        invalid={!!store.validators.stepChildren.nameSurname(store.ignoreEmptyValidations, i)}
                        value={child.nameSurname ?? ''}
                        onChange={(e) => store.stepChildren.setNameSurname(i, e.target.value)}/>
                }/>
                <CvvInputField key={'date'} error={store.validators.stepChildren.birthDate(store.ignoreEmptyValidations, i)} label={'DATUM ROJSTVA'} input={
                    <DatepickerStyles
                        invalid={!!store.validators.stepChildren.birthDate(store.ignoreEmptyValidations, i)}>
                        <DatePicker
                            inputRef={dateRef as RefObject<HTMLInputElement>}
                            value={store.stepChildren.birthDates![i]}
                            format={'dd. MM. y'}
                            onChange={(date?: Date) => store.stepChildren.setBirthDate(i, date)}
                        />
                    </DatepickerStyles>
                }/>
                <CvvInputField key={'height'} error={store.validators.stepChildren.height(store.ignoreEmptyValidations, i)} label={'VIŠINA (cm)'} input={
                    <CvvTextInput
                        min={0} maxLength={6} max={300}
                        type={'number'}
                        invalid={!!store.validators.stepChildren.height(store.ignoreEmptyValidations, i)}
                        value={!!store.stepChildren.heights[i] ? store.stepChildren.heights[i] : ''}
                        onChange={(e) => store.stepChildren.setHeight(i, e.target.value)}
                    />
                }/>
                <CvvInputField key={'weight'} error={store.validators.stepChildren.weight(store.ignoreEmptyValidations, i)} label={'TEŽA (kg)'} input={
                    <CvvTextInput
                        min={0} maxLength={6} max={1000}
                        type={'number'}
                        invalid={!!store.validators.stepChildren.weight(store.ignoreEmptyValidations, i)}
                        value={!!store.stepChildren.weights[i] ? store.stepChildren.weights[i] : ''}
                        onChange={(e) => store.stepChildren.setWeight(i, e.target.value)}
                    />
                }/>
            </div>
        ))}
        <CvvButton
            onClick={() => store.setStep(store.step + 1)}
            style={{marginTop: '64px'}}
            disabled={!store.stepValid(store.step, false)}>
            <theme.textTheme.headline5.span>NADALJUJ</theme.textTheme.headline5.span>
            <NextSvg height={'22px'} style={{marginLeft: 'auto'}}/>
        </CvvButton>
    </FormStepContainer>
})