import React, {useContext} from "react";
import styled, {useTheme} from "styled-components";
import {observer} from "mobx-react-lite";
import {TailSpin} from "react-loader-spinner";

import {FormStoreContext} from "../index";
import {Theme} from "../theme";
import {
    FormContainer,
    FormContentContainer,
    FormStepContainer,
    HeaderContainer,
    HeaderSubtitle,
    HeaderTitle
} from "./components/decorations";
import {MladiVoznikSummary} from "./mladi_voznik/form_mladi_voznik_components";
import {CvvCategoryHeader} from "./components/headers";
import {
    SummaryCategory,
    SummaryContainer,
    SummaryValue,
    SummaryLabel,
    SummaryList,
    SummaryListItem
} from "./mladi_voznik/form_mladi_voznik_s6";
import {sum} from "lodash";
import {DateFormatters, NumberFormatters} from "../utils";
import {CvvPaymentType} from "../strings";
import {CvvButton} from "../components/buttons";
import {LoadingOverlay} from "../components/overlays";
import {ToastContainer} from "react-toastify";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 800px;

  @media (max-width: 900px) {
    padding: 0 16px 0 16px;
  }
`;

export const FormSummary = observer(() => {
    const formStore = useContext(FormStoreContext);
    const theme = useTheme() as Theme;
    const summary = formStore.formSummary;
    const [participationLoading, setParticipationLoading] = React.useState(false);

    let content;

    if (!summary) {
        content = (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <TailSpin
                    color={theme.content}
                    height='100px'
                    width='100px'
                />
            </div>
        );
    } else {
        content = (
            <FormContainer style={{margin: '0', alignItems: 'stretch'}}>
                <HeaderContainer>
                    <HeaderSubtitle>{summary.tecajTip} - {summary.tecaj}</HeaderSubtitle>
                </HeaderContainer>
                <FormStepContainer style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0}}>
                    <CvvCategoryHeader>Podatki o prijavi</CvvCategoryHeader>
                    <SummaryCategory style={{margin: '-16px 0 16px 0'}}>
                        <SummaryContainer>
                            <SummaryLabel>Ime in priimek: </SummaryLabel>
                            <SummaryValue>{summary.nameSurname}</SummaryValue>
                        </SummaryContainer>
                        <SummaryContainer>
                            <SummaryLabel>Šifra seje: </SummaryLabel>
                            <SummaryValue>{summary.sessionId}</SummaryValue>
                        </SummaryContainer>
                        <SummaryContainer>
                            <SummaryLabel>Ure: </SummaryLabel>
                            <SummaryList numbered={summary.lessons.length > 1}>
                                {summary.lessons.map((l: any, i: any) => (
                                    <SummaryListItem
                                        key={'summary-lesson-' + i}>{l.locationName} ({DateFormatters.dateTime(new Date(l.start!.ts as number))} - {DateFormatters.dateTime(new Date(l.end!.ts as number))})</SummaryListItem>
                                ))}

                            </SummaryList>
                        </SummaryContainer>
                        <SummaryContainer>
                            <SummaryLabel>Tip tečaja: </SummaryLabel>
                            <SummaryValue>{summary.tecajTip}</SummaryValue>
                        </SummaryContainer>
                        <SummaryContainer>
                            <SummaryLabel>Tečaj: </SummaryLabel>
                            <SummaryValue>{summary.tecaj}</SummaryValue>
                        </SummaryContainer>
                        <SummaryContainer>
                            <SummaryLabel>Plačano: </SummaryLabel>
                            <SummaryValue>{NumberFormatters.eur((summary.pricelist?.paid ?? 0) / 100)} / {NumberFormatters.eur((summary.pricelist?.total ?? 0) / 100)}</SummaryValue>
                        </SummaryContainer>
                        <SummaryContainer>
                            <SummaryLabel>Način plačila: </SummaryLabel>
                            <SummaryValue>{CvvPaymentType[summary.paymentType]}</SummaryValue>
                        </SummaryContainer>
                    </SummaryCategory>
                    {Date.now() < ((formStore.formSummary?.lessons[0]?.start?.ts ?? 0) as number) && <SummaryCategory style={{alignItems: 'center', marginTop: '32px'}}>
                        <LoadingOverlay builder={(action) => <CvvButton
                            onClick={() => action(formStore.toggleConfirmed())}>
                            <theme.textTheme.headline5.span style={{width: '250px'}}>
                                {formStore.formSummary?.participationConfirmed ? 'Prekliči udeležbo' : 'Potrdi udeležbo'}
                            </theme.textTheme.headline5.span>
                        </CvvButton>}/>
                    </SummaryCategory>}
                </FormStepContainer>
            </FormContainer>);
    }

    return <Container>
        {content}
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </Container>;
});
