import styled from "styled-components";
import {StyledProps} from "../../theme";

export const CvvCategoryHeader = styled.div`
  background-color: ${({theme}: StyledProps) => theme.shade};
  display: inline-flex;
  align-items: center;
  line-height: 45px;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 10px 0 10px;
  text-align: left;
  
  ${({theme}: StyledProps) => theme.textTheme.headline6.css}
`;

export const CvvCategorySubHeader = styled.div`
  background-color: ${({theme}: StyledProps) => theme.backgroundLight};
  display: inline-flex;
  align-items: center;
  line-height: 35px;
  margin-bottom: 15px;
  width: 100%;
  padding: 0 8px;
  text-align: left;
  
  ${({theme}: StyledProps) => theme.textTheme.headline6.css}
`;