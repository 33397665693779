import styled from "styled-components";

export const AdminHeader = styled.div`
  display: flex;
  background-color: #0099CB;
  height: 35px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 15px;
  color: white;
  padding: 0 16px 0 16px;

  & > * {
    box-sizing: border-box;
  }
`;

export const AdminFooter = styled.div`
  background-color: #EEEEEE;
  border: solid 1px #CCCCCC;
  width: 100%;
  min-height: 35px;
  display: flex;
  align-items: center;
  padding: 0 16px 0 16px;
  box-sizing: border-box;
  margin-top: 16px;
`

export const AdminInputContainer = styled.div`
  display: flex;
  height: 30px;
  font-size: 13px;
  border-bottom: solid 1px #CCCCCC;

  & > * {
    display: flex;
    align-items: center;
    padding: 0 16px 0 16px;
  }
`;

export const AdminInputLabel = styled.div`
  width: 200px;
  height: 30px;
  border-left: solid 1px #CCCCCC;
  border-right: solid 1px #CCCCCC;
  font-weight: bold;
  background-color: #EEEEEE;
`;

export const AdminInput = styled.div`
  height: 30px;
  flex-grow: 1;
  border-right: solid 1px #CCCCCC;
  & > span {
    margin: 0 8px 0 8px;
  }

`;


