import {AdminStore} from "./admin_store";
import {CalendarController} from "../../forms/components/calendar";
import {makeAutoObservable, ObservableMap} from "mobx";
import {proto} from "../../proto/messages";
import {admin_tecaji, cvv_types} from "../../proto/compiled";
import {DateFormatters} from "../../utils";

export class AdminCalendarStore {
    private disposeCallbacks: (() => any)[] = [];

    base: AdminStore;
    controllers: CalendarController[] = [];
    days = new ObservableMap<string, admin_tecaji.AdminCalendarDayData>();
    selectedDate?: Date;
    private clear = false;

    constructor(base: AdminStore) {
        this.base = base;

        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        this.controllers.push(new CalendarController(date.getFullYear(), date.getMonth()));
        date.setMonth(date.getMonth() + 1);
        this.controllers.push(new CalendarController(date.getFullYear(), date.getMonth()));
        date.setMonth(date.getMonth() + 1);
        this.controllers.push(new CalendarController(date.getFullYear(), date.getMonth()));
        makeAutoObservable(this);

        this.onSub(
            this.base.api.getMessageHandler(new proto.RxAdminCalendarData())
                .subscribe((m) => this.onCalendarData(m))
        );
        this.onSub(
            this.controllers[1].onDateChange.subscribe(() => setTimeout(() => this.load(), 100))
        );
    }

    load(): void {
        this.clear = true;
        this.base.loading++;
        this.base.api.sendMessage(proto.TxAdminLoadCalendar.create({
            from: new cvv_types.Date({ts: this.controllers[0].rangeFrom.getTime()}),
            to: new cvv_types.Date({ts: this.controllers[this.controllers.length - 1].rangeTo.getTime()}),
            tecajKey: this.base.tecaj,
            tecajTipKey: this.base.tecajTip,
        })).then((response) => {
            this.base.loading--;
        });
    }

    dispose() {
        for (const dc of this.disposeCallbacks) {
            dc();
        }
    }

    private onSub(subscription: any) {
        this.disposeCallbacks.push(() => subscription.unsubscribe());
    }

    private onCalendarData(m: proto.RxAdminCalendarData) {
        if (this.clear) {
            this.clear = false;
            this.days.clear();
        }
        this.days.merge(m.proto.days as any);
    }

    selectDate(date: Date) {
        if (this.selectedDate?.toDateString() === date?.toDateString()) {
            this.selectedDate = undefined;
        } else {
            this.selectedDate = date;
        }
    }

    get tipi(): string[] {
        const t = new Set<string>();
        this.getCurrentTecaji().map((e) => t.add(e.tipName!));
        return Array.from(t);
    };

    getCurrentTecaji(tip?: string): admin_tecaji.IAdminTecajDaySummary[] {
        let tecaji;
        if (this.selectedDate) {
            const key = CalendarController.kFromDate(this.selectedDate);
            tecaji = this.days.get(key)?.tecaji ?? [];
        } else {
            const key = CalendarController.kFromDate(this.controllers[1].rangeFrom);
            const monthStart = key.substring(0, key.length - 3);
            tecaji = [];
            for (const k of Array.from(this.days.keys() ?? [])) {
                if (!k.startsWith(monthStart)) continue;
                tecaji.push(...this.days!.get(k)!.tecaji!.filter((t) => t.dayN === 0));
            }
        }
        if (tip) {
            return tecaji.filter((t) => t.tipName === tip);
        }
        return tecaji;
    }

    getTecaji(date?: Date): admin_tecaji.IAdminTecajDaySummary[] {
        if (!date) return [];
        const key = (date && CalendarController.kFromDate(date)) ?? '';
        return this.days.get(key)?.tecaji ?? [];
    }

    setYear(year: number) {
        const dY = year - this.controllers[1].year;
        this.controllers[0].setDYear(dY);
        this.controllers[1].setDYear(dY);
        this.controllers[2].setDYear(dY);
    }

    setMonth(month: number) {
        const year = this.controllers[1].year;
        this.controllers[0].setDate(month - 1, year);
        this.controllers[1].setDate(month, year);
        this.controllers[2].setDate(month + 1, year);
    }

    rangeText(): string {
        if (this.selectedDate) {
            return 'NA DAN ' + DateFormatters.date(this.selectedDate);
        } else {
            return 'ZA MESEC ' + DateFormatters.months[this.controllers[1].month].toUpperCase() + ' ' + this.controllers[1].year;
        }
    }
}