import styled, {useTheme} from "styled-components";
import {Theme} from "../../theme";

interface CvvInputProps {
    label: any,
    input: any
    error?: any
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 4px;
`;

export const CvvInputField = (props: CvvInputProps) => {
    const theme = useTheme() as Theme;
    return <Container>
        <theme.textTheme.label.span style={{marginBottom: '8px'}}>
            {props.label}
        </theme.textTheme.label.span>
        {props.input}
        <theme.textTheme.error.span style={{
            opacity: !!props.error ? 1 : 0,
            transition: 'opacity 300ms ease',
        }}>{props.error}</theme.textTheme.error.span>
    </Container>
}