import styled from "styled-components";
import {StyledProps} from "../theme";

export const CvvButton = styled.button<{ width?: string, height?: string, disabled?: boolean }>`
  background-color: ${({theme}: StyledProps) => theme.primary};
  outline: none;
  border: none;
  padding: 0 30px 0 30px;
  height: ${({height}) => height || '62px'};
  width: ${({width}) => width || '100%'};
  display: flex;
  align-items: center;
  ${({theme}: StyledProps) => theme.textTheme.button.css};
  box-sizing: border-box;
  cursor: ${({disabled}) => disabled ? 'auto' : 'pointer'};
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
  pointer-events: ${({disabled}) => disabled ? 'none' : 'auto'};
  user-select: none;
`;