import {makeAutoObservable} from "mobx";
import {MladiVoznikStore} from "./mladi_voznik_store";
import {SocketApi, SocketApiAckStatus} from "proto_socket_typescript";
import {proto} from "../proto/messages";
import {cvv_types} from "../proto/compiled";

export class BankartStore {
    api: SocketApi;
    error?: string = undefined;
    bankartError?: string = undefined;
    progress: boolean = false;
    month?: string | undefined = undefined;
    year?: string | undefined = undefined;
    alias?: string | undefined = undefined;
    startOfForm: boolean = true;
    flik: boolean = false;
    currYear: number = new Date().getFullYear();
    currMonth: number = new Date().getMonth() + 1;
    formFilled: boolean = false;
    addressNecessary: boolean = false;

    constructor(api: SocketApi, mladiVoznikStore: MladiVoznikStore) {
        this.api = api;
        this.addressNecessary = ['', undefined, null].includes(mladiVoznikStore.form.step1?.address?.address);
        console.log('addressNecessary', this.addressNecessary);
        makeAutoObservable(this);
    }

    changeMonth(month: string) {
        if (this.startOfForm) {
            this.startOfForm = false;
        }
        let temp: string = month.replace(/\D/g, '');
        if (parseInt(temp) > 12) {
            this.month = '12';
        } else if (temp.length === 1 && parseInt(temp) > 1 && parseInt(temp) < 10) {
            this.month = '0' + temp;
        } else {
            this.month = temp;
        }

        if (parseInt(this.month) < this.currMonth && parseInt(this.year!) === this.currYear) {
            this.error = "Izbrani mesec ni veljaven";
        } else {
            this.error = undefined;
        }
        this.formDataFilled();
    }

    changeYear(year: string) {
        if (this.startOfForm) {
            this.startOfForm = false;
        }
        let temp: string = year.replace(/\D/g, '');
        if (temp.length === 1 && parseInt(temp) < 2) {
            this.year = '2';
        } else {
            this.year = temp;
        }
        if (parseInt(this.year) < this.currYear ||
            (parseInt(this.year) === this.currYear && parseInt(this.month!) < this.currMonth)
            || parseInt(this.year) > this.currYear + 15) {
            this.error = "Izbrano leto ni veljavno";
        } else {
            this.error = undefined;
        }
        this.formDataFilled();
    }

    validateFields() {
        return this.month && this.month.length === 2 && this.year && this.year.length === 4;
    }

    formDataFilled(mladiVoznikStore?: MladiVoznikStore) {
        let filled = true;
        if (mladiVoznikStore && mladiVoznikStore.form.step6?.differentBillingAddress) {
            let error1 = mladiVoznikStore.validators.step6.billingFirstName(false);
            let error2 = mladiVoznikStore.validators.step6.billingLastName(false);
            let error3 = mladiVoznikStore.validators.step6.billingAddress(false);
            filled = filled && !error1 && !error2 && (!error3 || process.env.REACT_APP_DEBUG === '1');
        }
        this.formFilled = filled && !!this.validateFields();
    }

    async submit(e: any, mladiVoznikStore: MladiVoznikStore, store: BankartStore, paymentError: boolean, onPaymentErrorRetry: () => void, inProgress?: (p: boolean) => void, bankart?: any) {
        e.preventDefault();

        const differentBillingInfo = mladiVoznikStore.form.step6?.differentBillingAddress;
        console.log('in submit, different billing info is: ', differentBillingInfo);
        mladiVoznikStore.onUserActionValidation();

        if (paymentError) {
            inProgress && inProgress(false);
            onPaymentErrorRetry();
        }
        if (this.error !== undefined || !bankart) return;

        this.bankartError = undefined;
        this.formDataFilled(mladiVoznikStore);
        if (!this.formFilled) return;

        inProgress && inProgress(true);
        this.startOfForm = true;

        let nameSurname = mladiVoznikStore.form.step1?.nameSurname?.split(' ', 2) ?? ['', ''];

        let firstName = differentBillingInfo ? mladiVoznikStore.form.step6?.billingFirstName : nameSurname[0];
        let lastName = differentBillingInfo ? mladiVoznikStore.form.step6?.billingLastName : nameSurname[1];
        // this determination of address is ok, because if address is missing in step1, differentBillingInfo is true by default
        let address = differentBillingInfo ? mladiVoznikStore.form.step6?.billingAddress : mladiVoznikStore.form.step1?.address;


        bankart.tokenize( // here the token is retrieved from the Gateway
            {
                first_name: firstName,
                last_name: lastName,
                month: this.month,
                year: this.year,
            },
            function (token: any, cardData: any) {
                const transaction_token = document.getElementById('transaction_token') as HTMLInputElement | null;
                if (transaction_token) {
                    transaction_token.value = token;
                    console.log('token', token);
                    mladiVoznikStore.step6.cardPaymentSubmitForm();
                    store.api.sendMessage(proto.TxInitiateBankartPayment.create({
                        validationCode: mladiVoznikStore.step1?.result?.validationCode,
                        transactionToken: token,
                        firstName: firstName,
                        lastName: lastName,
                        address: address,
                        addressCountry: address?.state ?? 'SI',
                        differentBillingInfo: mladiVoznikStore.form.step6?.differentBillingAddress,
                    }), {ack: true}).then((status: any) => {
                        console.log('in progress');
                        if (status.status !== SocketApiAckStatus.success) {
                            store.bankartError = status.errorMessage ? status.errorMessage : 'Napaka pri plačilu';
                            inProgress && inProgress(false);
                            store.startOfForm = true;
                        }
                    });
                } else {
                    inProgress && inProgress(false);
                    store.startOfForm = true;
                }
            },
            function (errors: any) {
                store.bankartError = errors[0].message;
                inProgress && inProgress(false);
                console.log(errors[0])
            }
        );
    };

    handlePayer(mladiVoznikStore: MladiVoznikStore){
        mladiVoznikStore.form.step6!.differentBillingAddress = !mladiVoznikStore.form.step6?.differentBillingAddress;
        if (mladiVoznikStore.form.step6?.differentBillingAddress) {
            mladiVoznikStore.form.step6!.billingFirstName = undefined;
            mladiVoznikStore.form.step6!.billingLastName = undefined;
            this.startOfForm = true;
            this.formDataFilled(mladiVoznikStore);
        } else {
            mladiVoznikStore.form.step6!.billingFirstName = mladiVoznikStore.form.step1?.nameSurname?.split(' ', 1)[0];
            mladiVoznikStore.form.step6!.billingLastName = mladiVoznikStore.form.step1?.nameSurname?.split(' ', 2)[1];
            mladiVoznikStore.form.step6!.billingAddress = mladiVoznikStore.form.step1?.address ?? cvv_types.Address.create();
            this.formDataFilled(mladiVoznikStore);
            this.startOfForm = !this.formFilled;
        }
    }
}