import {billing, form, mladi_voznik} from "../proto/compiled";
import {SocketApi, SocketApiAckStatus} from "proto_socket_typescript";
import {makeAutoObservable} from "mobx";
import {proto} from "../proto/messages";
import {Pricelist} from "../forms/pricelist";
import {toast} from "react-toastify";
import {ShowD3SecureDialog} from "../components/3dsecure_dialog";
import PaymentType = billing.PaymentType;

export class FormStore {
    sessionId?: string;
    api: SocketApi;
    static meta?: form.FormMeta;
    meta?: form.FormMeta;
    pricelist?: Pricelist;
    paymentInitiated = false;
    paymentInProgress = false;
    fullyPaid = false;
    cardPaymentDone = false;
    cardPaymentError = false;
    orderSummary?: form.IOrderSummary;
    paymentType = billing.PaymentType.kreditna;
    resumeFormData?: form.IResumeFormData;
    formSummary?: form.FormSummary;
    formConfig?: mladi_voznik.IMladiVoznikFormConfig;

    constructor(api: SocketApi, sessionId?: string) {
        this.sessionId = sessionId;
        this.api = api;
        api.getMessageHandler(new proto.RxFormMeta()).subscribe((m) => this.onFormMeta(m));
        api.getMessageHandler(new proto.RxOrderSummary()).subscribe((m) => this.onOrderSummary(m));
        api.getMessageHandler(new proto.RxFormSummary()).subscribe((m) => this.onFormSummary(m));
        api.getMessageHandler(new proto.RxResumeFormData()).subscribe((m) => this.onResumeFormData(m));
        api.getMessageHandler(new proto.RxExportSlot()).subscribe((m) => this.onExportSlot(m));
        api.getMessageHandler(new proto.RxMladiVoznikFormConfig()).subscribe((m) => this.onFormConfig(m));
        api.getMessageHandler(new proto.RxBankartPaymentRedirect()).subscribe((m) => this.onBankartPaymentRedirect(m));
        api.getMessageHandler(new proto.RxBankartPaymentFinished()).subscribe((m) => this.onBankartPaymentFinished(m));
        makeAutoObservable(this);
    }

    get paymentAmount(): number | undefined {
        if (this.cardPaymentDone) return 0;
        if (!this.orderSummary || !this.orderSummary.pricelist) return undefined;
        return this.orderSummary!.pricelist!.total! - this.orderSummary!.pricelist!.paid!;
    }

    private onResumeFormData(message: proto.RxResumeFormData) {
        this.resumeFormData = message.proto;
    }

    private onOrderSummary(message: proto.RxOrderSummary) {
        try {
            this.orderSummary = message.proto;
            this.paymentType = this.orderSummary.paymentType!;
            this.fullyPaid = this.orderSummary.pricelist!.paid! >= this.orderSummary.pricelist!.total!;
            makeAutoObservable(this.orderSummary);
        } catch (e) {
            // todo - figure out why it's called twice
        }
    }

    private onFormSummary(message: proto.RxFormSummary) {
        this.formSummary = message.proto;
    }

    private onFormMeta(message: proto.RxFormMeta) {
        try {
            FormStore.meta = message.proto;
            this.meta = message.proto;
            this.updatePaymentType();
            makeAutoObservable(this.meta);
        } catch (e) {
            // todo - figure out why it's called twice!
        }
        try {
            this.pricelist = new Pricelist(this.meta!.pricelist!);
            makeAutoObservable(this.pricelist);
        } catch (e) {
            // todo - figure out why it's called twice!
        }

    }

    onSave() {
        this.orderSummary = undefined;
    }

    private onExportSlot(m: proto.RxExportSlot) {
        const a = document.createElement('a');
        a.href = m.proto.url;
        a.setAttribute("download", m.proto.name!);
        a.click();
    }

    private onFormConfig(m: proto.RxMladiVoznikFormConfig) {
        this.formConfig = m.proto;
        if (this.api.authenticated) {
            this.formConfig.s5 = mladi_voznik.MladiVoznikFormConfigS5.create({hide: true});
        }
    }

    private onBankartPaymentRedirect(m: proto.RxBankartPaymentRedirect) {
        if (m.proto.url) {
            ShowD3SecureDialog({
                redirectUrl: m.proto.url!,
                callback: (status) => {
                    if (status.includes('success')) {
                        this.cardPaymentDone = true;
                        this.paymentInProgress = false;
                        this.paymentInitiated = false;
                    } else {
                        this.cardPaymentDone = false;
                        this.paymentInProgress = false;
                        this.paymentInitiated = false;
                        this.cardPaymentError = true;
                    }
                },
                onClose: () => {
                    this.cardPaymentDone = false;
                    this.paymentInProgress = false;
                    this.paymentInitiated = false;
                    this.cardPaymentError = true;
                }
            });
        }

    }

    private onBankartPaymentFinished(m: proto.RxBankartPaymentFinished) {
        this.cardPaymentDone = true;
        this.paymentInProgress = false;
        this.paymentInitiated = false;
        this.cardPaymentError = false;
    }

    private updatePaymentType() {
        if (this.meta?.paymentTypes!.includes(PaymentType.kreditna)) {
            this.paymentType = PaymentType.kreditna;
        } else if (this.meta?.paymentTypes!.includes(PaymentType.gotovina)) {
            this.paymentType = PaymentType.gotovina;
        } else {
            this.paymentType = PaymentType.darilni_bon;
        }
    }

    async toggleConfirmed() {
        const formSummary = this.formSummary;
        if (!formSummary) return;
        formSummary.participationConfirmed = !formSummary.participationConfirmed;
        const response = await this.api.sendMessage(proto.TxSetSMSParticipation.create({
            formSessionId: formSummary.sessionId,
            confirmed: formSummary.participationConfirmed
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            formSummary.participationConfirmed = !formSummary.participationConfirmed;
            toast.error(response.errorMessage);
        } else if (formSummary.participationConfirmed) {
            toast.success('Udeležba je bila uspešno potrjena.');
        } else {
            toast.success('Udeležba je bila uspešno preklicana.');
        }
    }
}