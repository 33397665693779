import styled from "styled-components";
import React, {CSSProperties, useEffect, useMemo, useRef, useState} from "react";
import {useMeasure} from "../use_measure";

const Content = styled.div<{ maxHeight: string }>`
  overflow: hidden;
  max-height: ${({maxHeight}) => maxHeight};
  transition: all 300ms;
`;

const MeasureContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

enum TransitionState {
    stoppedExpanding, stoppedCollapsing, collapsing, expanding, unknown
}

export const CvvCollapsible = ({
                                   children,
                                   expanded,
                                   style,
                               }: { style?: CSSProperties, children?: any, expanded: boolean }) => {
    const [transitionState, setTransitionState] = useState(TransitionState.unknown);
    const contentRef = useRef<any>();
    const {ref, size} = useMeasure();
    const height = size?.height ?? 1000;

    useMemo(() => {
        if (transitionState === TransitionState.unknown) {
            setTransitionState(expanded ? TransitionState.stoppedExpanding : TransitionState.stoppedCollapsing);
        } else if (expanded) {
            setTimeout(() => setTransitionState(TransitionState.expanding), 0);
        } else {
            setTimeout(() => setTransitionState(TransitionState.collapsing), 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    useEffect(() => {
        contentRef.current.ontransitionend = () => {
            setTransitionState(expanded ? TransitionState.stoppedExpanding : TransitionState.stoppedCollapsing);
        };
    }, [ref, expanded]);

    let maxHeight = expanded ? 'auto' : '0';
    if (transitionState === TransitionState.stoppedExpanding && !expanded) {
        maxHeight = height + 'px';
    } else if (transitionState === TransitionState.collapsing) {
        maxHeight = '0';
    } else if (transitionState === TransitionState.stoppedCollapsing && expanded) {
        maxHeight = '0';
    } else if (transitionState === TransitionState.expanding) {
        maxHeight = height + 'px';
    }

    return <Content ref={contentRef} maxHeight={maxHeight}
                    style={{overflow: transitionState === TransitionState.stoppedExpanding ? 'visible' : 'hidden', ...style}}>
        <MeasureContainer ref={ref}>
            {children}
        </MeasureContainer>
    </Content>
}