import styled, {useTheme} from "styled-components";
import {Theme} from "../../theme";
import React, {useEffect, useState, useRef, useContext} from "react";
import {MdCheck, MdClose, MdRefresh} from "react-icons/md";
import {BANKART_API_KEY} from "../../globals";
import {proto} from "../../proto/messages";
import {SocketApi, SocketApiAckStatus} from "proto_socket_typescript";
import {billing, cvv_types} from "../../proto/compiled";
import {CvvRadio} from "../../components/radio";
import {CvvInputField} from "./input";
import {CvvTextInput} from "./text_input";
import {StringFormatters} from "../../utils";
import {BankartStore} from "../../stores/bankart_store";
import {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {MladiVoznikStore} from "../../stores/mladi_voznik_store";
import {FormStore} from "../../stores/form_store";
import {GmapsAddressPicker} from "./gmaps";
import {TailSpin} from "react-loader-spinner";

const Form = styled.form`
    display: flex;
`;

const style_smallcard = {
    width: '45px',
    padding: '0px',
    border: 'none',
    outline: 'none',
    transition: 'all 300ms',
    fontSize: '14px',
    fontWeight: '300',
    fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
}

const style_smallcard2 = {
    ...style_smallcard,
    fontWeight: '350',
    height: '17px',
}

export const BankartPayment = observer(({
                                            mladiVoznikStore,
                                            formStore,
                                            disabled,
                                            inProgress,
                                            onPaymentErrorRetry,
                                        }: {
    mladiVoznikStore: MladiVoznikStore,
    formStore: FormStore,
    disabled?: boolean,
    inProgress?: (p: boolean) => void,
    onPaymentErrorRetry: () => void
}) => {

    const bankart = useMemo(() => new window.PaymentJs(), []);
    const theme = useTheme() as Theme;
    const buttonRef = useRef<HTMLButtonElement>(null);
    const success = formStore.cardPaymentDone;
    const paymentError = formStore.cardPaymentError ? 'Napaka pri plačilu' : undefined;
    const progress = formStore.paymentInProgress;
    const store = useMemo(() => new BankartStore(formStore.api, mladiVoznikStore), []);
    if (store.addressNecessary) {
        mladiVoznikStore.form.step6!.differentBillingAddress = true;
    }
    const differentBillingInfo = !!(store.addressNecessary || mladiVoznikStore.form.step6?.differentBillingAddress);

    useEffect(() => {
        // enables or disables inputs
        if (progress) {
            const div = document.getElementById('bankart_payment') as HTMLDivElement | null;
            if (div) {
                const inputs = div.querySelectorAll('input');
                inputs.forEach((input) => {
                    input.disabled = true;
                });
            }
        } else {
            const div = document.getElementById('bankart_payment') as HTMLDivElement | null;
            if (div) {
                const inputs = div.querySelectorAll('input');
                inputs.forEach((input) => {
                    input.disabled = false;
                });
            }
        }
    }, [progress]);
    useEffect(() => {
        if (paymentError) {
            inProgress!(false);
            store.bankartError = paymentError;
        }
    }, [paymentError]);

    useEffect(() => {
        if (bankart.initialized) return;
        bankart.init(BANKART_API_KEY, 'card_number', 'card_cvc', function (payment: any) {
            payment.setNumberStyle({
                    width: '100%',
                    padding: '0px',
                    border: 'none',
                    outline: 'none',
                    transition: 'all 300ms',
                    fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
                    '::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: '0',
                    },
                    fontSize: '14px',
                    fontWeight: '300',
                    '-moz-appearance': 'textfield',
                    backgroundColor: 'white'
                }
            );
            payment.setCvvStyle({
                ...style_smallcard,
                width: '35px',
            });
            payment.setCvvPlaceholder('CVC');
            payment.setNumberPlaceholder('Številka kartice');
            payment.enableAutofill();
            payment.onAutofill(function (data: any) { // todo: test in production (disabled by service on http because of security)
                mladiVoznikStore.form.step6!.billingFirstName = data.first_name;
                mladiVoznikStore.form.step6!.billingLastName = data.last_name;
                store.year = data.year;
                store.month = data.month;
            })
            payment.numberOn('enter', () => handleKeyDown('ArrowRight', 'card_month', undefined));
            payment.cvvOn('enter', () => {
                if (buttonRef.current) {
                    buttonRef.current.click();
                }
            });

            payment.numberOn('input', () => {
                if (store.startOfForm) {
                    store.startOfForm = false;
                }
                if (paymentError) {
                    store.error = undefined;
                    store.bankartError = undefined;
                }
                store.formDataFilled(mladiVoznikStore);
            })
            payment.cvvOn('input', () => {
                if (store.startOfForm) {
                    store.startOfForm = false;
                }
                if (paymentError) {
                    store.error = undefined;
                    store.bankartError = undefined;
                }
                store.formDataFilled(mladiVoznikStore);
            });
        });
    });

    useEffect(() => {
        if (!bankart.initialized) return;
        if (success) {
            bankart.setNumberStyle({
                backgroundColor: 'rgba(0, 255, 0, .01)',
            });
            bankart.setCvvStyle({
                backgroundColor: 'rgba(0, 255, 0, .01)',
            });
        } else if (paymentError) {
            bankart.setNumberStyle({
                backgroundColor: 'rgba(255, 0, 0, .01)',
            });
            bankart.setCvvStyle({
                backgroundColor: 'rgba(255, 0, 0, .01)',
            });
        }
    }, [success, paymentError, store, bankart]);



    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement> | string, nextInputId: string | undefined, prevInputId: string | undefined) => {
        if (typeof e === 'string') {
            if ((e === 'ArrowRight') && nextInputId) {
                const nextInput = document.getElementById(nextInputId) as HTMLInputElement | null;
                if (nextInput) {
                    nextInput.focus();
                    setTimeout(() => {
                        nextInput.setSelectionRange(0, 0);
                    }, 0);
                }
            } else if (e === 'ArrowLeft' && prevInputId) {
                const prevInput = document.getElementById(prevInputId) as HTMLInputElement | null;
                if (prevInput) {
                    prevInput.focus();
                    setTimeout(() => {
                        prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length);
                    }, 0);
                }
            }
            return;
        }
        const {key, target} = e;
        const input = target as HTMLInputElement;
        const cursorPosition = input.selectionStart;

        // If Right Arrow is pressed and the cursor is at the end, move to the next field
        if ((key === "ArrowRight" || key === "Enter") && cursorPosition === input.value.length && nextInputId) {
            const nextInput = document.getElementById(nextInputId) as HTMLInputElement | null;
            if (nextInput) {
                nextInput.focus();
                setTimeout(() => {
                    // Ensure the cursor is at the start
                    nextInput.setSelectionRange(0, 0);
                }, 0);
            }
        }

        // If Left Arrow is pressed and the cursor is at the start, move to the previous field
        if (key === "ArrowLeft" && cursorPosition === 0 && prevInputId) {
            const prevInput = document.getElementById(prevInputId) as HTMLInputElement | null;
            if (prevInput) {
                prevInput.focus();
                setTimeout(() => {
                    // Ensure the cursor is at the end
                    prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length);
                }, 0);
            }
        }
    };
    const handleToggle = () => {
        store.flik = !store.flik;
    }


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) =>
        store.submit(e, mladiVoznikStore, store, paymentError !== undefined, onPaymentErrorRetry, inProgress, bankart);

    const btnDisabled = disabled || !bankart.init || store.error !== undefined || store.startOfForm || success
        || progress || !store.formFilled

    const spinning = !success && !paymentError && btnDisabled && progress;

    return <div>
        <Form onSubmit={handleSubmit}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    padding: '0px',
                    marginBottom: '10px',
                    width: '100%',
                }}
            >
                <div style={
                    {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        padding: '0px',
                        marginBottom: '10px',
                    }
                }>
                    {/*<label>*/}
                    {/*    <input*/}
                    {/*        type="radio"*/}
                    {/*        name="paymentMethod"*/}
                    {/*        value="flik"*/}
                    {/*        checked={flik}*/}
                    {/*        onChange={() => {}} // todo: add handleToggle for when we implement flik*/}
                    {/*    />*/}
                    {/*    Flik*/}
                    {/*</label>*/}
                    {/*<label>*/}
                    {/*<input*/}
                    {/*    type="radio"*/}
                    {/*    name="paymentMethod"*/}
                    {/*    value="kreditna_kartica"*/}
                    {/*    checked={!flik}*/}
                    {/*    onChange={() => {}} // todo: add handleToggle for when we implement flik*/}
                    {/*/>*/}

                    {/*</label>*/}
                    {(!store.addressNecessary || !success) && <h4
                        style={{
                            margin: '0',
                            padding: '0',
                            color: theme.text,
                        }}
                    >Podatki o plačniku</h4>}
                    {!store.addressNecessary && <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                    }}>
                        <div style={{width: '50%'}}>
                            <CvvRadio name={'payer'}
                                      id={'notdifferentBillingInfo'}
                                      checked={!mladiVoznikStore.form.step6?.differentBillingAddress}
                                      onChange={() => store.handlePayer(mladiVoznikStore)}
                                      label={'ENAKI KOT NAROČNIK'}
                                      disabled={success}
                            />
                        </div>
                        <div style={{width: '50%'}}>
                            <CvvRadio name={'payer'}
                                      id={'differentBillingInfo'}
                                      checked={!!mladiVoznikStore.form.step6?.differentBillingAddress}
                                      onChange={() => store.handlePayer(mladiVoznikStore)}
                                      disabled={success}
                                      label={'DRUGAČNI'}/>
                        </div>
                    </div> }
                    <input type="hidden" name="transaction_token" id="transaction_token"/>

                    {!store.flik && !success &&
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            flexGrow: '1',
                            gap: '10px',
                        }}>
                            {differentBillingInfo && <div style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                            }
                            }>
                                <CvvInputField label={'IME'}
                                               error={mladiVoznikStore.validators.step6.billingFirstName()}
                                               input={
                                                   <CvvTextInput
                                                       id={'first_name'}
                                                       onChange={(e) => {
                                                           mladiVoznikStore.form.step6!.billingFirstName = e.target.value;
                                                           if (store.startOfForm) {
                                                               store.startOfForm = false;
                                                           }
                                                           store.formDataFilled(mladiVoznikStore);
                                                       }}
                                                       value={mladiVoznikStore.form.step6?.billingFirstName ?? ''}
                                                       type={'text'}
                                                       maxLength={256}
                                                       name={'first_name'}
                                                       onKeyDown={(e) =>
                                                           handleKeyDown(e, 'last_name', 'undefined')}
                                                       disabled={success}
                                                   />
                                               }/>
                                <CvvInputField label={'PRIIMEK'}
                                               error={mladiVoznikStore.validators.step6.billingLastName()}
                                               input={
                                                   <CvvTextInput
                                                       id={'last_name'}
                                                       onChange={(e) => {
                                                           mladiVoznikStore.form.step6!.billingLastName = e.target.value;
                                                           if (store.startOfForm) {
                                                               store.startOfForm = false;
                                                           }
                                                           store.formDataFilled(mladiVoznikStore);
                                                       }}
                                                       value={mladiVoznikStore.form.step6?.billingLastName ?? ''}
                                                       type={'text'}
                                                       maxLength={256}
                                                       name={'last_name'}
                                                       onKeyDown={(e) => handleKeyDown(e, 'first_name', 'undefined')}
                                                       disabled={success}
                                                   />
                                               }/>
                                <GmapsAddressPicker
                                    address={mladiVoznikStore.step6.billingAddress!}
                                    onPlaceSelected={(p) => {
                                        mladiVoznikStore.setBillingAddress(p);
                                        if (store.startOfForm) {
                                            store.startOfForm = false;
                                        }
                                        store.formDataFilled(mladiVoznikStore);
                                    }}
                                    error={mladiVoznikStore.validators.step6.billingAddress()}
                                    noMap={true}
                                />
                            </div>}
                        </div>}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{
                        position: 'relative',
                        backgroundColor: success ? 'rgba(0, 255, 0, .1)' : (paymentError && store.startOfForm) ? 'rgba(255, 0, 0, .1)' : 'white',
                        boxShadow: '0 0.25em 0.5em rgba(0, 45, 98, .3)',
                        width: '100%',
                        padding: '12px',
                        borderRadius: '5px',
                        marginRight: '8px',
                        paddingBottom: (store.error || store.bankartError) ? '36px' : '12px',
                        transition: 'all 300ms',
                        border: store.error || store.bankartError ? 'solid 1px red' : undefined,
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                        justifyContent: 'space-between',

                    }}
                         id={'bankart_payment'}
                    >
                        {/*{store.flik &&*/}
                        {/*    <div style={{*/}
                        {/*        display: 'flex',*/}
                        {/*        flexDirection: 'row',*/}
                        {/*        justifyContent: 'space-between',*/}
                        {/*        alignItems: 'center',*/}
                        {/*        height: '100%',*/}
                        {/*        flexGrow: '1',*/}
                        {/*    }}>*/}
                        {/*        <input*/}
                        {/*            id={'alias'}*/}
                        {/*            placeholder={'Telefonska številka ali e-pošta'}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                handleChange(e)*/}
                        {/*            }}*/}
                        {/*            value={store.alias}*/}
                        {/*            type={'text'}*/}
                        {/*            maxLength={256}*/}
                        {/*            name={'alias'}*/}
                        {/*        />*/}
                        {/*    </div>}*/}


                        {!success && <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                            margin: '0',
                            padding: '0',
                        }
                        }>
                            <div id={'card_number'} style={{
                                height: '17px',
                                width: '90%',
                                marginTop: '0.5px'
                            }}></div>
                            <div style={{
                                display: 'flex',
                                gap: '7px',
                                flexDirection: 'row',
                            }}>
                                <input
                                    id={'card_month'}
                                    style={{
                                        ...style_smallcard2,
                                        textAlign: 'right',
                                        width: '25px',
                                        backgroundColor: success ? 'rgba(0, 255, 0, .01)' : (paymentError && store.startOfForm) ? 'rgba(255, 0, 0, .01)' : 'white',
                                    }}
                                    placeholder={'MM'}
                                    onChange={(e) => {
                                        store.changeMonth(e.target.value);
                                    }}
                                    value={store.month}
                                    type={'text'}
                                    inputMode={'numeric'}
                                    maxLength={2}
                                    max={12}
                                    min={1}
                                    name={'month'}
                                    onKeyDown={(e) => handleKeyDown(e, 'card_year', undefined)}
                                    disabled={success || !!paymentError}
                                    autoComplete={'off'}
                                />
                                <p style={{color: '#777', margin: '-1px 0', fontSize: '14px'}}>/</p>
                                <input
                                    id={'card_year'}
                                    style={{
                                        ...style_smallcard2,
                                        backgroundColor: success ? 'rgba(0, 255, 0, .01)' : (paymentError && store.startOfForm) ? 'rgba(255, 0, 0, .01)' : 'white',
                                    }}
                                    placeholder={'YYYY'}
                                    onChange={(e) => {
                                        store.changeYear(e.target.value);
                                    }}
                                    value={store.year}
                                    type={'text'}
                                    inputMode={'numeric'}
                                    maxLength={4}
                                    name={'year'}
                                    onKeyDown={(e) => handleKeyDown(e, undefined, 'card_month')}
                                    disabled={success || !!paymentError}
                                    autoComplete={'off'}
                                />
                                <div id={'card_cvc'} style={{
                                    height: '17px',
                                    width: '50px',
                                    marginTop: '0.5px',
                                }}></div>
                            </div>
                        </div>
                        }
                        {success && <p style={{
                            color: 'green',
                            margin: '0px'
                        }}>Plačilo je bilo uspešno.</p>}
                        {!success && <div style={{
                            backgroundColor: 'rgba(255, 0, 0, 0.05)',
                            position: 'absolute',
                            height: store.error || store.bankartError ? '24px' : '0',
                            overflow: 'hidden',
                            bottom: '0',
                            left: '0',
                            right: '0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: 'all 300ms',
                        }}>
                            <theme.textTheme.error.span>
                                {store.error && store.bankartError ? store.error + ', ' + store.bankartError : store.error ? store.error : store.bankartError}
                            </theme.textTheme.error.span>
                        </div>}
                    </div>
                    <button
                        // type="submit"
                        disabled={!!btnDisabled}
                        ref={buttonRef}
                        style={{
                            backgroundColor: success ? 'green' : (paymentError && store.startOfForm) ? 'red' : theme.content,
                            outline: 'none',
                            border: 'none',
                            borderRadius: '5px',
                            overflow: 'hidden',
                            boxShadow: '0 0.25em 0.5em rgba(0, 45, 98, .3)',
                            width: spinning || success ? '50px' : '100px',
                            cursor: btnDisabled ? 'auto' : 'pointer',
                            opacity: btnDisabled ? '0.7' : '1',
                            transition: 'all 300ms'
                        }}>
                        <theme.textTheme.button.span style={{

                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                        }}>
                            {!success && !paymentError && btnDisabled && progress && <TailSpin
                                color={theme.primary}
                                height={'25px'}
                                width={'25px'}/>}
                            {!progress && !success && 'PLAČAJ'}
                            {success && <MdCheck style={{fontSize: '20px'}}/>}
                        </theme.textTheme.button.span>
                    </button>
                </div>
            </div>
        </Form>
    </div>
});