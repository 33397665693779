// src/pages/PaymentStatus.tsx
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PaymentStatus = () => {
    const { status } = useParams<{ status: string }>();

    useEffect(() => {
        // When the component mounts, send a message to the parent window with the status
        if (window.parent !== window) {
            window.parent.postMessage({ paymentStatus: status }, "*");
        }
    }, [status]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            {status === 'success' && (
                <>
                    <h1>Payment Successful</h1>
                    <p>Your payment has been processed successfully.</p>
                    <span style={{fontSize: '30px'}}>✅</span>
                </>
            )}
            {status === 'error' && (
                <>
                    <h1>Payment Error</h1>
                    <p>There was an error processing your payment. Please try again.</p>
                    <span style={{fontSize: '30px'}}>❌</span>
                </>
            )}
            {status === 'cancel' && (
                <>
                    <h1>Payment Canceled</h1>
                    <p>Your payment has been canceled.</p>
                    <span style={{fontSize: '30px'}}>❌</span>
                </>
            )}
        </div>
    );
};

export default PaymentStatus;