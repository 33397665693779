import styled, {useTheme} from "styled-components";
import React, {CSSProperties, useState} from "react";
import {observer} from "mobx-react-lite";
import {TailSpin} from "react-loader-spinner"
import {Theme} from "../theme";

export const OverlayContainer = styled.div<{ hidden?: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${({hidden}) => hidden ? 0 : 1};
  pointer-events: ${({hidden}) => hidden ? 'none' : 'auto'};
  transition: opacity 300ms ease;
`;

export const CvvLoadingOverlay = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: ${({visible}) => visible ? 'all' : 'none'};
  opacity: ${({visible}) => visible ? '1' : '0'};
  transition: opacity 100ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type LoadingOverlayBuilder<T> = (action: (action: Promise<T>) => Promise<T>) => any;
export const LoadingOverlay = observer(({
                                            builder,
                                            style,
                                            overlayStyle,
                                            scale
                                        }: { builder: LoadingOverlayBuilder<any>, overlayStyle?: CSSProperties, style?: CSSProperties, scale?: number }) => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme() as Theme;
    return <div style={{position: 'relative', ...(style ?? {})}}>
        {builder(async (action) => {
            setLoading(true);
            const result = await action;
            setLoading(false);
            return result;
        })}
        <CvvLoadingOverlay visible={loading} style={overlayStyle}>
            <div style={{
                borderRadius: '50%',
                backgroundColor: theme.content,
                maxHeight: '50px',
                maxWidth: '50px',
                margin: '16px',
                padding: '4px',
                transform: scale == null ? undefined : `scale(${scale})`
            }}>
                <TailSpin
                        color={theme.primary}
                        height={'100%'}
                        width={'100%'}/>
            </div>

        </CvvLoadingOverlay>
    </div>
})