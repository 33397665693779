import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../theme";
import {OverlayContainer} from "./overlays";
import {CvvButton} from "./buttons";
import {useContext, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {appStore} from "..";
import {CvvInputField} from "../forms/components/input";
import {CvvTextInput} from "../forms/components/text_input";
import {makeAutoObservable} from "mobx";
import {AdminStoreContext} from "../admin/protected_route";
import {AdminStore} from "../admin/stores/admin_store";
import {proto} from "../proto/messages";
import {SocketApiAckStatus} from "proto_socket_typescript";
import {toast} from "react-toastify";

export const DialogFooter = styled.div`
  width: 100%;
  border-top: solid 1px #CCCCCC;
  background-color: ${({theme}: StyledProps) => theme.content10};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogContainer = styled.div<{ width?: string, height?: string }>`
  position: relative;
  background-color: ${({theme}: StyledProps) => theme.neutral};
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
  max-width: ${({width}) => width};
  max-height: ${({height}) => height};
  width: ${({width}) => width ? 'calc(100% - 32px)' : ''};
  height: ${({width}) => width ? 'calc(100% - 32px)' : ''};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContentUnitFrame = styled.div<{ padding?: string }>`
  position: relative;
  padding: ${({padding}) => padding};
  border: solid 1px ${({theme}: StyledProps) => theme.content};
`;

export const ContentUnitFrameSnack = styled.div<{ padding?: string, error?: boolean }>`
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  background-color: ${({theme, error}: StyledProps) => error ? theme.error : theme.content};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  ${({theme}: StyledProps) => theme.textTheme.headline6.with({color: 'white'}).css};
`;

interface YesNoDialogProps {
    yes?: any;
    no?: any;
    width?: string;
    height?: string;
    result: (result: boolean) => any;
    children?: any;
}

export const YesNoDialog = (props: YesNoDialogProps) => {
    const [first, setFirst] = useState(true);
    useEffect(() => setFirst(false), []);
    const theme = useTheme() as Theme;

    return <OverlayContainer hidden={first}>
        <DialogContainer style={{padding: '0', width: props.width ?? '500px'}}>
            <theme.textTheme.headline4.span style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                minHeight: '100px',
                height: props.height,
                width: '100%',
                padding: '16px 50px 16px 50px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: 'border-box'
            }}>
                {props.children}
            </theme.textTheme.headline4.span>
            <DialogFooter style={{height: '56px'}}>
                <CvvButton
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => props.result(false), 100);
                    }}
                    height={'42px'}
                    width={'180px'}
                    style={{padding: 0, borderRadius: '5px', justifyContent: 'center', marginRight: '16px'}}
                >{props.no ?? 'NE'}</CvvButton>
                <CvvButton
                    onClick={() => {
                        setFirst(true);
                        setTimeout(() => props.result(true), 100);
                    }}
                    height={'42px'}
                    width={'180px'}
                    style={{padding: 0, borderRadius: '5px', justifyContent: 'center'}}
                >{props.yes ?? 'DA'}</CvvButton>
            </DialogFooter>
        </DialogContainer>
    </OverlayContainer>;
};

export const UnovciBonDialog = observer(({sessionId}: { sessionId: string }) => {
    const state = useMemo(() => {
        const s = {
            stevilkaBona: '',
        };
        makeAutoObservable(s);
        return s;
    }, []);
    const store = useContext(AdminStoreContext) as AdminStore;


    return <YesNoDialog
        yes={'UNOVČI'}
        no={'PREKLIČI'}
        width={'480px'} result={(v) => {
        appStore.dialog = undefined;
        if (!v) {
            return;
        }
        store.api.sendMessage(proto.TxAdminUnovciDarilniBon.create({
            sessionId: sessionId,
            stDarilnegaBona: state.stevilkaBona
        }), {ack: true}).then((s) => {
            if (s.status !== SocketApiAckStatus.success) {
                toast.error(s.errorMessage);
            } else {
                store.participants.setParticipant(sessionId);
            }
        });
    }}>
        <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'stretch',
            height: '100%',
        }}>
            <CvvInputField
                label={'Številka darilnega bona'}
                input={
                    <CvvTextInput value={state.stevilkaBona}
                                  onChange={(e) => state.stevilkaBona = e.target.value}/>
                }/>
        </div>
    </YesNoDialog>
});
