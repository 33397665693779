import styled from "styled-components";
import {StyledProps} from "../theme";
import React, {RefObject, useState} from "react";

export const CvvRadioContainer = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0 16px 0 16px;
  cursor: pointer;
  user-select: none;

  && > * {
    pointer-events: none;
  }
`

export const CvvHiddenRadio = styled.input.attrs(props => ({
    type: 'radio',
    name: props.name
}))`
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  margin: 0;
  opacity: 0;
`;

export const CvvRadioBackground = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({theme}: StyledProps) => theme.content5};
  transition: background-color 300ms;
  z-index: -1;

  ${CvvHiddenRadio}:checked ~ && {
    background-color: ${({theme}: StyledProps) => theme.primary};
  }
`

export const CvvRadioLabel = styled.label`
  margin-left: 16px;
  ${({theme}: StyledProps) => theme.textTheme.button.css};
  font-weight: normal;
  transition: font-weight 300ms;

  ${CvvHiddenRadio}:checked ~ && {
    font-weight: bold;
  }
`;

export const CvvRadioControl = styled.span<{ color?: string }>`
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: solid 1px ${({theme, color}: StyledProps) => color ?? theme.content};
  flex-shrink: 0;
    
  &:before {
    content: " ";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    border-radius: 50%;
    transition: transform 300ms, opacity 150ms;
    background-color: ${({theme, color}: StyledProps) => color ?? theme.content};
    transform: scale(0);
    opacity: 0;
  }

  ${CvvHiddenRadio}:checked ~ && {
    &:before {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

interface CvvRadioProps {
    name: string;
    id: string;
    label: any;
    checked: boolean;
    tabIndex?: number;
    onChange: () => any;
    disabled?: boolean,
}

export const CvvRadio = (props: CvvRadioProps) => {
    const [ref] = useState<RefObject<any>>(React.createRef());
    return <CvvRadioContainer
        style={{marginBottom: '12px', pointerEvents: props.disabled ? 'none' : 'auto'}}
        onClick={(e) => {
            ref.current?.focus();
            !props.checked && !props.disabled && props.onChange();
        }}>
        <CvvHiddenRadio
            disabled={props.disabled}
            ref={ref}
            id={props.name + '-' + props.id}
            name={props.name}
            checked={props.checked}
            value={props.id}
            onChange={(e) => !props.disabled && props.onChange()}
            {...props.tabIndex !== undefined && {tabIndex: props.tabIndex}}
        />
        <CvvRadioBackground/>
        <CvvRadioControl color={props.disabled ? 'gray' : undefined}/>
        <CvvRadioLabel htmlFor={props.id} style={{
            color: props.disabled ? 'gray' : undefined,
        }}>{props.label}</CvvRadioLabel>
    </CvvRadioContainer>;
}