import {SocketApi} from "proto_socket_typescript";
import {MladiVoznikStore} from "./mladi_voznik_store";
import {makeAutoObservable} from "mobx";

export class MladiVoznikStoreS4 {
    api: SocketApi;
    base: MladiVoznikStore;

    constructor(base: MladiVoznikStore, api: SocketApi) {
        this.api = api;
        this.base = base;
        makeAutoObservable(this);
    }

    init() {

    }

    dispose() {
    }

    get done(): boolean {
        return true;
    };
}
