import styled from "styled-components";
import {StyledProps} from "../theme";

export const DatepickerStyles = styled.div<{ invalid?: boolean }>`
  .react-date-picker {
    width: 100%;
  }

  svg {
    stroke: ${({theme}: StyledProps) => theme.content};
  }

  .react-date-picker__inputGroup__input:invalid {
    background-color: transparent !important;
  }

  .react-date-picker__wrapper {
    border: none;
    border-bottom: solid 1px ${({theme, invalid}: StyledProps) => invalid ? theme.error : theme.idle};
    transition: all 300ms;
  }

  input {
    width: 22px !important;
    outline: none;
  }

  input:nth-of-type(4) {
    width: 40px !important;
  }
`;

export const AdminDatepickerStyles = styled(DatepickerStyles)`
  .react-date-picker {
    width: 100%;
  }

  svg {
    stroke: ${({theme}: StyledProps) => theme.content};
  }

  .react-date-picker__inputGroup__input:invalid {
    background-color: transparent !important;
  }

  .react-date-picker__wrapper {
    transition: all 300ms;
    border: solid 1px gray;
    border-radius: 3px;
    height: 22px;
  }

  input {
    outline: none;
  }

  .react-date-picker__button {
    display: none;
  }
`;