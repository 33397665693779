import {observer} from "mobx-react-lite";
import {useContext, useEffect} from "react";
import {AdminStore} from "./stores/admin_store";
import {AdminStoreContext} from "./protected_route";
import {AdminCalendars} from "./components/admin_calendars";
import styled, {useTheme} from "styled-components";
import {AdminButton, AdminScaffold} from "./admin_scaffold";
import {CvvRoutes} from "../strings";
import {AdminHeader} from "./components/admin_decorations";
import {AdminTecaji} from "./components/admin_tecaji";
import {DateFormatters} from "../utils";
import {Theme} from "../theme";
import {useNavigate} from "react-router-dom";
import {cvv_types} from "../proto/compiled";


const Container = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const Select = styled.select`
  margin-right: 8px;
`;

export const AdminRazpisaniTecaji = observer(() => {
    const store = useContext(AdminStoreContext) as AdminStore;
    const year = new Date().getFullYear();
    const theme = useTheme() as Theme;
    const navigate = useNavigate();
    useEffect(() => {
        store.api.connection.whenConnected.then(() => store.calendars.load());
    }, [store, store.calendars]);

    return <AdminScaffold route={CvvRoutes.admin.razpisaniTecaji}>
        <Container>
            <AdminHeader>
                <Select value={store.tecajTip ?? ''}
                        onChange={(e) => store.setTecajTip(e.target.value)}>
                    <option value={''}>---- izberi tip ----</option>
                    {store.meta?.tecajTipi?.map((t: cvv_types.IChoice) => <option key={t.text!}
                                                                                  value={t.text!}>{t.name}</option>)}
                </Select>
                <Select value={store.tecaj ?? ''} onChange={(e) => {
                    store.setTecaj(e.target.value);
                    store.calendars.load();
                }}>
                    <option value={''}>---- izberi tecaj ----</option>
                    {store.meta &&
                        store.meta?.tecajTipTecaji![store.tecajTip!]?.choices?.map(
                            (t: cvv_types.IChoice) => <option key={t.text!} value={t.text!}>{t.name}</option>
                        )}
                </Select>
                <AdminButton
                    onClick={() => {
                        navigate(CvvRoutes.admin.razpisaniTecaj());
                    }}
                    style={{marginLeft: 'auto'}}>
                    + DODAJ NOV TERMIN
                </AdminButton>
            </AdminHeader>

            <AdminHeader style={{marginTop: '8px', justifyContent: 'center'}}>
                <theme.textTheme.button.span style={{
                    position: 'absolute',
                    left: '16px',
                    color: 'white',
                }}>KOLEDAR
                </theme.textTheme.button.span>
                <Select value={store.calendars.controllers[1].month}
                        onChange={(e) => store.calendars.setMonth(parseInt(e.target.value))}>
                    {DateFormatters.months.map((m, i) => <option key={i} value={i}>{m}</option>)}
                </Select>
                <Select value={store.calendars.controllers[1].year}
                        onChange={(e) => store.calendars.setYear(parseInt(e.target.value))}>
                    {new Array(10).fill(0).map((m, i) => <option key={i} value={year + i - 8}>{year + i - 8}</option>)}
                </Select>
            </AdminHeader>
            <div style={{padding: '8px'}}>
                <AdminCalendars/>
            </div>

            <AdminHeader style={{marginTop: '8px'}}>
                <theme.textTheme.button.span style={{color: 'white', marginRight: 'auto'}}>
                    RAZPISANI TEČAJI {store.calendars.rangeText()}
                </theme.textTheme.button.span>
            </AdminHeader>
            {store.calendars.tipi.map((tip) => <AdminTecaji key={tip} tip={tip}/>)}
        </Container>
    </AdminScaffold>
});