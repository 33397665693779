import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {useCallback, useContext} from "react";
import {StyledProps, Theme} from "../../theme";
import {admin_tecaji} from "../../proto/compiled";
import {DateFormatters} from "../../utils";
import {AdminStoreContext} from "../protected_route";
import {AdminStore} from "../stores/admin_store";
import {MdEdit} from 'react-icons/md'
import {MdDelete} from 'react-icons/md'
import {useNavigate} from "react-router-dom";
import {CvvRoutes} from "../../strings";
import {AppStoreContext} from "../../index";
import {YesNoDialog} from "../../components/dialogs";


const TecajiGrid = styled.div`
  display: grid;
  grid-template-columns: 100px 130px 30px 30px 30px 30px auto repeat(16, 20px);
  gap: 1px;
  width: 100%;
  margin-bottom: 10px;
`;

const TecajiGridCell = styled.div`
  display: flex;
  outline: solid 1px #CCCCCC;
  line-height: 30px;
  padding: 0 5px 0 5px;
  overflow: hidden;
  white-space: nowrap;
  ${({theme}: StyledProps) => theme.textTheme.body.with({color: 'black'}).css};
`;

const TecajHourSpan = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.primary};
  border: solid 4px #EEEEEE;
  border-left: none;
  border-right: none;
  box-sizing: border-box;
`;

const TecajHourSpanContainer = styled(TecajiGridCell)`
  display: flex;
  grid-column-end: span 16;
  position: relative;
  padding: 0;
`;

const TecajiGridHeaderCell = styled.div`
  text-align: center;
  line-height: 27px;
  height: 27px;
  outline: solid 1px #CCCCCC;
  background-color: ${({theme}) => theme.content10};
  overflow: hidden;
  ${({theme}: StyledProps) => theme.textTheme.body.with({color: 'black', weight: 'bold'}).css};
`;

const TecajiGridHeader = styled.div`
  grid-column-end: span 23;
  background-color: ${({theme}) => theme.content};
  font-weight: bold;
  color: white;
  height: 27px;
  text-align: center;
  line-height: 27px;
  font-size: 13px;
`;

const IconButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  color: ${({theme}: StyledProps) => theme.content};
  font-size: 20px;
  height: 100%;
  padding: 0 4px 0 4px;
  margin-right: -4px;

  &:hover {
    background-color: ${({theme}: StyledProps) => theme.content10};
  }
`;

export const AdminTecaji = observer(({tip}: { tip: string }) => {
    const theme = useTheme() as Theme;
    const store = useContext(AdminStoreContext) as AdminStore;
    const app = useContext(AppStoreContext);
    const navigate = useNavigate();

    const getRow = useCallback((tecaj: admin_tecaji.IAdminTecajDaySummary) => {
        const start = new Date(tecaj.start?.ts as number);
        const end = new Date(tecaj.end?.ts as number);
        const inFuture = start.getTime() > Date.now();
        const key = tecaj.id;
        return [
            <TecajiGridCell key={key + '-1'} style={{
                borderLeft: 'solid 8px ' + (inFuture ? '#00CC00' : '#CC0000'),
                backgroundColor: inFuture ? '#CBFFCB' : '#FFCCCC',
            }}>{DateFormatters.date(start)}</TecajiGridCell>,
            <TecajiGridCell
                key={key + '-2'}>od {DateFormatters.time(start)} do {DateFormatters.time(end)}</TecajiGridCell>,
            <TecajiGridCell key={key + '-3'} style={{justifyContent: 'center'}}>{tecaj.placesTotal}</TecajiGridCell>,
            <TecajiGridCell key={key + '-5'}
                            style={{justifyContent: 'center', color: 'green'}}>{tecaj.placesFree}</TecajiGridCell>,
            <TecajiGridCell key={key + '-4'}
                            style={{justifyContent: 'center', color: 'red'}}>{tecaj.placesTaken}</TecajiGridCell>,
            <TecajiGridCell key={key + '-6'}
                            style={{backgroundColor: theme.content10}}>{tecaj.locationPrefix}</TecajiGridCell>,
            <TecajiGridCell key={key + '-7'} style={{overflow: 'visible', cursor: 'pointer'}} onClick={() => {
                store.razpisaniTecaj = tecaj;
                store.participants.participants = undefined;
                store.participants.load();
                navigate(CvvRoutes.admin.prijavljeneOsebe);
            }}>
                {tecaj.tecajName?.toUpperCase()} ({tecaj.dayN! + 1}. dan)
                <span style={{marginLeft: 'auto', height: '100%'}}>
                    <IconButton
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(CvvRoutes.admin.razpisaniTecaj(tecaj.razpisaniTecajId!));
                        }}><MdEdit/></IconButton>
                    <IconButton
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            app.showDialog(
                                <YesNoDialog result={(result) => {
                                    app.hideDialog();
                                    if (result) {
                                        store.delete(tecaj);
                                    }
                                }}>
                                    <theme.textTheme.headline5.span>Ali ste prepričani, da želite izbrisati tečaj?
                                    </theme.textTheme.headline5.span>
                                </YesNoDialog>
                            );
                        }}>
                        <MdDelete/>
                    </IconButton>
                </span>
            </TecajiGridCell>,
            <TecajHourSpanContainer key={key + '-8'}>
                <TecajHourSpan style={{
                    marginLeft: (((start.getHours() - 7) * 60 + start.getMinutes()) / 9.6) + '%',
                    marginRight: (((22 - end.getHours() + 1) * 60 - end.getMinutes()) / 9.6) + '%',
                }}/>
            </TecajHourSpanContainer>
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [theme]);

    return <TecajiGrid>
        <TecajiGridHeader>{tip}</TecajiGridHeader>
        <TecajiGridHeaderCell>datum</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>od - do</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>max</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>pro</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>zas</TecajiGridHeaderCell>
        <TecajiGridHeaderCell style={{gridColumnEnd: 'span 2'}}>vrsta tečaja</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>7</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>8</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>9</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>10</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>11</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>12</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>13</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>14</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>15</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>16</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>17</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>18</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>19</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>20</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>21</TecajiGridHeaderCell>
        <TecajiGridHeaderCell>22</TecajiGridHeaderCell>
        {store.calendars.getCurrentTecaji(tip).map((t) => getRow(t))}
    </TecajiGrid>
});