import {observer} from "mobx-react-lite";
import {FaCheck} from 'react-icons/fa'
import {CvvCategoryHeader} from "../components/headers";
import {CvvInputField} from "../components/input";
import {CvvTextInput} from "../components/text_input";
import {GmapsAddressPicker} from "../components/gmaps";
import {FormStepContainer} from "../components/decorations";
import {useContext, useState} from "react";
import {ContentUnitFrame, ContentUnitFrameSnack} from "../../components/dialogs";
import {ReactComponent as PhoneSvg} from "../../assets/phone.svg";
import {ReactComponent as NextSvg} from "../../assets/next.svg";
import {useTheme} from "styled-components";
import {Theme} from "../../theme";
import {CvvButton} from "../../components/buttons";
import {LoadingOverlay} from "../../components/overlays";
import {MladiVoznikS1LoginStep} from "../../stores/mladi_voznik_store_s1";
import {CvvPinInput} from "../components/pin_input";
import {MladiVoznikStoreContext} from "./form_mladi_voznik";
import {MladiVoznikStore} from "../../stores/mladi_voznik_store";
import {PricelistItems, PricelistModifiers} from "../pricelist";
import {StringFormatters} from "../../utils";
import {CvvRadio} from "../../components/radio";
import ReCAPTCHA from "react-google-recaptcha";
import {CAPTCHA_SITE_KEY} from "../../globals";

export const FormMladiVoznikS1 = observer((props: any) => {
    const store = useContext(MladiVoznikStoreContext) as MladiVoznikStore;
    const theme = useTheme() as Theme;
    console.log('store.config.s1?.nameSurname', store.config.s1?.nameSurname);
    const [captcha, setCaptcha] = useState<string | null>(null);

    const debugMode = process.env.REACT_APP_DEBUG === '1';

    return <FormStepContainer>
        <CvvCategoryHeader style={{marginTop: '52px'}}>
            Osebni podatki
        </CvvCategoryHeader>
        {store.config.s1?.nameSurname &&
            <CvvInputField error={store.validators.step1.nameSurname()} label={'IME IN PRIIMEK'} input={
                <CvvTextInput
                    autoComplete={'name'}
                    invalid={!!store.validators.step1.nameSurname()} value={store.form.step1!.nameSurname!}
                    onChange={(e) => store.form.step1!.nameSurname = e.target.value}/>
            }/>}
        {store.config.s1?.address && <GmapsAddressPicker
            address={store.form.step1!.address!}
            onPlaceSelected={(p) => store.setAddress(p)}
            error={store.validators.step1.address()}
        />}
        {store.config.s1?.email &&
            <div style={{marginTop: store.config.s1?.address ? '16px' : undefined, marginBottom: '32px'}}>
                <CvvInputField error={store.validators.step1.email()} label={'E-NASLOV'} input={
                    <CvvTextInput
                        autoComplete={'email'}
                        invalid={!!store.validators.step1.email()} value={store.form.step1!.email!}
                        onChange={(e) => store.form.step1!.email = e.target.value}/>
                }/>
            </div>}
        {store.config.s1?.children &&
            <CvvInputField error={store.validators.step1.children()} label={'ŠTEVILO OTROK'} input={
                <CvvTextInput type={'numeric'} min={0} maxLength={3} invalid={!!store.validators.step1.children()}
                              value={store.form.step1!.children! > 0 ? store.form.step1!.children! : ''}
                              pattern={'[0-9]*'}
                              onChange={
                    (e)=> {
                                  store.form.step1!.children = Number(e.target.value.replace(/\D/g, ''));
                                  store.stepChildren.initData(store.form.step1!.children);
                              }}/>
            }/>
        }
        {store.config.s1?.isStudent && <>
            <CvvCategoryHeader>
                SEM DIJAK / ŠTUDENT
            </CvvCategoryHeader>
            <CvvRadio
                name={'dijak-student'} id={'da'} checked={store.form.step1!.isStudent!} onChange={() => {
                store.form.step1!.isStudent = true;
            }} label={'DA'}/>
            <CvvRadio
                name={'dijak-student'} id={'ne'} checked={!store.form.step1!.isStudent} onChange={() => {
                store.form.step1!.isStudent = false;
            }} label={'NE'}/>
        </>}
        {!store.step1.result && <LoadingOverlay
            builder={(action) => <ContentUnitFrame padding={'16px'}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '16px',
                }}>
                    <PhoneSvg height={'60px'} fill={theme.content} style={{marginRight: '16px'}}/>
                    <theme.textTheme.body.span style={{fontSize: '16px'}}>
                        {store.step1.loginStep === MladiVoznikS1LoginStep.phone && 'Vpiši svojo telefonsko številko, da ti lahko pošljemo potrditveno kodo'}
                        {store.step1.loginStep === MladiVoznikS1LoginStep.pin && 'Vnesite potrditveno kodo, ki ste jo prejeli po SMS.'}
                    </theme.textTheme.body.span>
                </div>
                {store.step1.loginStep === MladiVoznikS1LoginStep.phone &&
                    <>
                        <CvvInputField error={store.validators.step1.phone()} label={'TELEFONSKA ŠTEVILKA'} input={
                            <CvvTextInput
                                type={'tel'}
                                autoComplete={'phone'}
                                pattern={'[0-9]*'}
                                invalid={!!store.validators.step1.phone()}
                                value={store.form.step1!.phone!}
                                onChange={(e) => store.form.step1!.phone = StringFormatters.phone(e.target.value)}/>
                        }/>
                        <ReCAPTCHA
                            sitekey={CAPTCHA_SITE_KEY}
                            onChange={(val: string | null) => setCaptcha(val)}
                        />
                    </>}
                {store.step1.loginStep === MladiVoznikS1LoginStep.pin &&
                    <div style={{padding: '0 32px 0 32px'}}>
                        <CvvPinInput
                            onSubmit={() => action(store.step1.checkPin())}
                            invalid={store.step1.pin !== null && !store.step1.pin} onChange={(val) => {
                            store.step1.pin = val;
                        }} digits={6}/>
                    </div>}
                {store.step1.loginStep === MladiVoznikS1LoginStep.phone && <CvvButton
                    onClick={() => {
                        store.onUserActionValidation();
                        if (store.stepValid(store.step, false)) {
                            action(store.step1.requestPin(captcha!));
                        }
                    }}
                    style={{marginTop: '16px'}}
                    disabled={!!store.validators.step1.phone(false) || (captcha === null && !debugMode)}>
                    <span>{store.api.authenticated ? 'NADALJUJ' : 'POŠLJI KODO'}</span><NextSvg height={'22px'}
                                                                                                style={{marginLeft: 'auto'}}/>
                </CvvButton>}
                {store.step1.loginStep === MladiVoznikS1LoginStep.pin && <div style={{display: 'flex'}}>
                    <CvvButton
                        onClick={() => store.step1.resetLogin()}
                        style={{
                            marginTop: '16px',
                            marginRight: '8px',
                            width: '61px',
                            padding: 0,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        <NextSvg height={'22px'} style={{transform: 'rotate(180deg)'}}/>
                    </CvvButton>
                    <CvvButton
                        onClick={() => action(store.step1.checkPin())}
                        style={{marginTop: '16px'}}
                        disabled={!store.step1.pin}>
                        <span>PREVERI KODO</span><NextSvg height={'22px'} style={{marginLeft: 'auto'}}/>
                    </CvvButton>
                </div>}
                {store.step1.info && <ContentUnitFrameSnack>{store.step1.info}</ContentUnitFrameSnack>}
                {store.step1.error && <ContentUnitFrameSnack error={true}>{store.step1.error}</ContentUnitFrameSnack>}
            </ContentUnitFrame>}
        />}
        {store.step1.result && <ContentUnitFrame padding={'16px'}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <theme.textTheme.headline4.span>
                    <FaCheck
                        style={{fontSize: '20px', marginRight: '12px', marginBottom: '-2px'}}/>
                    Prijava je uspela
                </theme.textTheme.headline4.span>
            </div>
            <CvvButton
                onClick={() => store.setStep(store.step + 1)}
                style={{marginTop: '16px'}}
                disabled={!store.stepValid(store.step, false)}>
                <theme.textTheme.headline5.span>NADALJUJ</theme.textTheme.headline5.span>
                <NextSvg height={'22px'} style={{marginLeft: 'auto'}}/>
            </CvvButton>
            {store.step1.result.jeClan &&
                <ContentUnitFrameSnack>
                    Ker ste AMZS član, se upošteva popust v vrednosti {
                    store.formStore?.pricelist?.getModifierDifferenceEur(PricelistItems.tecaj, [PricelistModifiers.amzs_clan])
                }</ContentUnitFrameSnack>}
        </ContentUnitFrame>}

    </FormStepContainer>
})