import styled from "styled-components";
import {StyledProps} from "../../theme";

export const CvvTextInput = styled.input<{ invalid?: boolean }>`
  background: transparent;
  border: none;
  border-bottom: solid 1px ${({theme, invalid}: StyledProps) => invalid ? theme.error : theme.idle};
  outline: none;
  width: 100%;
  height: 2.5rem;
  ${({theme}: StyledProps) => theme.textTheme.input.css};
  transition: border-bottom-color 300ms ease-in-out;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;


  &:focus {
    ${({theme, invalid}: StyledProps) => !invalid && `border-bottom-color: ${theme.content}`};
  }
`;
