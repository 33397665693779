import {SocketApi, SocketApiAckStatus} from "proto_socket_typescript";
import {proto} from "../../proto/messages";
import {makeAutoObservable} from "mobx";

export class AuthStore {
    private api: SocketApi;
    username: string = '';
    password: string = '';
    errorText?: string;
    authenticated: boolean = false;

    constructor(api: SocketApi) {
        this.api = api;
        api.setAuth(window.localStorage.getItem('token'));
        api.authenticatedChanges.subscribe((a) => this.onAuthChange(a));
        this.authenticated = api.authenticated;
        api.getMessageHandler(new proto.RxLoginToken()).subscribe((m) => this.onLoginToken(m));
        api.getMessageHandler(new proto.RxLoginError()).subscribe((m) => this.onLoginError(m));
        api.getMessageHandler(new proto.RxTokenInvalid()).subscribe((m) => this.onTokenInvalid(m));
        makeAutoObservable(this);

        api.connection.whenConnected.then(() => {
            if (api.authenticated) {
                api.sendMessage(proto.TxVerifyToken.create());
            }
        });
    }

    private onLoginToken(message: proto.RxLoginToken) {
        window.localStorage.setItem('token', message.proto.token);
        this.api.setAuth(message.proto.token);
    }

    private onLoginError(message: proto.RxLoginError) {
        this.errorText = message.proto.errorText;
        this.api.setAuth(null);
    }

    async authenticate() {
        const result = await this.api.sendMessage(proto.TxLogin.create({
            username: this.username,
            password: this.password,
        }), {ack: true});
        if (result.status !== SocketApiAckStatus.success) {
            this.errorText = result.errorMessage ?? undefined;
        }
    }

    private onAuthChange(authenticated: boolean) {
        this.authenticated = authenticated;
        if (authenticated) {
            window.location.href = '/admin/';
        } else {
            window.localStorage.removeItem('token');
            window.location.reload();
        }
    }

    private onTokenInvalid(m: proto.RxTokenInvalid) {
        this.api.setAuth(null);
    }
}