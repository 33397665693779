import {getAge} from "../utils";

export type CvvValidator<T> = (value: T, ignore?: boolean) => string | undefined;

export abstract class CvvValidators {
    static notEmpty: CvvValidator<any> = (value, ignore = false) => !(value || ignore) ? 'Polje je obvezno' : undefined;

    static phone: CvvValidator<string> = (value, ignore = false) => {
        if (ignore && value.length === 0) return undefined;
        if (value.length !== 9) return 'telefonska številka mora imeti 9 znakov';
        if (value[0] !== '0') return 'telefonska številka se mora začeti z 0';
    };

    static nameSurname: CvvValidator<string> = (value, ignore = false) => {
        if (ignore && value.length === 0) return undefined;
        if (value.trim().split(' ').length <= 1) return 'Vnesite ime in priimek';
    };

    static email: CvvValidator<string> = (value, ignore = false) => {
        if (ignore && value.length === 0) return undefined;
        const valid = value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (!valid) return 'Vnesite veljaven elektronski naslov (primer@ponudnik.domena)';
    };

    static children: CvvValidator<number>  = (value, ignore = false) => {
        if (ignore && value.toString().length === 0) return undefined;
        const num = value;
        if(num < 0) return 'Število otrok ne more biti negativno';
        if(!num && num !== 0) return 'Vnesite število otrok';
    };

    static stVozniske: CvvValidator<string> = (value, ignore = false) => {
        if (ignore && value.length === 0) return undefined;
        if (value.length < 6) return 'Polje ni izpolnjeno pravilno.';
    };

    static stDarilnegaBona: CvvValidator<string> = (value, ignore = false) => {
        if (!value.startsWith('CVV')) return 'Polje ni izpolnjeno pravilno.';
        value = value.substring(3);
        if (ignore && value.length === 0) return undefined;
        const num = parseInt(value);
        if (!num || num < 0 || value.length !== 6) return 'Polje ni izpolnjeno pravilno.';
    };


    static emso: (minAge?: number, maxAge?: number) => CvvValidator<string> = (minAge, maxAge) =>
        (val, ignore = false) => {
            if (ignore && val.length === 0) return undefined;
            const error = 'Polje ni izpolnjeno pravilno.';
            const multipliers = [7, 6, 5, 4, 3, 2, 7, 6, 5, 4, 3, 2, 1];

            if (val.length !== 13) {
                return error;
            }
            let crc = 0;
            for (let i = 0; i < val.length; i++) {
                crc += parseInt(val[i]) * multipliers[i];
            }
            let ageValid = true;
            if (crc % 11 !== 0) {
                return error;
            }
            if (minAge !== undefined || maxAge !== undefined) {
                let dan = parseInt(val.substring(0, 2)) ?? 0;
                let mesec = parseInt(val.substring(2, 4)) ?? 0;
                let leto = parseInt(val.substring(4, 7)) ?? 1970;
                leto = leto < 500 ? leto + 2000 : leto + 1000;
                let age = getAge(new Date(leto, mesec, dan));
                ageValid = ageValid && (maxAge === undefined || age <= maxAge);
                ageValid = ageValid && (minAge === undefined || age >= minAge);
            }

            if (!ageValid) {
                return 'Starost ni med ' + minAge + ' in ' + maxAge + ' leti.';
            }
        };

    static date: CvvValidator<Date> = (value, ignore = false) => {
        if (ignore && !value) return undefined;
        if (!value) return 'Vnesite datum';
        if(value.getTime() > Date.now()) return 'Vnesite veljaven datum';
    }

    static height: CvvValidator<string> = (value, ignore = false) => {
        if (ignore && value.length === 0) return undefined;
        if (value.length === 0) return 'Vnesite višino';
        if (value.includes('e')) return 'Vrednost ni število';
        if(!parseFloat(value)) return 'Vrednost ni število';
        if (parseFloat(value) < 0) return 'Vrednost ne more biti negativna';
        if(parseFloat(value) > 300) return 'Vrednost ne more biti večja od 300 cm';
    }

    static weight: CvvValidator<string> = (value, ignore = false) => {
        if (ignore && value.length === 0) return undefined;
        if (value.length === 0) return 'Vnesite težo';
        if (value.includes('e')) return 'Vrednost ni število';
        if(!parseFloat(value)) return 'Vrednost ni število';
        if (parseFloat(value) < 0) return 'Vrednost ne more biti negativna';
        if(parseFloat(value) > 1000) return 'Vrednost ne more biti večja od 1000 kg';
    }


    static join = (validators: CvvValidator<any>[]): CvvValidator<any> => (value) => {
        for (const validator of validators) {
            const error = validator(value);
            if (!!error) return error;
        }
    }
}