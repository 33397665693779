import {makeAutoObservable, ObservableMap} from "mobx";
import styled, {useTheme} from "styled-components";
import {observer} from "mobx-react-lite";
import {CvvTheme, StyledProps, Theme} from "../../theme";
import {ReactComponent as NextSvg} from "../../assets/next.svg";
import {Subject} from "rxjs";
import {zfill} from "../../utils";

export class CalendarController {
    year: number;
    month: number;
    datePayloads = new ObservableMap<string, any>();
    onDateChange = new Subject<null>();

    constructor(year: number, month: number) {
        this.year = year;
        this.month = month;
        makeAutoObservable(this);
    }

    get daysInMonth() {
        return new Date(this.year, this.month + 1, 0).getDate();
    }

    get rangeFrom(): Date {
        return new Date(this.year, this.month, 1);
    }

    get rangeTo(): Date {
        return new Date(this.year, this.month + 1, 1);
    }


    nextMonth() {
        this.month += 1;
        if (this.month > 11) {
            this.year += 1;
            this.month = 0;
        }
        this.onDateChange.next(null);
    }

    setDYear(dYear: number) {
        this.year += dYear;
        this.onDateChange.next(null);
    }

    setDate(month: number, year: number) {
        if (month > 11) {
            year += 1;
            month -= 12;
        } else if (month < 0) {
            year -= 1;
            month += 12;
        }
        this.year = year;
        this.month = month;
        this.onDateChange.next(null);
    }

    prevMonth() {
        this.month -= 1;
        if (this.month < 0) {
            this.year -= 1;
            this.month = 11;
        }
    }

    setPayload(date: Date, payload: any) {
        const key = CalendarController.kFromDate(date);
        this.datePayloads.set(key, payload);

        if (typeof payload === "function" && payload.prototype !== undefined) {
            makeAutoObservable(payload);
        }
    }

    static dateFromK(key: string) {
        return Date.parse(key);
    }

    static kFromDate(date: Date) {
        return date.getFullYear() + '-' + zfill(date.getMonth() + 1, 2) + '-' + zfill(date.getDate(), 2);
    }

    getPayload(date: Date): any {
        const key = CalendarController.kFromDate(date);
        return this.datePayloads.get(key);
    }

    monthTitle(): string {
        const dateS = new Date(this.year, this.month).toLocaleString('sl-si', {
            month: 'long',
        });
        return dateS.toUpperCase();
    }

    monthYearTitle(): string {
        const dateS = new Date(this.year, this.month).toLocaleString('sl-si', {
            month: 'long',
            year: 'numeric',
        });
        return dateS.toUpperCase();
    }
}

const HeaderDay = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${({theme}: StyledProps) => theme.content};
`;

const CalendarContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 1fr);
`;


type DayBuilder = (date?: Date, payload?: any) => any;


export const CvvCalendarHeader = observer(({controller}: { controller: CalendarController }) => {
    const theme = useTheme() as Theme;
    const canGoBack = controller.year > new Date().getFullYear() || controller.month > new Date().getMonth();
    return <div style={{display: "flex", flexDirection: 'column', position: 'relative'}}>
        <div style={{display: 'flex', alignItems: 'center', padding: '0 12px 0 12px'}}>
            {canGoBack && <NextSvg
                onClick={() => controller.prevMonth()}
                style={{transform: 'scaleX(-1)', color: theme.content, width: '17px', cursor: 'pointer'}}/>}
            <theme.textTheme.button.span style={{margin: '0 auto 0 auto', userSelect: 'none'}}>
                {controller.monthYearTitle()}
            </theme.textTheme.button.span>
            <NextSvg
                onClick={() => controller.nextMonth()}
                style={{color: theme.content, width: '17px', cursor: 'pointer'}}/>
        </div>
        <hr style={{
            width: 'calc(100% - 16px)',
            border: 'none',
            borderTop: 'solid 1px ' + CvvTheme.content,
            height: '2px'
        }}/>
        <CvvCalendarHeaderDays/>
    </div>;
});

export const CvvCalendarHeaderDays = () => <div style={{display: "flex", justifyContent: 'space-around'}}>
    <HeaderDay>pon</HeaderDay>
    <HeaderDay>tor</HeaderDay>
    <HeaderDay>sre</HeaderDay>
    <HeaderDay>čet</HeaderDay>
    <HeaderDay>pet</HeaderDay>
    <HeaderDay>sob</HeaderDay>
    <HeaderDay>ned</HeaderDay>
</div>;

interface CvvCalendarGridProps {
    controller: CalendarController;
    dayBuilder: DayBuilder
}

export const CvvCalendarGrid = observer(({controller, dayBuilder}: CvvCalendarGridProps) => {
    const children = [];
    const day = (new Date(controller.year, controller.month, 1).getDay() + 6) % 7;
    for (let i = 0; i < day; i++) {
        children.push(dayBuilder());
    }

    for (let i = 0; i < controller.daysInMonth; i++) {
        const date = new Date(controller.year, controller.month, i + 1);
        children.push(dayBuilder(date, controller.getPayload(date)));
    }

    return <CalendarContainer>
        {children}
    </CalendarContainer>
})

export const CalendarDay = styled.div<{ selected: boolean, hint?: boolean, slotSelected: boolean, clickable: boolean}>`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  background-color: ${({
                         theme,
                         selected,
                         hint
                       }: StyledProps) => selected ? theme.content : hint ? theme.content5 : 'transparent'};
  transition: all 300ms;
  cursor: ${({clickable}) => clickable ? 'pointer' : 'cursor'};
  pointer-events: ${({clickable}) => clickable ? 'auto' : 'none'};
  user-select: none;
  font-weight: ${({theme, slotSelected}: StyledProps) => slotSelected ? 'bold' : 'normal'};
  outline: ${({theme, slotSelected}: StyledProps) => `solid ${slotSelected ? theme.content : 'transparent'} 2px`};
  outline-offset: -2px;
  color: ${({theme, selected, clickable}: StyledProps) => !clickable ? 'gray' : selected ? 'white' : theme.content};
`;