import {AdminStore} from "./admin_store";
import {makeAutoObservable} from "mobx";
import {proto} from "../../proto/messages";
import {admin_participants, cvv_types, exports} from "../../proto/compiled";
import {SocketApiAckStatus} from "proto_socket_typescript";
import {toast} from "react-toastify";
import {appStore} from "../../index";
import {YesNoDialog} from "../../components/dialogs";
import {CvvTheme} from "../../theme";
import {debounce, isNaN} from "lodash";

export class AdminParticipantsStore {
    private disposeCallbacks: (() => any)[] = [];
    base: AdminStore;
    page = 0;
    search?: string;
    date?: Date;
    participants?: admin_participants.IAdminParticipants;
    participant?: admin_participants.IAdminParticipant;
    commentTypes?: cvv_types.ICommentType[];
    newComment: cvv_types.Comment = new cvv_types.Comment({
        type: new cvv_types.CommentType()
    });
    comments?: cvv_types.IComment[];

    constructor(base: AdminStore) {
        this.base = base;
        makeAutoObservable(this);
        makeAutoObservable(this.newComment);
        makeAutoObservable(this.newComment.type!);

        this.onSub(
            this.base.api.getMessageHandler(new proto.RxAdminParticipants()).subscribe((m) => this.onAdminParticipants(m))
        );
        this.onSub(
            this.base.api.getMessageHandler(new proto.RxAdminParticipant()).subscribe((m) => this.onAdminParticipant(m))
        );
        this.onSub(
            this.base.api.getMessageHandler(new proto.RxAdminCommentTypes()).subscribe((m) => this.onAdminCommentTypes(m))
        );
        this.onSub(
            this.base.api.getMessageHandler(new proto.RxAdminParticipantComments()).subscribe((m) => this.onAdminComments(m))
        );
    }

    load(): void {
        console.log('load participants');
        this.participant = undefined;
        this.base.api.sendMessage(proto.TxAdminLoadCommentTypes.create())
        this.loadParticipants();
    }

    setPage(page: number): boolean {
        if (isNaN(page) || page > (this.participants?.nPages ?? 0) || page < 0) return false;
        if (page === this.page) return true;
        this.page = page;
        this.loadParticipantsDebounced();
        return true;
    }

    async loadParticipants() {
        return await this.base.api.sendMessage(proto.TxAdminLoadParticipants.create({
            page: this.page,
            tecajTipKey: this.base.tecajTip,
            tecajKey: this.base.tecaj,
            tecajId: this.base.razpisaniTecaj?.razpisaniTecajId,
            search: this.search,
            date: this.date && new cvv_types.Date({ts: this.date.getTime()}),
        }), {ack: true});
    }

    loadParticipantsDebounced = debounce(() => this.loadParticipants(), 300);

    async togglePresence(participant: admin_participants.IParticipantSummary, dayN: number) {
        const message = proto.TxAdminParticipantSetPresence.create({
            sessionId: participant.sessionId,
            daysPresent: Array.from(participant.daysPresent!)
        });

        const dI = message.proto.daysPresent!.indexOf(dayN);
        if (dI < 0) {
            message.proto.daysPresent.push(dayN);
        } else {
            const result = await new Promise((resolve, reject) => {
                appStore.showDialog(
                    <YesNoDialog result={(result) => {
                        appStore.hideDialog();
                        resolve(result);
                    }}>
                        <CvvTheme.textTheme.headline5.span>Ali ste prepričani, da želite preklicati prisotnost na
                            tečaju?
                        </CvvTheme.textTheme.headline5.span>
                    </YesNoDialog>
                );
            });
            if (!result) return;
            message.proto.daysPresent.splice(dI, 1)
        }
        const response = await this.base.api.sendMessage(message, {ack: true});
        if (response.status === SocketApiAckStatus.success) {
            participant.daysPresent = message.proto.daysPresent;
            if (this.participant?.summary && this.participant?.summary?.sessionId === participant.sessionId) {
                this.participant.summary.daysPresent = message.proto.daysPresent;
            }
        } else {
            toast.error(response.errorMessage ?? 'Neznana napaka');
        }
    }

    dispose() {
        for (const dc of this.disposeCallbacks) {
            dc();
        }
    }

    private onSub(subscription: any) {
        this.disposeCallbacks.push(() => subscription.unsubscribe());
    }

    private onAdminParticipants(message: proto.RxAdminParticipants) {
        this.participants = message.proto;
    }

    private onAdminParticipant(message: proto.RxAdminParticipant) {
        this.participant = message.proto;
        makeAutoObservable(this.participant!.summary!);
        makeAutoObservable(this.participant!.form!);
        makeAutoObservable(this.participant!.form!.step1!);
        makeAutoObservable(this.participant!.form!.step2!);
        makeAutoObservable(this.participant!.form!.step3!);
        if (this.participant!.form!.step3!.datumIzdajeVozniske) {
            makeAutoObservable(this.participant!.form!.step3!.datumIzdajeVozniske!);
        }
        if (this.participant!.form!.step3!.datumOpravljenegaIntenzivnegaTecaja) {
            makeAutoObservable(this.participant!.form!.step3!.datumOpravljenegaIntenzivnegaTecaja!);
        }
        makeAutoObservable(this.participant!.form!.step4!);
        makeAutoObservable(this.participant!.form!.step5!);
        makeAutoObservable(this.participant!.form!.step6!);
    }

    setParticipant(id?: string) {
        if (!id) {
            this.participant = undefined;
        } else {
            this.base.api.sendMessage(proto.TxAdminLoadParticipant.create({
                id: id,
            }));
        }

    }

    private onAdminCommentTypes(message: proto.RxAdminCommentTypes) {
        this.commentTypes = message.proto.types;
        if (this.commentTypes?.length) {
            this.newComment.type!.id = this.commentTypes[0].id;
        }
    }

    async saveNewComment() {
        const response = await this.base.api.sendMessage(proto.TxAdminParticipantSaveComment.create({
            sessionId: this.participant?.summary?.sessionId,
            comment: this.newComment
        }), {ack: true});
        this.newComment.text = '';
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
        }
    }

    async resendConfirmationMail() {
        const response = await this.base.api.sendMessage(proto.TxAdminParticipantResendConfirmationMail.create({
            sessionId: this.participant?.summary?.sessionId
        }), {ack: true});
        if (response.status === SocketApiAckStatus.success) {
            toast.success('Poslano');
        } else {
            toast.error(response.errorMessage ?? 'Neznana napaka');
        }
    }

    private onAdminComments(m: proto.RxAdminParticipantComments) {
        this.comments = m.proto.comments;
    }

    async deleteComment(comment: cvv_types.IComment) {
        const response = await this.base.api.sendMessage(proto.TxAdminParticipantDeleteComment.create({
            commentId: comment.id
        }), {ack: true})
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
        } else {
            await this.base.api.sendMessage(proto.TxAdminLoadParticipant.create({
                id: this.participant?.summary?.sessionId,
            }));
            await this.loadParticipants();
        }
    }

    async archive(participant: admin_participants.IAdminParticipant) {
        const response = await this.base.api.sendMessage(proto.TxAdminArchiveParticipant.create({
            sessionId: participant.summary?.sessionId
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
        } else {
            await this.base.api.sendMessage(proto.TxAdminLoadParticipant.create({
                id: participant?.summary?.sessionId,
            }));
            this.loadParticipants();
        }
    }

    async downloadConfirmation() {
        const response = await this.base.api.sendMessage(proto.TxExportSession.create({
            sessionId: this.participant?.summary?.sessionId,
            exportType: exports.ExportType.potrdilo,
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
        }
    }

    async createInvoice() {
        const response = await this.base.api.sendMessage(proto.TxAdminCreateInvoice.create({
            sessionId: this.participant?.summary?.sessionId,
        }), {ack: true});
        if (response.status !== SocketApiAckStatus.success) {
            toast.error(response.errorMessage ?? 'Neznana napaka');
        } else {
            this.setParticipant(this.participant?.summary?.sessionId!);
        }
    }
}