import {observer} from "mobx-react-lite";
import {DateFormatters, NumberFormatters} from "../../utils";
import {Pricelist} from "../pricelist";
import {useTheme} from "styled-components";
import {useContext} from "react";
import {MladiVoznikStoreContext} from "./form_mladi_voznik";
import {MladiVoznikStore} from "../../stores/mladi_voznik_store";
import {Theme} from "../../theme";
import {Grid} from "react-loader-spinner"
import {form} from "../../proto/compiled";

interface MladiVoznikSummaryProps {
    price?: number | null | undefined;
    errors?: form.IFormError[] | null;
}

export const MladiVoznikSummary = observer((props: MladiVoznikSummaryProps) => {
    const theme = useTheme() as Theme;
    const store = useContext(MladiVoznikStoreContext) as MladiVoznikStore;
    let price;
    if (props.price === null) {
        price =
            <><span style={{display: 'inline-block', verticalAlign: 'middle', marginLeft: '8px'}}>
                <Grid
                    color={theme.content}
                    height={20}
                    width={20}/>
            </span> €</>;
    } else if (props.price !== undefined) {
        price = NumberFormatters.eur(props.price / 100);
    } else {
        price = NumberFormatters.eur((Pricelist.total(store.pricelistItems, store.pricelistModifiers) ?? 0) / 100);
    }

    let errors;
    if (props.errors?.length) {
        errors = props.errors.map((e) => <theme.textTheme.error.span>
            {e.field}: {e.description}
        </theme.textTheme.error.span>)
    }

    return <div style={{
        marginTop: '8px',
        borderTop: 'solid 1px ' + theme.content,
        borderBottom: 'solid 1px ' + theme.content,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 0 16px',
        textAlign: 'center'
    }}>
        <theme.textTheme.headline4.span style={{lineHeight: '35px'}}>
            {DateFormatters.verbose(new Date((store.form.step2?.date?.dateTime?.ts ?? Date.now()) as number))}<br/>
            {!props.errors?.length && <>Za plačilo <b>{price}</b></>}
        </theme.textTheme.headline4.span>
        {errors}
    </div>;
})