import {createContext, useContext, useEffect, useState} from "react";
import {ApiContext, AppStoreContext} from "../index";
import {Navigate} from "react-router-dom";
import {AdminStore} from "./stores/admin_store";
import {ConnectedOverlay} from "../app";
import {observer} from "mobx-react-lite";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../index_admin.css';
let adminStore: AdminStore | undefined;
export const AdminStoreContext = createContext<AdminStore | undefined>(undefined);

export const ProtectedRoute = observer(({children}: { children: any }) => {
    const api = useContext(ApiContext);
    const app = useContext(AppStoreContext);
    const [auth, setAuth] = useState(api.authenticated);

    useEffect(() => {
        const sub = api.authenticatedChanges.subscribe((a) => {
            setAuth(a);
        });
        return () => sub.unsubscribe();
    });

    if (auth) {
        if (!adminStore) adminStore = new AdminStore(api);
        return <AdminStoreContext.Provider value={adminStore}>
            {children}
            {app.dialog}
            <ConnectedOverlay/>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </AdminStoreContext.Provider>
    }

    return <Navigate to={'/admin/login/'}/>;
});