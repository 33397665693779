import {SocketApi, SocketApiAckStatus} from "proto_socket_typescript";
import {AdminCalendarStore} from "./admin_calendar_store";
import {proto} from "../../proto/messages";
import {admin_tecaji, amzs_cvv, cvv_types} from "../../proto/compiled";
import {makeAutoObservable} from "mobx";
import {AdminTecajiStore} from "./admin_tecaji_store";
import {AdminParticipantsStore} from "./admin_participants_store";

export class AdminStore {
    loading = 0;
    private disposeCallbacks: (() => any)[] = [];
    api: SocketApi;
    calendars: AdminCalendarStore;
    participants: AdminParticipantsStore;
    tecaji: AdminTecajiStore;
    user?: amzs_cvv.IUserProfileData;
    meta?: admin_tecaji.IAdminMeta;

    tecajId?: string;
    tecajTip?: string;
    tecaj?: string;
    razpisaniTecaj?: admin_tecaji.IAdminTecajDaySummary;

    constructor(api: SocketApi) {
        this.api = api;
        this.calendars = new AdminCalendarStore(this);
        this.participants = new AdminParticipantsStore(this);
        this.tecaji = new AdminTecajiStore(this);
        this.disposeCallbacks.push(() => this.tecaji.dispose());
        this.disposeCallbacks.push(() => this.participants.dispose());
        this.disposeCallbacks.push(() => this.calendars.dispose());
        this.onSub(
            api.getMessageHandler(new proto.RxUserProfileData())
                .subscribe((m) => this.user = m.proto)
        );
        this.onSub(
            api.getMessageHandler(new proto.RxAdminMeta())
                .subscribe((m) => this.onAdminMeta(m))
        );
        makeAutoObservable(this);
        this.api.connection.whenConnected.then(() => this.load());
    }

    private onSub(subscription: any) {
        this.disposeCallbacks.push(() => subscription.unsubscribe());
    }

    dispose() {
        for (const dc of this.disposeCallbacks) {
            dc();
        }
    }

    private load() {
    }

    private onAdminMeta(message: proto.RxAdminMeta) {
        this.meta = message.proto;
    }

    setTecajTip(value: string) {
        if (value.length === 0)
            this.tecajTip = undefined;
        else
            this.tecajTip = value;
        this.calendars.load();
        this.tecaj = undefined;
    }

    setTecaj(value: string) {
        if (value.length === 0)
            this.tecaj = undefined;
        else
            this.tecaj = value;
    }

    async delete(tecaj: admin_tecaji.IAdminTecajDaySummary) {
        await this.api.sendMessage(proto.TxAdminDeleteRazpisaniTecaj.create({
            tecajId: tecaj.razpisaniTecajId,
        }), {ack: true});
        this.calendars.load();
    }
}