import {SocketApi} from "proto_socket_typescript";
import {MladiVoznikStore} from "./mladi_voznik_store";
import {autorun, makeAutoObservable} from "mobx";

export interface CheckboxData {
    content: any;
    key: string;
    required: boolean;
    checked?: boolean;
    expanded?: boolean;
    children?: CheckboxData[];
    owner?: CheckboxData;
}

export class MladiVoznikStoreS5 {
    initialized = false;
    api: SocketApi;
    base: MladiVoznikStore;
    checkboxes: CheckboxData[] = [
        {
            content: <span>Z izpolnjeno prijavnico sprejemam <a target={'_blank'} className={'pointer-events-all'} onClick={(e) => e.stopPropagation()} href={'https://cvvv.amzs.si/data/pdf/IZJAVA_O_ODGOVORNOSTI.pdf'}>IZJAVO O ODGOVORNOSTI</a>.</span>,
            key: 'odgovornost',
            required: true
        },
        {
            content: <span>Želim prejemati splošne novice (po e-pošti, prek SMS, telefonskega klica) o novostih, ugodnostih, ponudbah, novicah, dogodkih, nagradnih igrah ter možnostih sodelovanja pri anketiranjih in raziskavah.</span>,
            key: 'splosne-novice',
            required: false
        },
        {
            content: <span>Želim prejemati meni posebej prilagojene ponudbe o ponudbi produktov in storitev, ki bodo prilagojene mojim izkazanim interesom in zanimanjem.</span>,
            key: 'prilagojene-ponudbe',
            required: false
        }
    ];

    constructor(base: MladiVoznikStore, api: SocketApi) {
        this.api = api;
        this.base = base;
        makeAutoObservable(this);
        autorun(() => {
            const s5 = base.form?.step5;
            if (!s5) return;
            if (!s5.agreements) s5.agreements = {};
            for (const checkbox of this.checkboxes) {
                s5.agreements[checkbox.key] = !!checkbox.checked;
            }
        });
    }

    init() {
        if (this.initialized) return;
        this.initialized = true;
        const setOwner = (checkboxes: CheckboxData[], owner?: CheckboxData) => {
            for (const c of checkboxes) {
                c.owner = owner;
                if (c.children) {
                    setOwner(c.children, c);
                }
            }
        }
        setOwner(this.checkboxes);
    }

    dispose() {
    }

    get done(): boolean {
        for (const c of this.checkboxes) {
            if (!c.required) continue;
            if (!c.checked) return false;
        }
        return true;
    };

    toggle(checkbox: CheckboxData, value = undefined as boolean | undefined) {
        value = value ?? !checkbox.checked;
        checkbox.checked = value;

        let owner = checkbox.owner;
        while (owner) {
            let allChecked = true;
            for (const c of owner.children!) {
                if (!c.checked) {
                    allChecked = false;
                    break;
                }
            }
            owner.checked = allChecked;
            owner = owner.owner;
        }

        if (!checkbox.children) return;
        for (const c of checkbox.children) {
            this.toggle(c, value);
        }
    }
}
