import {observer} from "mobx-react-lite";
import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../theme";
import {CvvTextInput} from "../forms/components/text_input";
import {CvvInputField} from "../forms/components/input";
import {useContext} from "react";
import {ApiContext, AuthStoreContext} from "../index";
import {CvvButton} from "../components/buttons";
import {LoadingOverlay} from "../components/overlays";
import {Navigate} from "react-router-dom";
import Logo from "../assets/amzs.gif";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({theme}: StyledProps) => theme.shade};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 400px;
  padding: 16px;
  background-color: white;
  box-shadow: 0 0.25em 0.5em rgba(0, 45, 98, .3);
  border-radius: 10px;
`;

export const Login = observer(() => {
    const api = useContext(ApiContext);
    const store = useContext(AuthStoreContext);
    const theme = useTheme() as Theme;
    return api.authenticated ? <Navigate to={'/admin/'}/> : <Container>
        <LoadingOverlay
            overlayStyle={{
                borderRadius: '10px'
            }}
            builder={(action) => <form onSubmit={(e) => {
                e.preventDefault();
            }}>
                <LoginContainer>
                    <img src={Logo} style={{height: '60px'}} alt={'logo'}/>
                    <div style={{marginTop: 'auto', width: '100%'}}>
                        <CvvInputField label={'UPORABNIŠKO IME'} input={
                            <CvvTextInput invalid={!!store.errorText} value={store.username}
                                          onChange={(e) => store.username = e.target.value}/>
                        }/>
                    </div>
                    <div style={{marginBottom: 'auto', width: '100%'}}>
                        <CvvInputField label={'GESLO'} input={
                            <CvvTextInput invalid={!!store.errorText} value={store.password}
                                          type={'password'}
                                          onChange={(e) => store.password = e.target.value}/>
                        }/>
                    </div>
                    <theme.textTheme.error.span>&nbsp;{store.errorText}&nbsp;</theme.textTheme.error.span>
                    <CvvButton
                        onClick={() => action(store.authenticate())}
                        style={{justifyContent: 'center', marginTop: 'auto'}}
                        disabled={store.password.length === 0 || store.username.length === 0}>
                        <theme.textTheme.headline5.span>PRIJAVA</theme.textTheme.headline5.span>
                    </CvvButton>
                </LoginContainer>
            </form>}/>
    </Container>;
});